import { SVGS } from '@/assets'
import { MENU_COLORS } from '@/design'

interface IRouteLink {
  title: string
  img: string
  to: string
  bgColor: string
  borderColor: string
  isEnabled: boolean
  alt?: string
}

export const disabledGames = (import.meta.env.VITE_DISABLED_GAMES || '').split(',')
export const isGameEnabled = (gameName: string) => !disabledGames.includes(gameName)

export const getRouteLinks = (walletClientType: string | undefined): IRouteLink[] => {
  const links: IRouteLink[] = [
    {
      title: 'dice',
      img: SVGS.diceIcon,
      to: '/dice',
      bgColor: MENU_COLORS.dice.two,
      borderColor: MENU_COLORS.dice.one,
      isEnabled: !disabledGames.includes('dice'),
    },
    {
      title: 'bombs',
      img: SVGS.bombIcon,
      to: '/bombs',
      bgColor: MENU_COLORS.bombs.two,
      borderColor: MENU_COLORS.bombs.one,
      isEnabled: !disabledGames.includes('bombs'),
    },
    {
      title: 'cards',
      img: SVGS.cardsIcon,
      to: '/cards',
      bgColor: MENU_COLORS.cards.two,
      borderColor: MENU_COLORS.cards.one,
      isEnabled: !disabledGames.includes('cards') && walletClientType === 'privy',
    },
    {
      title: 'crash',
      img: SVGS.crashIcon,
      to: '/crash',
      bgColor: MENU_COLORS.crash.two,
      borderColor: MENU_COLORS.crash.one,
      isEnabled: !disabledGames.includes('crash'),
    },
    {
      title: 'coin flip',
      img: SVGS.walletIcon,
      to: '/coin-flip',
      bgColor: MENU_COLORS.coinFlip.two,
      borderColor: MENU_COLORS.coinFlip.one,
      isEnabled: !disabledGames.includes('coin flip'),
    },
    {
      title: 'plinko',
      img: SVGS.plinkoIcon,
      to: '/plinko',
      bgColor: MENU_COLORS.plinko.two,
      borderColor: MENU_COLORS.plinko.one,
      isEnabled: !disabledGames.includes('plinko'),
    },
    {
      title: 'rps',
      img: SVGS.scissorIcon,
      to: '/rps',
      bgColor: MENU_COLORS.rps.two,
      borderColor: MENU_COLORS.rps.one,
      isEnabled: !disabledGames.includes('rps'),
    },
    {
      title: 'roulette',
      img: SVGS.rouletteIcon,
      to: '/roulette',
      bgColor: MENU_COLORS.roulette.two,
      borderColor: MENU_COLORS.roulette.one,
      isEnabled: !disabledGames.includes('roulette'),
    },
    {
      title: 'deathmatch',
      img: SVGS.deathmatchIcon,
      to: '/deathmatch',
      bgColor: MENU_COLORS.deathmatch.two,
      borderColor: MENU_COLORS.deathmatch.one,
      isEnabled: true,
    },
    {
      title: 'quests',
      img: SVGS.galxeIcon,
      to: 'https://app.galxe.com/quest/fareplay',
      bgColor: MENU_COLORS.deathmatch.two,
      borderColor: MENU_COLORS.deathmatch.one,
      isEnabled: false,
    },
    {
      title: 'settings',
      img: SVGS.settingsIcon,
      to: '/settings',
      bgColor: MENU_COLORS.settings.two,
      borderColor: MENU_COLORS.settings.one,
      isEnabled: true,
    },
  ]

  return links.filter(routeLink => routeLink.isEnabled)
}

interface IMobileRouteLink {
  title: string
  img: string
  to: string
  particleColors: string | string[]
  isEnabled: boolean
}

export const getMobileRouteLinks = (walletClientType: string | undefined): IMobileRouteLink[] => {
  const mobileRouteLinks: IMobileRouteLink[] = [
    {
      title: 'DICE',
      img: SVGS.diceIcon,
      to: '/dice',
      particleColors: MENU_COLORS.dice.one,
      isEnabled: !disabledGames.includes('dice'),
    },
    {
      title: 'BOMBS',
      img: SVGS.bombIcon,
      to: '/bombs',
      particleColors: MENU_COLORS.bombs.one,
      isEnabled: !disabledGames.includes('bombs'),
    },
    {
      title: 'CARDS',
      img: SVGS.cardsIcon,
      to: '/cards',
      particleColors: MENU_COLORS.cards.one,
      isEnabled: !disabledGames.includes('cards') && walletClientType === 'privy',
    },
    {
      title: 'CRASH',
      img: SVGS.crashIcon,
      to: '/crash',
      particleColors: MENU_COLORS.crash.one,
      isEnabled: !disabledGames.includes('crash'),
    },
    {
      title: 'COIN FLIP',
      img: SVGS.walletIcon,
      to: '/coin-flip',
      particleColors: MENU_COLORS.coinFlip.one,
      isEnabled: !disabledGames.includes('coin flip'),
    },
    {
      title: 'PLINKO',
      img: SVGS.plinkoIcon,
      to: '/plinko',
      particleColors: MENU_COLORS.plinko.one,
      isEnabled: !disabledGames.includes('plinko'),
    },
    {
      title: 'ROCK PAPER SCISSORS',
      img: SVGS.scissorIcon,
      to: '/rps',
      particleColors: MENU_COLORS.rps.one,
      isEnabled: !disabledGames.includes('rps'),
    },
    {
      title: 'ROULETTE',
      img: SVGS.rouletteIcon,
      to: '/roulette',
      particleColors: MENU_COLORS.roulette.one,
      isEnabled: !disabledGames.includes('roulette'),
    },
    {
      title: 'DISCORD',
      img: SVGS.discordIcon,
      to: 'https://discord.com/invite/eUEwY3vS8R',
      particleColors: MENU_COLORS.discord.one,
      isEnabled: true,
    },
    {
      title: 'X (TWITTER)',
      img: SVGS.xIcon,
      to: 'https://www.x.com/fareplayio',
      particleColors: MENU_COLORS.x.one,
      isEnabled: true,
    },
  ]
  return mobileRouteLinks.filter(routeLink => routeLink.isEnabled)
}

export const externalLinks: Omit<IRouteLink, 'bgColor' | 'borderColor' | 'isEnabled'>[] = [
  {
    title: 'DISCORD',
    img: SVGS.discordIcon,
    to: 'https://discord.com/invite/eUEwY3vS8R',
    alt: 'discord',
  },
  {
    title: 'X (TWITTER)',
    img: SVGS.xIcon,
    to: 'https://www.x.com/fareplayio',
    alt: 'x',
  },
]
