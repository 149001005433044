import { type GameHelperFunctions } from './types'
import { formatEther, parseEther } from 'viem'
import { DEFAULT_MAX_COUNT } from './constants'
import { sortBigIntQK } from '../vault/helpers'

export enum CardPackSelection {
  Explorer = 0,
  Challenger = 1,
  Cryptonaught = 2,
}

export const isValidCardsSide = (side = 0) => {
  return side < 3 && side >= 0
}

export const cardDrawCountToOpenAPack = 6

export const getMaxCountForCards = (side: number) => {
  return DEFAULT_MAX_COUNT
}

// @NOTE: Since it has multiple multipliers, it returns the highest one of them
export const getCardsMultiplierWithPPVFromSide = (side: number) => {
  return Math.max(...packIndexToPackQKForACardDraw[side].k.map(val => Number(formatEther(val))))
}

export const getCardsPotentialProfitCoefficientFromSide = (side: number) => {
  return getCardsMultiplierWithPPVFromSide(side) - 1
}

export const getCardsMultiplierWithoutPPVFromSide = (side: number) => {
  return 0
}

export const getCardsKellyFractionFromSide = (side: number) => {
  return 0
}

export const cardsHelperFunctions: GameHelperFunctions<number> = {
  isValidSide: isValidCardsSide,
  getMaxCount: getMaxCountForCards,
  getMultiplierWithoutPPV: getCardsMultiplierWithoutPPVFromSide,
  getMultiplierWithPPV: getCardsMultiplierWithPPVFromSide,
  getKellyFraction: getCardsKellyFractionFromSide,
  getPotentialProfitCoefficient: getCardsPotentialProfitCoefficientFromSide,
}

// @NOTE: Using String to represent the numbers because of possible problems that can be caused by precision lose using numbers
const sortedQKForPack0 = sortBigIntQK({
  // EV: 0.71175
  q: ['0.8625', '0.11', '0.0275'].map(val => parseEther(val)),
  k: ['0.06', '3', '12'].map(val => parseEther(val)),
})
const sortedQKForPack1 = sortBigIntQK({
  // EV: 0.831549
  q: ['0.004583', '0.009167', '0.0275', '0.1375', '0.275', '0.54625'].map(val => parseEther(val)),
  // k: ['36', '18', '6', '1.2', '0.6', '0.012'].map(val => parseEther(val)),
  k: ['36', '18', '6', '1.2', '0.6', '0.012'].map(val => parseEther(val)),
})
const sortedQKForPack2 = sortBigIntQK({
  // EV: 0.88896916666
  q: [
    '0.001833333333333333',
    '0.002291666666666667',
    '0.003055555555555557',
    '0.018333333333333333',
    '0.03666666666666667',
    '0.055',
    '0.18333333333333335',
    '0.5499999999999999',
    '0.14948611111111119',
  ].map(val => parseEther(val)),
  k: ['60', '48', '36', '6', '3', '2', '0.6', '0.2', '0.06'].map(val => parseEther(val)),
})

export const packIndexToPackQKForACardDraw = [
  {
    q: sortedQKForPack0.sortedQ,
    k: sortedQKForPack0.sortedK,
  },
  {
    q: sortedQKForPack1.sortedQ,
    k: sortedQKForPack1.sortedK,
  },
  {
    q: sortedQKForPack2.sortedQ,
    k: sortedQKForPack2.sortedK,
  },
]
