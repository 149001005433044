import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

interface CardsGameState {
  didPlayerWin: boolean | null
  cardResults: number[]
  packAvailable: boolean
  backendResult: any
}

interface CardsGameActions {
  resolveEntry: (payload: {
    resultSides: any[]
    playedCount: number
    deltaAmounts: number[]
    totalDeltaAmount: number
  }) => void
  clearBackendResult: () => void
  resetGame: () => void
  setCardResults: (results: number[]) => void
  setPackAvailable: (available: boolean) => void
}

const initialCardsGameState = {
  didPlayerWin: null,
  cardResults: [],
  packAvailable: false,
  backendResult: null,
}

type CardsGameStore = CardsGameState & CardsGameActions
export const useCardsGameStore = create<CardsGameStore>()(
  devtools(
    immer((set, get) => ({
      ...initialCardsGameState,

      resolveEntry: (payload: {
        resultSides: any[]
        playedCount: number
        deltaAmounts: number[]
        totalDeltaAmount: number
      }) => {
        console.log('DEBUG::: card resultsides: ', payload.resultSides)
        console.log('DEBUG::: deltaAmounts: ', payload.deltaAmounts)

        const state = get()
        state.setCardResults(payload.resultSides)
        set({
          backendResult: {
            resultSides: payload.resultSides,
            playedCount: payload.playedCount,
            deltaAmounts: payload.deltaAmounts,
            totalDeltaAmount: payload.totalDeltaAmount,
          },
        })
      },

      clearBackendResult: () =>
        set(state => {
          state.backendResult = null
        }),

      resetGame: () =>
        set(state => {
          state.didPlayerWin = null
        }),

      setCardResults: results =>
        set(state => {
          state.cardResults = results
        }),

      setPackAvailable: available =>
        set(state => {
          state.packAvailable = available
        }),
    }))
  )
)
