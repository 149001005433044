import { Canvas } from '@react-three/fiber'
import { Bloom, EffectComposer } from '@react-three/postprocessing'
import { Physics } from '@react-three/rapier'
import { DiceScene } from './DiceScene'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { AppGameName } from '@/chains/types'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SBorderAnimator } from '../shared/BorderFlash'
import { VolumeSlider, SVolumeSliderWrapper } from '@/components/shared/SoundSystem/VolumeSlider'
import { MotionCanvas } from 'framer-motion-3d'
import { DiceSliderWrapper, SCanvas } from '../styles'
import { useCanvasResize } from '@/hooks/useCanvasResize'
import { useDiceGameState } from '@/store/useGameStateStore'
import { DiceSlider } from '@/components/DiceSlider/DiceSlider'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { type ICanvas } from '../shared/mountedInterface'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'
import DiceLandAudio from '@/assets/audio/_land.wav'
import DicePrepAudio from '@/assets/audio/Device 7 Start.wav'
import DiceResetAudio from '@/assets/audio/Device 7 Stop.wav'
import WinSoundAudio from '@/assets/audio/coins/Coins 11.wav'
import { ShareWinningsIconButton } from '@/components/shared/ShareWinningsIconButton'
import { ResizeWrapper } from '@/components/shared/ResizeWrapper'

const GAME_SOUNDS = {
  diceLand: DiceLandAudio,
  dicePrep: DicePrepAudio,
  diceReset: DiceResetAudio,
  winSound: WinSoundAudio,
}

function DiceCanvas({ isMounted, setIsMounted }: ICanvas) {
  const isMobileScreen = useIsBreakpoint('sm')
  const containerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { canvasSize, isResizing } = useCanvasResize(containerRef, canvasRef)
  const gravityY = -30

  const gravity = useMemo(() => [0, gravityY, 0] as [number, number, number], [gravityY])

  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)

  // sfx
  const soundContext = useSound()

  useEffect(() => {
    if (soundContext && typeof soundContext.loadSound === 'function') {
      // console.log('DEBUG Loading game sounds in DiceCanvas')
      Object.entries(GAME_SOUNDS).forEach(([key, path]) => {
        soundContext.loadSound(key, path)
      })
    } else {
      console.warn('DEBUG Cannot load sounds: Sound context not available in CoinFlipCanvas')
    }

    // Cleanup function to unload the sounds when the component unmounts
    return () => {
      if (soundContext && typeof soundContext.unloadSound === 'function') {
        console.log('DEBUG Unloading game sounds in CoinFlipCanvas')
        Object.keys(GAME_SOUNDS).forEach(key => {
          soundContext.unloadSound(key)
        })
      }
    }
  }, [soundContext])

  const playGameSound = useCallback(
    (soundName: string, volume = 1, pitch = 1) => {
      if (soundContext && typeof soundContext.playSound === 'function') {
        soundContext.playSound(soundName, volume, pitch)
      }
    },
    [soundContext]
  )

  const { selectedSide, setSelectedSide } = useDiceGameState(state => ({
    selectedSide: state.entry.side,
    setSelectedSide: (side: number) => state.setEntry({ side }),
  }))

  return (
    <SCanvas ref={containerRef} style={{ opacity: isMounted ? 1 : 0 }}>
      <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
        <DeltaNumbersDisplay gameName={AppGameName.Dice} />
        <MotionCanvas
          className='dice-canvas'
          ref={canvasRef}
          key={`${canvasSize.width}-${canvasSize.height}`}
          onCreated={_state => {
            setTimeout(() => {
              setIsMounted(true)
            }, 600)
          }}
          shadows
          dpr={[1, 2]}
          gl={{ antialias: true, stencil: false, preserveDrawingBuffer: false, alpha: true }}
        >
          <EffectComposer>
            <Bloom luminanceThreshold={1.35} luminanceSmoothing={0.8} intensity={1} />
          </EffectComposer>
          <fog attach='fog' args={[0x090909, 5, 15]} />
          <Physics debug={false} gravity={gravity}>
            <DiceScene playGameSound={playGameSound} />
          </Physics>
        </MotionCanvas>
        <Canvas style={{ display: 'none' }}>
          <group />
        </Canvas>
        <SVolumeSliderWrapper>
          <VolumeSlider />
        </SVolumeSliderWrapper>
        {didPlayerWin && <ShareWinningsIconButton />}
        {isMobileScreen && (
          <DiceSliderWrapper>
            <DiceSlider sliderValue={selectedSide} setSliderValue={setSelectedSide} />
          </DiceSliderWrapper>
        )}
      </SBorderAnimator>
      {isResizing && <ResizeWrapper />}
    </SCanvas>
  )
}
export default DiceCanvas
