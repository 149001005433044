import { motion } from 'framer-motion'
import { FARE_COLORS } from '@/design/colors'
import { BREAKPOINTS, SPACING } from '@/design/spacing'
import { COMPONENTS } from '@/design/components'
import { AvatarWrapper } from '../Avatar/style'
import { deviceBP } from '@/design/breakpoints'
import { noUserSelect } from '@/style'

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 100;
  width: 100svw;
`

export const MainHeader = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00000050;
  padding: 0 ${SPACING.md}px;
  height: ${COMPONENTS.header}px;
  backdrop-filter: blur(8px);
  gap: 16px;

  /* Vendor prefixes */
  -webkit-backdrop-filter: blur(8px); /* Safari and Chrome */
  -moz-backdrop-filter: blur(8px); /* Firefox */
  -ms-backdrop-filter: blur(8px); /* Edge */

  > * {
    margin: auto 24px;
  }

  ${AvatarWrapper} {
    width: 32px;
    height: 32px;

    > img {
      height: 20px;
      width: 20px;
    }
  }

  @media ${deviceBP.md} {
    gap: 0px;
    padding: 0px;
  }

  @media (min-aspect-ratio: 16/9) and (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    display: none;
  }
`

export const LeftHeader = styled.div`
  display: flex;
  flex: 1;
  ${noUserSelect}

  > img {
    margin: auto 0;
    height: 16px;
  }

  @media (max-width: 760px) {
    display: none;
  }
`
export const ScrollRightIndicator = styled(motion.img)<{ isVisible: boolean }>`
  position: flex-end;
  padding-left: 16px;
  cursor: pointer;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.5s ease;
`

export const ScrollLeftIndicator = styled(motion.img)<{ isVisible: boolean }>`
  position: flex-start;
  padding-right: 16px;
  cursor: pointer;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.5s ease;
`

export const RightHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  img {
    height: ${SPACING.lg}px;
    width: ${SPACING.lg}px;
  }
`

export const MenuDropDownWrapper = styled.div`
  margin-left: auto;
  z-index: 100;
`

export const AccountWrapper = styled(motion.div)`
  display: flex;
  gap: 12px;
  padding: ${SPACING.xs}px 0px;
  border-radius: 6px !important;

  > * {
    margin: auto 0 !important;
  }

  p {
    margin-right: ${SPACING.sm}px !important;
  }

  .wrong-network-btn {
    margin-right: 16px !important;
  }

  @media (max-width: 1400px) {
    gap: 0px;
  }

  @media ${deviceBP.sm} {
    position: absolute;
    left: 0;
    margin-left: ${SPACING.xs}px;
    justify-content: flex-start;
    gap: 6px;
  }
`

const gradientKeyframe = keyframes`
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }
  
  100% {
    background-position: 0% 50%
  }
`

export const FARE_GRADIENT = css`
  background: linear-gradient(
    to right,
    ${FARE_COLORS.blue},
    ${FARE_COLORS.pink},
    ${FARE_COLORS.salmon}
  );
  background-size: 400% 400%;

  -webkit-animation: ${gradientKeyframe} 10s ease infinite;
  -moz-animation: ${gradientKeyframe} 10s ease infinite;
  animation: ${gradientKeyframe} 10s ease infinite;
`

export const HeaderStrip = styled.div`
  width: 100%;
  height: 1px;
  ${FARE_GRADIENT};

  @media (min-aspect-ratio: 16/9) and (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    display: none;
  }
`

export const HeaderLinkWrapper = styled.div<{ $isDisabled?: boolean }>`
  display: flex;

  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 350px;
    overflow-x: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
    scrollbar: none;
    -webkit-overflow-scrolling: touch;
  }

  @media (max-width: 1000px) {
    width: 250px;
  }

  @media (max-width: 912px) {
    width: 175px;
  }

  @media (max-width: 760px) {
    display: none;
  }

  ${props => props.$isDisabled && 'pointer-events: none;'}
`

export const HeaderLink = styled(motion.div)<{ isActive?: boolean }>`
  display: grid;
  align-items: center;
  line-height: 0;

  > a {
    display: grid;
    height: 36px;
    width: 36px;
    transition: 0.2s ease-in-out;
    border: 2px solid transparent;
    align-items: center;
    justify-content: center;
  }

  ${({ isActive }) =>
    !isActive ?
      css`
        > * {
          opacity: 0.25;
        }
      `
    : css`
        > a {
          line-height: 0;
          border-radius: 6px;
          border: 2px solid ${FARE_COLORS.blue};
        }
      `}

  &:not(:last-of-type) {
    margin-right: ${SPACING.lg}px;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  img {
    width: 20px;
    height: 20px;
  }
`

export const SelectItem = styled.div`
  padding: ${SPACING.sm}px !important;
`

export const SimpleSpinner = styled(motion.div)`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid ${FARE_COLORS.blue};
  border-top-color: #333;
  animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`
export const SimpleSpinnerContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const FreePlayWrapper = styled(motion.div)`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid ${FARE_COLORS.aqua};
  box-shadow: 0px 0px 3px ${FARE_COLORS.aqua};
  background: transparent;
  height: 42px;
  width: 150px;
`

export const NetworkDropdownWrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`
