import { type MutableRefObject, type InputHTMLAttributes, type KeyboardEventHandler } from 'react'
import { NumericFormat, type NumericFormatProps } from 'react-number-format'
import { BaseInput, InputWrapper, Prefix, Suffix } from './styles'
interface IInput {
  inputPrefix?: JSX.Element
  inputSuffix?: JSX.Element
  isNumeric?: boolean
  onEnter?: KeyboardEventHandler<HTMLInputElement>
  customRef?: MutableRefObject<HTMLInputElement | null>
  hasInputSlider?: boolean
}

export const Input = ({
  inputPrefix,
  inputSuffix,
  isNumeric,
  onEnter,
  getInputRef,
  customRef,
  ...props
}: IInput & InputHTMLAttributes<HTMLInputElement> & NumericFormatProps) => {
  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(null)

  const setInputRef = useCallback(
    (ref: HTMLInputElement) => {
      inputRef.current = ref
      if (typeof getInputRef === 'function') getInputRef(ref)
    },
    [getInputRef]
  )

  useEffect(() => {
    const inputElem = getInputRef ? inputRef.current : customRef?.current
    if (!inputElem) return

    const execOnEnter: KeyboardEventHandler<HTMLInputElement> = e => {
      if (e.code === 'Enter' && onEnter !== undefined) {
        onEnter(e)
      }
    }
    inputElem.addEventListener('keypress', execOnEnter as any)

    return () => inputElem.removeEventListener('keypress', execOnEnter as any)
  }, [customRef, getInputRef, onEnter])

  return (
    <InputWrapper $hasPrefix={!!inputPrefix} className='fare-input-wrapper'>
      {inputPrefix && <Prefix className='input-prefix'>{inputPrefix}</Prefix>}
      {isNumeric ?
        <NumericFormat
          customInput={BaseInput}
          $hasPrefix={Boolean(inputPrefix)}
          {...(props as any)}
          getInputRef={setInputRef}
        />
      : <BaseInput
          type={'text'}
          {...(props as InputHTMLAttributes<HTMLInputElement>)}
          ref={customRef}
        />
      }
      {inputPrefix && <Suffix className='input-suffix'>{inputSuffix}</Suffix>}
    </InputWrapper>
  )
}
