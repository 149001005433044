import { create } from 'zustand'

interface GameOutcomeState {
  selectedPackId: any
  isShowingOutcome: boolean
  didPlayerWin: boolean
  intensity: number
  setIsShowingOutcome: (isShowingOutcome: boolean) => void
  setDidPlayerWin: (didPlayerWin: boolean) => void
  setIntensity: (intensity: number) => void
}

export const useGameOutcomeStore = create<GameOutcomeState>(set => ({
  selectedPackId: null,
  isShowingOutcome: false,
  didPlayerWin: false,
  intensity: 1,
  setIsShowingOutcome: (isShowingOutcome: boolean) => set({ isShowingOutcome }),
  setDidPlayerWin: (didPlayerWin: boolean) => set({ didPlayerWin }),
  setIntensity: (intensity: number) => set({ intensity }),
}))
