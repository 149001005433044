import React from 'react'
import { CrashThree } from './CrashCanvas'
import { CrashForm } from '@/components/Games/Crash/CrashForm'
import GamePageComponent from '@/components/shared/GamePage'
import { AppGameName } from '@/chains/types'

export const CrashPage: React.FC = () => {
  return (
    <GamePageComponent
      mode={AppGameName.Crash}
      CanvasComponent={CrashThree}
      FormComponent={CrashForm}
    />
  )
}
