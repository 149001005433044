import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  type MutableRefObject,
} from 'react'
import { NumericFormat, type NumericFormatProps } from 'react-number-format'
import ReactSlider, { type ReactSliderProps } from 'react-slider'
import { useSound } from '../SoundSystem/SoundContext'
import {
  BaseInput,
  InputWrapper,
  Prefix,
  SReactSliderWrapper,
  SSliderThumb,
  Suffix,
} from './styles'

const Thumb = (props: any, _state: any) => <SSliderThumb {...props} />

interface IFareNumberInput extends Omit<NumericFormatProps, 'getInputRef'> {
  inputPrefix?: JSX.Element
  inputSuffix?: JSX.Element
  customRef?: MutableRefObject<HTMLInputElement | null>
  customInputSlider?: React.ComponentType<any>
  hasInputSlider?: boolean
  inputSliderProps?: ReactSliderProps & {
    totalSteps?: number
  }
  isTextEditDisabled?: boolean
}

export const FareNumberInput = forwardRef<HTMLInputElement, IFareNumberInput>(
  (
    {
      inputPrefix,
      inputSuffix,
      customRef,
      customInputSlider: CustomSlider,
      hasInputSlider = false,
      inputSliderProps,
      isTextEditDisabled = false,
      ...props
    },
    ref
  ) => {
    const inputRef: MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(null)
    const { loadSound, playSound } = useSound()
    const lastPlayTime = useRef(0)
    const lastValue = useRef(inputSliderProps?.defaultValue ?? 0)
    const reachedMinRef = useRef(false)
    const reachedMaxRef = useRef(false)

    const hasPrefix = useMemo(() => Boolean(inputPrefix), [inputPrefix])
    const hasSuffix = useMemo(() => Boolean(inputSuffix), [inputSuffix])

    useEffect(() => {
      loadSound('clickSound2', '/src/assets/audio/bombs-mouse-over.wav')
      loadSound('mouseDownSound', '/src/assets/audio/Click 11.wav')
      loadSound('maxSound', '/src/assets/audio/mouse-over-slider.wav')
      loadSound('minSound', '/src/assets/audio/mouse-over-slider.wav')
    }, [loadSound])

    const setInputRef = useCallback(
      (elem: HTMLInputElement | null) => {
        inputRef.current = elem
        if (customRef && typeof customRef !== 'function') {
          customRef.current = elem // Safe to assign since customRef is MutableRefObject
        }
        if (typeof ref === 'function') {
          ref(elem)
        } else if (ref) {
          ;(ref as MutableRefObject<HTMLInputElement | null>).current = elem
        }
      },
      [customRef, ref]
    )

    const onFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
      event => event.target.select(),
      []
    )

    const onEnterBlur: React.KeyboardEventHandler<HTMLInputElement> = useCallback(event => {
      if (event.key === 'Enter') {
        event.currentTarget.blur()
      }
    }, [])

    const handleSliderChange = useCallback(
      (value: number | readonly number[], index: number) => {
        const now = Date.now()
        const timeSinceLastPlay = now - lastPlayTime.current

        if (timeSinceLastPlay < 50) return // Throttle to max 20 plays per second

        const min = inputSliderProps?.min ?? 0
        const max = inputSliderProps?.max ?? 100

        // Ensure value is a number
        const numericValue = Array.isArray(value) ? value[0] : value

        // Check if we've reached the min or max
        if (numericValue === min && !reachedMinRef.current) {
          playSound('minSound', 0.1, 0.1)
          reachedMinRef.current = true
          reachedMaxRef.current = false
        } else if (numericValue === max && !reachedMaxRef.current) {
          playSound('maxSound', 0.1, 0.1)
          reachedMaxRef.current = true
          reachedMinRef.current = false
        } else if (numericValue !== min && numericValue !== max) {
          const valueChange = Math.abs(numericValue - lastValue.current)
          const speed = valueChange / timeSinceLastPlay

          const minSpeed = 0.001
          const maxSpeed = 5
          const minPitch = 1
          const maxPitch = 1.05
          const minVolume = 0.2
          const maxVolume = 0.3

          const normalizedSpeed = Math.min(
            Math.max((speed - minSpeed) / (maxSpeed - minSpeed), 0),
            1
          )
          const pitch = minPitch + normalizedSpeed * (maxPitch - minPitch)
          const volume = minVolume + normalizedSpeed * (maxVolume - minVolume)

          playSound('clickSound2', volume, pitch)

          reachedMinRef.current = false
          reachedMaxRef.current = false
        }

        lastPlayTime.current = now
        lastValue.current = numericValue
        inputSliderProps?.onChange?.(value as number, index)
      },
      [playSound, inputSliderProps]
    )

    const handleWheel: React.WheelEventHandler<HTMLDivElement> = useCallback(
      ev => {
        if (!inputSliderProps) return
        const { deltaY, shiftKey } = ev
        const { min, max, step, totalSteps, value } = inputSliderProps

        if (typeof value !== 'number' || typeof min !== 'number' || typeof max !== 'number') return

        const actualStep = totalSteps ? (max - min) / totalSteps : step || 1
        const incrementAmount = shiftKey ? 5 * actualStep : actualStep

        let newVal = value
        if (deltaY < 0) {
          newVal = Math.min(value + incrementAmount, max)
        } else if (deltaY > 0) {
          newVal = Math.max(value - incrementAmount, min)
        }

        handleSliderChange(newVal, 0)
      },
      [inputSliderProps, handleSliderChange]
    )

    return (
      <InputWrapper
        $hasPrefix={hasPrefix}
        $hasSuffix={hasSuffix}
        className='fare-number-input-new'
        onWheel={handleWheel}
      >
        {inputPrefix && <Prefix>{inputPrefix}</Prefix>}
        <NumericFormat
          id='input-num-format-new'
          className='input-numeric-format-new'
          customInput={BaseInput as any}
          getInputRef={setInputRef}
          onFocus={onFocus}
          onKeyDown={onEnterBlur}
          disabled={isTextEditDisabled}
          {...props}
        />
        {inputSuffix && <Suffix>{inputSuffix}</Suffix>}
        {hasInputSlider &&
          inputSliderProps &&
          (CustomSlider ?
            <CustomSlider {...inputSliderProps} disabled={props.disabled} />
          : <SReactSliderWrapper
              id='fare-input-slider-wrapper'
              $disabled={inputSliderProps.disabled}
            >
              <ReactSlider
                className='fare-input-slider'
                renderThumb={Thumb}
                {...inputSliderProps}
                onChange={handleSliderChange}
              />
            </SReactSliderWrapper>)}
      </InputWrapper>
    )
  }
)

FareNumberInput.displayName = 'FareNumberInputNew'
