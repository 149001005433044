import { LoadingChip } from '@/components/shared/LoadingChip'

const ResizingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`
export const ResizeWrapper = (): JSX.Element => {
  return (
    <ResizingWrapper>
      <LoadingChip width={56} height={56} />
    </ResizingWrapper>
  )
}
