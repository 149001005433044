import { LOCAL_STORAGE } from '@/constants/storage'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { useActiveWallet } from '@/lib/privy/hooks/useActiveWallet'
import { usePrivy, useWallets, useUser, useCreateWallet } from '@privy-io/react-auth'

export const SmartWalletListener = () => {
  const { wallets } = useWallets()
  const { refreshUser } = useUser()
  const { ready, authenticated } = usePrivy()
  const { createWallet } = useCreateWallet()
  const { privyWallet } = useActiveWallet()
  const { setIsUsingSmartWallet } = useAppChainConfigStore(state => ({
    setIsUsingSmartWallet: state.setIsUsingSmartWallet,
  }))

  useEffect(() => {
    if (!privyWallet && ready && authenticated) {
      createWallet().then(console.log).catch(console.error)
    }
  }, [ready, authenticated, privyWallet, createWallet])

  useEffect(() => {
    if (!ready || !authenticated || wallets.length === 0) return

    const hasExternalWallet =
      wallets.filter(wallet => wallet.connectorType !== 'embedded').length > 0
    const localStorageIsUsingSmartWallet = localStorage.getItem(LOCAL_STORAGE.IS_USING_SMART_WALLET)

    if (hasExternalWallet && !localStorageIsUsingSmartWallet) return setIsUsingSmartWallet(false)

    if (!hasExternalWallet) {
      setIsUsingSmartWallet(true)
    }
  }, [ready, authenticated, wallets])

  useEffect(() => {
    if (ready && authenticated) {
      refreshUser()
    }
  }, [ready, authenticated])

  return null
}
