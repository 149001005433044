import numeral from 'numeral'

import mouseOverSliderAudio from '@/assets/audio/mouse-over-slider.wav'
import device7StopAudio from '@/assets/audio/Device 7 Stop.wav'
import click11Audio from '@/assets/audio/Click 11.wav'
import click12Audio from '@/assets/audio/Click 12.wav'

import { Button, ButtonEnum } from '.'
import { isValidSide } from '@/lib/crypto'
import ConnectWallet from '../Wallet/ConnectWallet'
import { useGameContract } from '@/components/Singletons/useGameContract'
import { useSound } from '../../shared/SoundSystem/SoundContext'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'
import { useActiveWallet } from '@/lib/privy/hooks'
import NetworkSwitchButton from '@/components/SwitchNetworkButton'
import { useBufferZone } from '@/hooks/useBufferZone'
import { useAllowance } from '@/hooks/useAllowance'
import { usePathGameName } from '@/hooks/usePathGameName'
import { useHasAppLoaded } from '@/hooks/useHasAppLoaded'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useFundModal } from '@/hooks/useFundModal'
import { AppGameName } from '@/chains/types'
import { useQuickplaySmartWallet } from '@/hooks/useQuickplaySmartWallet'
import {
  quickPlayModalState,
  usequickPlayModalState,
} from '../../Modals/QuickPlayModal/quickPlayModalState'
import { QuickPlayModal } from '../../Modals/QuickPlayModal'
import { useSnapshot } from 'valtio'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'

export const GameButton = ({
  entryAmountNum,
  formData,
}: {
  entryAmountNum: number
  formData: any
}) => {
  const { appAddresses, networkStyle, isWrongNetwork, isUsingSmartWallet } = useAppChainConfig()
  const hasSetupSmartWallet = useAppChainConfigStore.use.hasSetupSmartWallet()
  const { isWalletAuthed } = useActiveWallet()
  const { loadSound, playSound } = useSound()
  const { openModal: openFundWalletModal } = useFundModal()
  const { setquickPlayModal, showquickPlayModal } = usequickPlayModalState()
  const isQuickplayModalVisible = useSnapshot(quickPlayModalState).isVisible
  const balances = useCurrencyStore(state => state.balances)
  const selectedCurrencyAmount = balances.currency
  const { approveAllowance, isApprovingAllowance, hasApprovedAllowance } = useAllowance(
    formData.entryAmount
  )
  const gameName = usePathGameName()
  const {
    submitEntry,
    approveContracts,
    isApprovingContracts,
    hasApprovedContracts,
    isSubmitting,
    inProgressEntry,
  } = useGameContract()
  const hasAppLoaded = useHasAppLoaded()
  const { sendSmartWalletTrialRegister } = useQuickplaySmartWallet()

  const { bufferedZone: maxAllowedBet } = useBufferZone(formData.side)

  const isValidSideBoolean = useMemo(() => {
    return Boolean(isValidSide(gameName, formData.side))
  }, [formData.side, gameName])

  const playButtonSound = useCallback(
    (soundType: 'button' | 'approve' | 'submit' | 'error') => {
      const soundMap = {
        button: 'buttonClick',
        approve: 'approveClick',
        submit: 'submitClick',
        error: 'errorClick',
      }
      playSound(soundMap[soundType], 0.3, 0.5) // Adjust volume as needed
    },
    [playSound]
  )

  const placeBet = useCallback(() => {
    if (isUsingSmartWallet) {
      if (hasSetupSmartWallet) {
        playButtonSound('submit')
        sendSmartWalletTrialRegister(formData)
      } else {
        showquickPlayModal()
      }
    } else {
      playButtonSound('submit')
      submitEntry(formData)
    }
  }, [
    playButtonSound,
    submitEntry,
    isUsingSmartWallet,
    formData,
    hasSetupSmartWallet,
    sendSmartWalletTrialRegister,
  ])

  useEffect(() => {
    loadSound('buttonClick', click11Audio)
    loadSound('approveClick', click12Audio)
    loadSound('submitClick', mouseOverSliderAudio)
    loadSound('errorClick', device7StopAudio)
  }, [loadSound])

  if (!hasAppLoaded) {
    return null
  }

  if (!isWalletAuthed) {
    return <ConnectWallet />
  }

  if (isWrongNetwork) {
    return <NetworkSwitchButton />
  }

  if (Number(selectedCurrencyAmount) === 0) {
    return (
      <>
        <Button
          onClick={openFundWalletModal}
          buttonType={ButtonEnum.CONNECT_WALLET}
          disabled={false}
        >
          <span>ADD FUNDS TO BET</span>
        </Button>
      </>
    )
  }

  if (inProgressEntry) {
    return (
      <Button
        onClick={() => {
          playButtonSound('button')
        }}
        buttonType={ButtonEnum.PRIMARY_1}
        disabled
        isLoading
        loadingText={'PLAYING'}
        type='button'
      >
        PLAYING
      </Button>
    )
  }

  // ╭─────────────────────────────────────────────────────────╮
  // │ If user is using external wallet prompt them to         │
  // │ approve game contracts and USDC                         │
  // ╰─────────────────────────────────────────────────────────╯
  if (!isUsingSmartWallet) {
    // @TODO: I havent understood all the logic for privy stuff but this should basically disable someone from playing Cards game if they are not using privy
    if (gameName === AppGameName.Cards) {
      return (
        <Button
          onClick={() => {}}
          buttonType={ButtonEnum.ERROR}
          disabled={true}
          isLoading={false}
          loadingText={''}
          type='button'
        >
          requires privy wallet
        </Button>
      )
    }
    if (!hasApprovedContracts) {
      return (
        <Button
          onClick={approveContracts}
          buttonType={ButtonEnum.CONNECT_WALLET}
          disabled={isApprovingContracts}
          isLoading={isApprovingContracts}
          loadingText={'APPROVING GAMES'}
          type='button'
        >
          APPROVE GAMES
        </Button>
      )
    }

    if (!hasApprovedAllowance) {
      return (
        <Button
          onClick={() => {
            playButtonSound('approve')
            approveAllowance(appAddresses.bankroll)
          }}
          buttonType={ButtonEnum.CONNECT_WALLET}
          disabled={isApprovingAllowance || entryAmountNum <= 0}
          isLoading={isApprovingAllowance}
          loadingText={'APPROVING ' + networkStyle.currencyName}
          type='button'
        >
          APPROVE {networkStyle.currencyName}
        </Button>
      )
    }
  }

  if (
    entryAmountNum > maxAllowedBet &&
    // NOTE: If side is not valid, do not bother showing this as it will show 'place bet' but it will be disabled
    isValidSideBoolean
  ) {
    return (
      <Button
        onClick={() => playButtonSound('error')}
        buttonType={ButtonEnum.PRIMARY_1}
        disabled={true}
        isLoading={isSubmitting}
        loadingText={'SUBMITTING ENTRY'}
        type='button'
      >
        LIMIT: {numeral(maxAllowedBet).format('0,0.00')}
      </Button>
    )
  }

  return (
    <>
      <Button
        onClick={placeBet}
        buttonType={ButtonEnum.PRIMARY_1}
        disabled={isSubmitting || entryAmountNum <= 0 || !isValidSideBoolean}
        isLoading={isSubmitting}
        loadingText={'SUBMITTING ENTRY'}
        type='button'
      >
        PLACE BET
      </Button>
      <QuickPlayModal
        formData={formData}
        setIsVisible={setquickPlayModal}
        isVisible={isQuickplayModalVisible}
      />
    </>
  )
}
