import { BREAKPOINTS, floatingContainer, SPACING } from '@/design'

export const SUserDashboard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  gap: ${SPACING.sm}px;
  margin-bottom: 24px;
  padding-block: 24px;
`

export const SGameStatBoxWrapper = styled.div`
  min-width: 24px;
  max-width: fit-content;
  height: 24px;
  margin: auto;
  align-items: center;
`

export const SGameStateDataWrapper = styled.div`
  font-size: 20px;
`

export const DynamicBorder = styled.div<{ $statColor: string }>`
  border: 1px solid ${props => props.$statColor};
  border-radius: 6px;
  flex: 1 1 calc(25% - ${SPACING.lg}px);
  padding: ${SPACING.sm}px;
  gap: ${SPACING.lg}px;
  color: ${props => props.$statColor};
`

export const SGameStatRow = styled.div`
  position: relative;
  display: flex;
  text-wrap: nowrap;
  gap: ${SPACING.lg}px;
  width: 100%;

  > td {
    padding: 0 ${SPACING.sm}px;
    text-align: left;
    width: 25%;
    min-width: 24px;
    max-width: fit-content;
    height: 24px;
    margin: auto;
    align-items: center;
  }
`

export const SUserTxHistory = styled.div`
  width: 100%;
  height: 50%;
  max-height: 400px;
  min-height: 180px;
  border-radius: 6px;
  flex-grow: 1;
  box-sizing: border-box;
  grid-column: 1 / span 5;
  user-select: none;
  ${floatingContainer};
  font-family: GohuUppercase, monospace;
  overflow: scroll;

  @media only screen and (max-width: ${BREAKPOINTS.lg}px) {
    grid-column: 1 / span 5;
  }
`
export const SCardCollectionContainer = styled.div`
  padding: 20px 20px 0px 20px;
  &:last-child {
    padding-bottom: 20px;
  }
`
export const SCardCollectionTitle = styled.div`
  margin-bottom: 10px;
`
export const SCardCollectionContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`
