import { SFormSection } from '../style'
import { BACKGROUND_COLORS, BORDER_COLORS, FARE_COLORS, SPACING, TEXT_COLORS } from '@/design'
import { motion } from 'framer-motion'
import { PNGS } from '@/assets'
import { deviceBP } from '@/design/breakpoints'

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2px;
  margin: 20px 0 5px;
  background-color: ${BORDER_COLORS.one};
  padding: 2px;
  border-radius: 2px;
`

export const SFormGridSection = styled(SFormSection)`
  padding-left: ${SPACING.md}px;
  padding-top: 0;
`

export const GridWrapper = styled.div`
  position: relative;
`

export const CheckboxContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -15px;
  display: flex;
  align-items: center;
`

export const StyledCheckbox = styled.input`
  margin-right: 4px;
  transform: scale(0.8);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid ${FARE_COLORS.gray};
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: ${FARE_COLORS.gray};

    &::after {
      content: '✓';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 12.8px;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  }
`

export const CheckboxLabel = styled.label`
  font-family: 'Gohu';
  font-size: 10px;
  color: ${TEXT_COLORS.two};
`

export const Tile = styled(motion.div)<{ $isSelected: boolean; $isDisabled: boolean }>`
  width: 100%;
  padding-bottom: 100%;
  // background-color: ${props => (props.$isSelected ? FARE_COLORS.gray : FARE_COLORS.black)};
  background-color: ${FARE_COLORS.black};
  border-radius: ${props => (props.$isSelected ? '4px' : '2px')};
  box-shadow: ${props => (props.$isSelected ? 'inset 0 0 15px rgba(74, 245, 211, 0.5)' : '')};
  cursor: ${props => (props.$isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.$isDisabled ? 0.5 : 1)};
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, transparent 0%, rgba(0, 0, 0, 0.1) 100%);
    opacity: ${props => (props.$isSelected ? 1 : 0)};
    transition: opacity 0.3s ease;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    height: 20%;
    background-color: ${FARE_COLORS.aqua}80;
    border-radius: 50%;
    opacity: ${props => (props.$isSelected ? 1 : 0)};
    transition: opacity 0.3s ease;
  }

  & > .border-draw {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &:hover {
    transform: scale(1.01);
    // background-color: ${props => (props.$isSelected ? '#572222' : '#1b1d26')};
    background-color: '#1b1d26';
    box-shadow: inset 0 0 15px rgba(74, 245, 211, 0.5);
  }
`

const smallIconUrl = PNGS.fareLogo

const ButtonContainer = styled.div`
  position: relative;
  display: inline-block;
`

interface IconProps {
  $iconUrl?: string // Optional URL for the icon
  $size?: string // Optional size for the icon
  $iconSrc?: string // Required source for the icon
}

const IconWithBloom = styled.div<IconProps>`
  width: ${({ $size }) => $size || '10px'};
  height: ${({ $size }) => $size || '10px'};
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${({ $iconUrl }) =>
      $iconUrl || smallIconUrl}); // Use the passed URL or default
    background-size: cover;
  }

  &::before {
    z-index: -1; // Behind the actual image
    filter: blur(5px) brightness(1.5); // Creates a glow effect
    opacity: 1;
  }

  &::after {
    filter: none; // No filter for the actual icon display
  }

  &:hover::before {
    filter: blur(7px) brightness(2); // Enhanced glow on hover
    opacity: 1;
  }
`

const MainIcon = styled(IconWithBloom)`
  margin-bottom: 0px;
`
const SmallIcon = styled(IconWithBloom)`
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url(${smallIconUrl});
  background-size: cover;
`

const TopLeftIcon = styled(SmallIcon)`
  top: 5px;
  left: 5px;
`

const BottomRightIcon = styled(SmallIcon)`
  bottom: 5px;
  right: 5px;
  transform: rotate(180deg);
`
const BottomRightIcon2 = styled(SmallIcon)`
  bottom: 5px;
  right: 5px;
  transform: rotate(180deg);
`
interface StyledPackButtonProps {
  $iconSrc: string
  $isActive?: boolean
  packCost: number
  onClick: () => void
  children: React.ReactNode
}
interface StyledCardProps {
  $iconSrc: string
  $isActive?: boolean
  $cardDescription: string
  $cardValue: number
  $cardTier: number
  onClick: () => void
  children: React.ReactNode
}
export const StyledPackButton = ({
  $iconSrc,
  $isActive,
  // packCost,
  onClick,
  children,
}: StyledPackButtonProps) => (
  <ButtonContainer>
    <TopLeftIcon />
    <CardPackSelectionButton $isActive={$isActive} onClick={onClick}>
      <MainIcon $iconSrc={$iconSrc} $iconUrl={$iconSrc} $size='40px' />
      {children}
    </CardPackSelectionButton>
    <BottomRightIcon />
  </ButtonContainer>
)

export const StyledCard = ({
  $iconSrc,
  $isActive,
  $cardDescription,
  $cardValue,
  $cardTier,
  onClick,
  children,
}: StyledCardProps) => (
  <ButtonContainer>
    <TopLeftIcon />
    <CardPackSelectionButton $isActive={$isActive} onClick={onClick}>
      <MainIcon $iconSrc={$iconSrc} $iconUrl={$iconSrc} $size='40px' />
      <Tooltip>
        {$cardDescription}
        [Tier: {$cardTier}] [Value: {$cardValue}]
      </Tooltip>
      {children}
    </CardPackSelectionButton>
    <BottomRightIcon2 />
  </ButtonContainer>
)

const Tooltip = styled.span`
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  z-index: 1;
  bottom: 125%; // Position above the button
  left: 50%;
  margin-left: -60px; // Use half of the width to center the tooltip
  opacity: 0;
  transition: opacity 0.3s;

  @media ${deviceBP.sm} {
    top: 0%; // Position on button
    height: fit-content;
    width: 70px;
    margin-left: -50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`

export const CardPackSelectionButton = styled.button<{ $isActive?: boolean }>`
  height: 110px;
  width: 80px;
  padding: 15px;
  border-radius: 4px;
  background: transparent;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:focus-visible {
    outline: none;
  }

  &:hover {
    border: 1px solid ${FARE_COLORS.blue}99 !important;

    ${Tooltip} {
      visibility: visible;
      opacity: 1;
    }
  }

  > span {
    font-family: 'GohuUppercase', monospace;
    line-height: 10px;
    font-size: 10px;
    margin-top: 10px;
  }

  ${({ $isActive }) =>
    $isActive ?
      css`
        border: 2px solid ${FARE_COLORS.blue} !important;
        color: ${TEXT_COLORS.one};
        background: ${BACKGROUND_COLORS.four};
      `
    : css`
        border: 1px solid ${BORDER_COLORS.one};
        color: ${TEXT_COLORS.two};
      `};
`
