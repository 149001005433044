import { SVGS } from '@/assets'
import ModalCard from '@/components/shared/Modal/Card'
import { CloseButtonIcon } from '@/components/shared/CloseButton'

interface IMobileLeaderboardModal {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

const LeaderBoardContent = styled.div`
  text-align: left;
  padding: 0 16px;
`

const ImageWrapper = styled.div`
  display: inline-flex;
  gap: 12px;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const MobileLeaderboardModal = ({ isVisible, setIsVisible }: IMobileLeaderboardModal) => {
  const handleClose = () => {
    localStorage.setItem('mobile-leaderboard-modal-dismissed', 'true')
    setIsVisible(false)
  }

  return (
    <ModalCard
      title='Join the board'
      description='Do you want to see your username on the leaderboard?'
      isVisible={isVisible}
      setIsVisible={handleClose}
      style={{ width: '100%', maxWidth: '100%' }}
    >
      <CloseButtonIcon onClick={handleClose} />
      <LeaderBoardContent>
        <p>1. Swipe left to the game screen.</p>
        <p>2. Fund your wallet and place a bet.</p>
        <p>3. Take over the Leaderboard!</p>
      </LeaderBoardContent>
      <ImageWrapper>
        <img src={SVGS.diceIcon} alt='Dice' width={32} />
        <img src={SVGS.coin} alt='Coin' width={32} />
        <img src={SVGS.rockIcon} alt='Hand' width={32} />
      </ImageWrapper>
    </ModalCard>
  )
}
