import { CardsCanvas } from '@/components/Canvases/CardsCanvas/index'
import { CardsForm } from '@/components/Forms/Cards'
import { LeftContainer, PageWrapper, RightContainer } from '../style'
import { GamePage } from '..'
import { RefactorLiveEntries } from '@/components/shared/LiveEntries/RefactorLiveEntries'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { GameLoadingChip } from '@/components/shared/GamePage/styled'
import FullScreenLoadingChip from '@/components/shared/FullScreenLoadingChip'
import { GameContractListener } from '@/components/Singletons/GameContractListener'

export const CardsPage = () => {
  const [isMounted] = useState(true)
  const isMobileScreen = useIsBreakpoint('sm')
  return (
    <PageWrapper>
      {!isMounted &&
        (isMobileScreen ? <GameLoadingChip width={48} height={48} /> : <FullScreenLoadingChip />)}
      <GamePage mode='cards'>
        <LeftContainer>
          <CardsCanvas />
          {!isMobileScreen && isMounted && <RefactorLiveEntries />}
        </LeftContainer>
        <RightContainer>{isMounted && <CardsForm />}</RightContainer>
      </GamePage>
      <GameContractListener />
    </PageWrapper>
  )
}
