import React, { useMemo } from 'react'
import { Controller, type Control } from 'react-hook-form'
import numeral from 'numeral'

import { clamp, ensureNumber } from '@/utils'
import { FareNumberInput } from '@/components/shared/Input/FareNumberInput'
import { FormAmountLabel, FormIndicator, FormLabel, FormLabelRow, SFormSection } from '../../style'

interface SliderInputProps {
  label: string
  label2?: string
  labelColor?: string
  label2Color?: string
  name: string
  control: Control<any>
  min: number
  max: number
  step: number
  defaultValue?: number
  disabled?: boolean
}

export const SliderInput: React.FC<SliderInputProps> = ({
  label,
  label2,
  labelColor,
  label2Color,
  name,
  control,
  min,
  max,
  step,
  defaultValue = min,
  disabled = false,
}) => {
  const totalSteps = useMemo(() => Math.round((max - min) / step), [min, max, step])

  const valueToSlider = (value: number) => {
    return Math.round(((value - min) / (max - min)) * 1000)
  }

  const sliderToValue = (sliderValue: number) => {
    const rawValue = (sliderValue / 1000) * (max - min) + min
    return Math.round((rawValue - min) / step) * step + min
  }

  return (
    <SFormSection style={{ paddingTop: '15px' }}>
      <FormLabelRow>
        <FormLabel
          $isRelative
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
          <div>
            <FormIndicator $isActive={true} style={{ left: -12, top: 4 }} />
            <FormAmountLabel style={{ color: labelColor }}>{label}</FormAmountLabel>
          </div>
          {label2 && <FormAmountLabel style={{ color: label2Color }}>{label2}</FormAmountLabel>}
        </FormLabel>
      </FormLabelRow>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          const value = ensureNumber(field.value)
          const clampedValue = clamp(value, min, max)
          const steppedValue = Math.round((clampedValue - min) / step) * step + min

          return (
            <FareNumberInput
              {...field}
              value={steppedValue}
              onChange={event => {
                const newValue = numeral(event.target.value).value() || min
                const newSteppedValue = Math.round((newValue - min) / step) * step + min
                field.onChange(clamp(newSteppedValue, min, max))
              }}
              allowLeadingZeros={false}
              allowNegative={false}
              isAllowed={({ floatValue = 0 }) => floatValue >= min && floatValue <= max}
              onFocus={event => event.target.select()}
              hasInputSlider
              disabled={disabled}
              inputSliderProps={{
                value: valueToSlider(steppedValue),
                onChange: newSliderValue => {
                  if (typeof newSliderValue === 'number') {
                    const actualValue = sliderToValue(newSliderValue)
                    field.onChange(clamp(actualValue, min, max))
                  }
                },
                min: 0,
                max: 1000,
                step: 1000 / totalSteps,
                disabled,
              }}
              thousandSeparator=','
            />
          )
        }}
      />
    </SFormSection>
  )
}
