import {
  BACKGROUND_COLORS,
  COMPONENTS,
  FARE_COLORS,
  FONT_STYLES,
  MENU_COLORS,
  SPACING,
  TEXT_COLORS,
} from '@/design'
import { motion } from 'framer-motion'

export const MobileMenuContentWrapper = styled(motion.div)`
  justify-content: center;
  grid-gap: ${SPACING.xxs}px;
  position: absolute;
  height: calc(100vh - ${COMPONENTS.header + 1}px);
  top: ${COMPONENTS.header + 1}px;
  z-index: 200;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${BACKGROUND_COLORS.one};
  overflow-y: scroll;
  transform-origin: top;
`

export const ImageWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;

  > img {
    width: 42px;
    height: 42px;
    margin: auto;
    padding: ${SPACING.xs}px;
  }
`

const getMenuLinkStyles = (isActive = false, colors: any) => css`
  background: ${isActive ?
    `linear-gradient(270deg, ${colors.two}, ${colors.three})`
  : `linear-gradient(270deg, ${colors.four}, transparent)`};
  color: ${isActive ? TEXT_COLORS.one : TEXT_COLORS.two};
  border-left: 3px solid ${isActive ? colors.one : 'transparent'};
  box-shadow: ${isActive ? `inset 10px 0 8px -8px ${colors.one}` : 'none'};
`

export const MenuLink = styled(Link)<{ isActive?: boolean }>`
  margin: auto;
  grid-column: span 1;
  grid-row: span 1;
  width: calc(100% - ${SPACING.sm * 2}px);
  height: 60px;
  margin: ${SPACING.sm}px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: max(100px, 30%) 1fr;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  position: relative;
  text-decoration: none;
  overflow: hidden !important;
  border-left: 3px solid transparent;
  transition: 0.2s all ease-in-out;

  > span {
    left: 0;
    right: 0;
    ${FONT_STYLES.lg};
    color: ${({ isActive }) => (isActive ? TEXT_COLORS.one : TEXT_COLORS.two)};
    margin: 0;
  }

  &:nth-of-type(1) {
    ${({ isActive }) => getMenuLinkStyles(isActive, MENU_COLORS.dice)}
  }

  &:nth-of-type(2) {
    ${({ isActive }) => getMenuLinkStyles(isActive, MENU_COLORS.bombs)}
  }
  &:nth-of-type(3) {
    ${({ isActive }) => getMenuLinkStyles(isActive, MENU_COLORS.cards)}
  }

  &:nth-of-type(4) {
    ${({ isActive }) => getMenuLinkStyles(isActive, MENU_COLORS.crash)}
  }

  &:nth-of-type(5) {
    ${({ isActive }) => getMenuLinkStyles(isActive, MENU_COLORS.coinFlip)}
  }

  &:nth-of-type(6) {
    ${({ isActive }) => getMenuLinkStyles(isActive, MENU_COLORS.plinko)}
  }

  &:nth-of-type(7) {
    ${({ isActive }) => getMenuLinkStyles(isActive, MENU_COLORS.rps)}
  }
  &:nth-of-type(7) {
    ${({ isActive }) => getMenuLinkStyles(isActive, MENU_COLORS.roulette)}
  }
  // DISCORD
  &:nth-of-type(8) {
    ${({ isActive }) =>
      isActive ?
        css`
          background: linear-gradient(
            270deg,
            ${MENU_COLORS.discord.one},
            ${MENU_COLORS.discord.one}
          );
          color: ${TEXT_COLORS.one};
          border-left: 3px solid ${MENU_COLORS.discord.one};
          box-shadow: inset 10px 0 8px -8px ${MENU_COLORS.discord.one};
        `
      : css`
          background: linear-gradient(270deg, ${MENU_COLORS.discord.one}, transparent);
          color: ${TEXT_COLORS.two};
        `};
  }

  // TWITTER
  &:nth-of-type(9) {
    ${({ isActive }) =>
      isActive ?
        css`
          background: linear-gradient(270deg, ${MENU_COLORS.x.one}, ${MENU_COLORS.x.one});
          color: ${TEXT_COLORS.one};
          border-left: 3px solid ${MENU_COLORS.x.one};
          box-shadow: inset 10px 0 8px -8px ${MENU_COLORS.x.one};
        `
      : css`
          background: linear-gradient(270deg, ${MENU_COLORS.x.one}, transparent);
          color: ${TEXT_COLORS.two};
        `};
  }
`

export const MenuSandwich = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  > div:not(:last-child) {
    margin-bottom: ${SPACING.xxs}px;
  }

  @media (min-width: 768px) {
    display: none;
  }

  ${props => props.$isDisabled && 'pointer-events: none;'}
`

const ROTATION_DELTA = 6

export const Bar = styled.div<{ isActive?: boolean }>`
  width: 25px;
  height: 2px;
  background-color: #333;
  transition: 0.4s;

  &:nth-child(1) {
    background: ${FARE_COLORS.salmon};
  }

  &:nth-child(2) {
    background: ${FARE_COLORS.pink};
  }

  &:nth-child(3) {
    background: ${FARE_COLORS.blue};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &:first-child {
        /* Rotate first bar */
        transform: translate(0, ${ROTATION_DELTA}px) rotate(-45deg);
        background-color: ${FARE_COLORS.salmon};
      }

      &:nth-child(2) {
        /* Fade out the second bar */
        opacity: 0;
      }

      &:last-child {
        /* Rotate last bar */
        transform: translate(0, -${ROTATION_DELTA}px) rotate(45deg);
        background-color: ${FARE_COLORS.salmon};
      }
    `}
`

export const RotatedBar = styled(Bar)`
  /* Rotate first bar */
  transform: translate(0, ${ROTATION_DELTA}px) rotate(-45deg);
`

export const HiddenBar = styled(Bar)`
  /* Fade out the second bar */
  opacity: 0;
`

export const RotatedBar2 = styled(Bar)`
  /* Rotate last bar */
  transform: translate(0, -${ROTATION_DELTA}px) rotate(45deg);
`
