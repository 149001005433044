import posthog from 'posthog-js'

import { useActiveWallet, usePrivyTwitterData } from '@/lib/privy/hooks'
import { usePrivyService } from '@/lib/privy/usePrivyService'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import useUserDataStore from '@/store/useUserDataStore'
import { usePrivy } from '@privy-io/react-auth'

export const AppChainConfigListener = () => {
  const { pathname } = useLocation()
  const { activeWallet, walletChainId, isWalletAuthed, walletAddress } = useActiveWallet()
  const { user, ready } = usePrivy()
  const twitterData = usePrivyTwitterData()
  const { verifyIdToken } = usePrivyService()
  const setUserData = useUserDataStore(state => state.setUserData)
  const { setAppProvider, setSessionVerifyState } = useAppChainConfigStore(state => ({
    setAppProvider: state.setAppProvider,
    setSessionVerifyState: state.setSessionVerifyState,
  }))

  useEffect(() => {
    if (!activeWallet?.address || !isWalletAuthed) return

    setAppProvider(walletChainId, activeWallet)
  }, [activeWallet?.address, walletChainId, isWalletAuthed])

  const verifyIdSetUserData = async (publicAddress: string | undefined) => {
    try {
      if (!publicAddress) {
        setSessionVerifyState('not-verified')
        posthog?.identify()
        posthog?.group('arbitrum-mainnet', 'deathmatch-season-2')
        posthog?.capture('guest_joined')
        return
      }
      setSessionVerifyState('pending')
      const shouldSyncSocials = pathname === '/settings' || Boolean(twitterData)
      const { userData } = await verifyIdToken(publicAddress, shouldSyncSocials)
      setUserData(userData)
      setSessionVerifyState('verified')

      posthog?.identify(publicAddress, {
        username: userData.username,
        email: user?.email,
        twitterData: user?.twitter,
        walletClientType: activeWallet?.walletClientType,
        walletConnectorType: activeWallet?.connectorType,
        privyUserId: user?.id,
      })
      posthog?.capture('user_signin_verified')
      posthog?.group('arbitrum-mainnet', 'deathmatch-season-2')
    } catch (err) {
      console.error('ISSUE VERIFYING ID/SETTING USER DATA')
      console.error(err)
      posthog?.capture('user_signin_verification_unauthorized', { errorMsg: String(err) })
      setSessionVerifyState('not-verified')
    }
  }

  useEffect(() => {
    if (!ready) return
    verifyIdSetUserData(walletAddress)
  }, [walletAddress, walletChainId, ready])

  return null
}
