import { SVGS } from '@/assets'

import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { getRouteLinks } from '@/constants/links'
import {
  HeaderLink,
  HeaderLinkWrapper,
  LeftHeader,
  ScrollLeftIndicator,
  ScrollRightIndicator,
} from './style'
import { Divider } from '../Divider'
import { useGetGameStoreType } from '@/store/useGameStateStore'
import { useActiveWallet } from '@/lib/privy/hooks'

export const LeftHeaderContent = () => {
  const { pathname } = useLocation()
  const isDesktopScreen = useIsBreakpoint('lg')
  const isMobileScreen = useIsBreakpoint('xs')
  const activePage = useMemo(() => {
    return pathname
  }, [pathname])
  const gameStoreType = useGetGameStoreType(pathname)
  const shouldDisable = useMemo(() => gameStoreType !== 'IDLE', [gameStoreType])

  const [isScrollable, setIsScrollable] = useState(false)
  const headerLinkWrapperRef = useRef<HTMLDivElement>(null)
  const [isAtEnd, setIsAtEnd] = useState(false)
  const [isAtStart, setIsAtStart] = useState(true)
  const { activeWallet } = useActiveWallet()
  const routeLinks = getRouteLinks(activeWallet?.walletClientType)

  useEffect(() => {
    const checkScrollable = () => {
      const { scrollWidth, clientWidth } = headerLinkWrapperRef.current || {
        scrollWidth: 0,
        clientWidth: 0,
      }
      setIsScrollable(scrollWidth > clientWidth)
    }

    checkScrollable()
    window.addEventListener('resize', checkScrollable)

    return () => {
      window.removeEventListener('resize', checkScrollable)
    }
  }, [])

  const handleScroll = () => {
    if (headerLinkWrapperRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = headerLinkWrapperRef.current
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth)
      setIsAtStart(scrollLeft === 0)
    }
  }

  const scrollRight = () => {
    headerLinkWrapperRef.current?.scrollBy({ left: 200, behavior: 'smooth' })
  }

  const scrollLeft = () => {
    headerLinkWrapperRef.current?.scrollBy({ left: -200, behavior: 'smooth' })
  }

  useEffect(() => {
    const currentRef = headerLinkWrapperRef.current
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <LeftHeader>
      {!isMobileScreen && <img src={SVGS.oneLineFpLogo} alt='fareplay-logo' />}
      {isDesktopScreen && (
        <>
          <Divider />
          <ScrollLeftIndicator
            src={SVGS.caretLeft}
            onClick={scrollLeft}
            alt='Scroll Right'
            isVisible={isScrollable && !isAtStart}
            initial={{ x: 0 }}
            animate={{ x: 5 }}
            exit={{ x: 0 }}
            transition={{
              delay: 2,
              duration: 0.7,
              repeat: Infinity,
              repeatType: 'reverse',
              damping: 10,
            }}
          />
          <HeaderLinkWrapper ref={headerLinkWrapperRef} $isDisabled={shouldDisable}>
            {routeLinks.map((link, i) => (
              <HeaderLink
                isActive={link.to === activePage}
                key={link.to}
                transition={{
                  duration: 0.25,
                  delay: (i + 1) * 0.1,
                  ease: 'easeIn',
                }}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <Link
                  to={link.to as string}
                  target={link.to.startsWith('https') ? '_blank' : undefined}
                >
                  <img src={link.img} alt={link.to} />
                </Link>
              </HeaderLink>
            ))}
          </HeaderLinkWrapper>
        </>
      )}
      <ScrollRightIndicator
        src={SVGS.caretRight}
        onClick={scrollRight}
        alt='Scroll Right'
        isVisible={isScrollable && !isAtEnd}
        initial={{ x: 0 }}
        animate={{ x: 5 }}
        exit={{ x: 0 }}
        transition={{
          delay: 2,
          duration: 0.7,
          repeat: Infinity,
          repeatType: 'reverse',
          damping: 10,
        }}
      />
    </LeftHeader>
  )
}
