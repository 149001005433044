import { SVGS } from '@/assets'
import { ButtonEnum } from '@/components/shared/Button'
import { ModalOverlayContainer, ModalWrapper } from '@/components/shared/Modal/styles'
import {
  ShareWinningsButton,
  ShareWinningsCard,
  ShareWinningsDescription,
  ShareWinningsTitle,
  ShareWinnngsBlocks,
} from './styles'
import useUserDataStore from '@/store/useUserDataStore'
import { getAppCurrencyDecimals } from '@/chains/lib'
import { utils } from 'ethers'
import numeral from 'numeral'
import { fsocket } from '@/lib/fsocket'
import { FARE_COLORS } from '@/design'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'
import axios from 'axios'

interface ShareWinningsModalProps {
  onClose: () => void
}

export const ShareWinningsModal = ({ onClose }: ShareWinningsModalProps) => {
  const { appChainConfig } = useAppChainConfig()
  const { latestSUEntries } = useUserDataStore()
  const { liveEntries } = fsocket.liveEntry.useState()
  const modalRef = useRef<HTMLDivElement>(null)

  const [isCopying, setIsCopying] = useState(false)

  //  game data
  const lastEntry = latestSUEntries[0] || { deltaAmount: 0, multiplier: 0, gameName: 'N/A' }
  // game name
  const lastGameName = lastEntry.gameName

  // delta amount
  const decimalizedDeltaAmount = Math.abs(
    Number(utils.formatUnits(lastEntry.deltaAmount, getAppCurrencyDecimals()))
  )
  const formattedDecimalizedDeltaAmount = numeral(decimalizedDeltaAmount).format('0,0.00')
  let winningsOrLosses = '-'
  if (Number(lastEntry.deltaAmount) > 0) {
    winningsOrLosses = '+'
  }

  // multiplier amount
  const formattedDecimalizedMultiplier = Number(
    utils.formatUnits(lastEntry.multiplier, getAppCurrencyDecimals())
  )

  // entry amount
  const entryAmount = numeral(
    utils.formatUnits(liveEntries[0]?.multiplier || 0, getAppCurrencyDecimals())
  ).format('0,0.00')
  const formattedEntryAmount = numeral(entryAmount).format('0,0.00')

  // profit or loss amount
  let profitOrLossAmount = null
  if (Number(lastEntry.deltaAmount) > 0) {
    profitOrLossAmount = numeral(
      Number(formattedDecimalizedDeltaAmount) + Number(formattedEntryAmount)
    ).format('0,0.00')
  } else if (Number(lastEntry.deltaAmount) < 0) {
    profitOrLossAmount = numeral(
      Number(formattedEntryAmount) - Number(formattedDecimalizedDeltaAmount)
    ).format('0,0.00')
  }

  const handleCopyImage = useCallback(async () => {
    setIsCopying(true)

    try {
      const totalWageredStr = entryAmount || '0'
      const totalWonStr = formattedDecimalizedDeltaAmount || '0'
      const currentPNLStr = String(formattedDecimalizedMultiplier || 0)
      const profitOrLossStr = profitOrLossAmount || '0'
      const entryAmtStr = entryAmount || '0' // or if the server needs it

      const response = await axios({
        url: `${appChainConfig.httpUrl}/share-card/generate`,
        responseType: 'blob',
        data: {
          gameName: lastGameName,
          totalWagered: totalWageredStr,
          totalWon: totalWonStr,
          currentPNL: currentPNLStr,
          profitOrLossAmount: profitOrLossStr,
          entryAmount: entryAmtStr,
        },
        method: 'POST',
        withCredentials: true,
      })

      // c) Get the PNG as a blob
      const blob = await response.data

      // d) Copy that PNG to the clipboard
      const clipboardItem = new ClipboardItem({ 'image/png': blob })

      await navigator.clipboard.write([clipboardItem])
    } catch (err) {
      console.error('Copy image error:', err)
      alert('Failed to copy image. Please check console for details.')
    } finally {
      setIsCopying(false)
    }
  }, [
    entryAmount,
    formattedDecimalizedDeltaAmount,
    formattedDecimalizedMultiplier,
    profitOrLossAmount,
    appChainConfig.httpUrl,
    lastGameName,
  ])

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <ModalOverlayContainer>
      <ModalWrapper
        ref={modalRef}
        style={{
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <ShareWinningsCard>
          <ShareWinningsTitle>
            <img src={SVGS.simpleFareCoin} alt='fare-coin' width={30} />
            <img src={SVGS.oneLineFpLogo} alt='fareplay-logo' width={130} />
          </ShareWinningsTitle>
          <ShareWinningsDescription>
            <p>{lastGameName}</p>
            <p className='gradient-text'>+{profitOrLossAmount} USD</p>
            <p className='current-pnl'>
              CURRENT PNL <span>{formattedDecimalizedMultiplier}X</span>
            </p>
          </ShareWinningsDescription>
          <ShareWinnngsBlocks className='stat-block'>
            <p>
              TOTAL WAGERED<span>${entryAmount}</span>
            </p>
            <p>
              TOTAL WON{' '}
              <span style={{ color: `${FARE_COLORS.aqua}` }}>
                {winningsOrLosses} ${formattedDecimalizedDeltaAmount}
              </span>
            </p>
          </ShareWinnngsBlocks>
          <ShareWinnngsBlocks className='fare-site-block'>
            <img src={SVGS.star} alt='star' width={36} />
            <p>FAREPLAY.IO</p>
          </ShareWinnngsBlocks>
        </ShareWinningsCard>

        <ShareWinningsButton
          buttonType={ButtonEnum.CONNECT_WALLET}
          onClick={handleCopyImage}
          disabled={isCopying}
          isLoading={isCopying}
          loadingText='Copying image...'
        >
          <div>
            COPY IMAGE <img src={SVGS.copyIcon} alt='copy-icon' width={20} />
          </div>
        </ShareWinningsButton>
      </ModalWrapper>
    </ModalOverlayContainer>
  )
}
