import React, { useMemo } from 'react'
import { useRouletteGameStore, type RouletteEntry } from '@/store/useRouletteGameStore'
import type { BetPosition } from './types'
import { numberGrid, redNumbers } from './constants'
import {
  BetFormWrapper,
  BottomSection,
  LeftButton,
  MiddleSection,
  NumberButton,
  NumberGrid,
  RightColumn,
  SectionButton,
  TopSection,
} from './styles'
import { BettingOverlay } from './BettingOverlay'
import { styled } from 'styled-components'
import { BREAKPOINTS, TEXT_COLORS } from '@/design'
import { useRouletteSound } from './RouletteSoundInterface'
import { useIsGameAnimating } from '@/hooks/useIsGameAnimating'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'

const convertEntriesToMap = (
  entries: RouletteEntry[]
): Map<string, { position: BetPosition; amount: number }> => {
  const map = new Map()
  entries.forEach(entry => {
    const position: BetPosition = {
      type: entry.type,
      numbers:
        Array.isArray(entry.value) ?
          entry.value.map(v => (typeof v === 'string' ? parseInt(v) : v))
        : [typeof entry.value === 'string' ? parseInt(entry.value) : entry.value],
    }
    const key = `${entry.type}-${Array.isArray(entry.value) ? entry.value.join('-') : entry.value}`
    map.set(key, { position, amount: entry.amount })
  })
  return map
}

const InstructionText = styled.div`
  font-size: 8px;
  color: ${TEXT_COLORS.three};
  opacity: 0.6;
  user-select: none;
  margin-top: 10px;
  margin-inline: 40px;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    color: white;
    font-size: 15px;
    text-align: center;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    font-size: 18px;
    color: white;
    opacity: 1;
    margin-top: 20px;
  }
`
const InstructionWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    padding-bottom: 100px;
    text-wrap: nowrap;
  }
`

interface RouletteBetFormProps {
  disabled?: boolean
}

const numberToBetAmount: { [key: number]: number } = {}
for (let i = 0; i <= 36; i++) {
  numberToBetAmount[i] = 0
}

export const RouletteBetForm: React.FC<RouletteBetFormProps> = ({ disabled = false }) => {
  const { addEntry, selectedChip, currentEntries, removeEntry, gameState } = useRouletteGameStore()
  const { playGameSound } = useRouletteSound()
  const { isDrawerOpen } = useRouletteGameStore()
  const { isGameAnimating } = useIsGameAnimating()
  const isMobileScreen = useIsBreakpoint('sm')

  const isDisabled = disabled || gameState !== 'IDLE' || isGameAnimating

  const placedBetsMap = useMemo(() => convertEntriesToMap(currentEntries), [currentEntries])

  const handleBetPlaced = (position: BetPosition) => {
    if (selectedChip === null || isDisabled) return
    playGameSound('random-coin', 0.4, 1)
    addEntry({
      type: position.type,
      value: position.value || position.numbers,
      amount: selectedChip,
      numbers: position.numbers,
    })
  }

  const handleBetRemoved = (position: BetPosition) => {
    if (isDisabled) return
    const entryIndex = currentEntries.findIndex(entry => {
      if (['redBlack', 'oddEven', 'highLow'].includes(entry.type)) {
        return entry.type === position.type && entry.value === position.value
      }
      const entryValue = Array.isArray(entry.value) ? entry.value : [entry.value]
      const positionValue = position.numbers
      return (
        entry.type === position.type && JSON.stringify(entryValue) === JSON.stringify(positionValue)
      )
    })

    if (entryIndex !== -1) {
      playGameSound('click', 0.3, 0.8)
      removeEntry(entryIndex)
    }
  }

  const handleAllBetsRemoved = () => {
    if (isDisabled) return
    currentEntries.forEach(() => removeEntry(0))
  }

  return (
    <BetFormWrapper $isDisabled={isDisabled} $isOpen={isDrawerOpen}>
      <TopSection>
        {/* <LeftButton onClick={() => handleNumberClick(0)}>0</LeftButton> */}
        <LeftButton disabled={isDisabled}>0</LeftButton>
        <NumberGrid>
          {numberGrid.map((row, rowIndex) => (
            <React.Fragment key={`row-${rowIndex}`}>
              {row.map(num => (
                <NumberButton
                  key={num}
                  $isRed={redNumbers.includes(num)}
                  disabled={isDisabled}
                  onClick={() => {}}
                >
                  {num}
                </NumberButton>
              ))}
            </React.Fragment>
          ))}
          <BettingOverlay
            onBetPlaced={handleBetPlaced}
            placedBets={placedBetsMap}
            hoverAmount={selectedChip ?? 0}
            debug={false}
            onBetRemoved={handleBetRemoved}
            disabled={isDisabled}
          />
        </NumberGrid>
        <RightColumn>
          {[1, 2, 3].map(num => (
            <NumberButton
              key={`2to1-${num}`}
              onClick={() => {}} // @NOTE: Weirdly, this is not getting triggered and `handleBetPlace` gets run
              disabled={isDisabled}
            >
              2:1
            </NumberButton>
          ))}
        </RightColumn>
      </TopSection>

      <MiddleSection>
        <SectionButton disabled={isDisabled}>1 to 12</SectionButton>
        <SectionButton disabled={isDisabled}>13 to 24</SectionButton>
        <SectionButton disabled={isDisabled}>25 to 36</SectionButton>
      </MiddleSection>

      <BottomSection>
        <SectionButton disabled={isDisabled}>1 to 18</SectionButton>
        <SectionButton disabled={isDisabled}>EVEN</SectionButton>
        <SectionButton $isRed disabled={isDisabled}>
          RED
        </SectionButton>
        <SectionButton disabled={isDisabled}>BLACK</SectionButton>
        <SectionButton disabled={isDisabled}>ODD</SectionButton>
        <SectionButton disabled={isDisabled}>19 to 36</SectionButton>
      </BottomSection>

      {isMobileScreen ?
        <InstructionWrapper>
          <InstructionText>TAP BOARD TO PLACE BET</InstructionText>
          <InstructionText onClick={handleAllBetsRemoved}>TAP HERE CLEAR BOARD</InstructionText>
        </InstructionWrapper>
      : <InstructionWrapper>
          <InstructionText>CLICK TO PLACE</InstructionText>
          <InstructionText>SHIFT-CLICK TO REMOVE</InstructionText>
        </InstructionWrapper>
      }
    </BetFormWrapper>
  )
}
