import { AppGameName } from '@/chains/types'
import { SGradientWalletModalContent } from '@/components/Privy/SelectWalletModal'
import { usePathGameName } from '@/hooks/usePathGameName'
import { useActiveWallet } from '@/lib/privy/hooks'

const BannerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  height: 40px;
  width: 80%;
`

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: black;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
`

export const PrivyBanner = () => {
  const { activeWallet, isUsingSmartWallet } = useActiveWallet()
  const gameName = usePathGameName()

  if (gameName === AppGameName.Cards && activeWallet && !isUsingSmartWallet) {
    return (
      <BannerWrapper>
        <SGradientWalletModalContent>
          <BannerContent>Privy wallet needs to be connected to play this game!</BannerContent>
        </SGradientWalletModalContent>
      </BannerWrapper>
    )
  }

  return null
}
