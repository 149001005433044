import { FARE_COLORS } from '@/design'
import { SColoredText, SConnectWalletContent } from './style'

export const ConnectWalletAlert = () => {
  return (
    <SConnectWalletContent>
      To view your{' '}
      <SColoredText
        style={{
          background: `linear-gradient(180deg, ${FARE_COLORS.salmon}, ${FARE_COLORS.pink}, ${FARE_COLORS.blue})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Deathmatch
      </SColoredText>{' '}
      points, see your invite codes, connect your socials or edit your settings, please connect your
      wallet.
    </SConnectWalletContent>
  )
}
