import React from 'react'
import { PNGS, SVGS } from '@/assets'
import { BREAKPOINTS } from '@/design'
import { gameNameToSVGMap, getAppCurrencyDecimals } from '@/chains/lib'
import numeral from 'numeral'
import { AnimatePresence, type Variants } from 'framer-motion'
import { ClickToCopy } from '../ClickToCopy'
import { getUserImageToRender, getUsernameToRender } from '@/utils/userDisplay'
import { fsocket } from '@/lib/fsocket'
import { utils } from 'ethers'
import { SBetLink, SLEData, SLEDataRow, SLEHeaderRow, SLiveEntriesWrapper } from './styles'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { useBlockExplorerUrl } from '@/lib/privy/hooks/useBlockExplorerUrl'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { MobileLeaderboardModal } from '@/components/Modals/MobileLeaderboardModal'

const leRowVariants: Variants = {
  initial: {
    opacity: 0,
    x: 20,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 20,
  },
}

const leDataRowVariants: Variants = {
  initial: {
    opacity: 0,
    y: -30,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    y: -30,
    transition: {
      duration: 0.3,
      ease: 'easeIn',
    },
  },
}

export const RefactorLiveEntries = () => {
  const networkStyle = useNetworkStyle()
  const { liveEntries } = fsocket.liveEntry.useState()
  const blockExplorerUrl = useBlockExplorerUrl()
  const isMobileScreen = useIsBreakpoint('sm')
  const [showModal, setShowModal] = useState(false)

  const handleModalClose = (showModal: boolean) => {
    localStorage.setItem('mobile-leaderboard-modal-dismissed', 'true') // Set the dismiss flag
    setShowModal(showModal)
  }

  useEffect(() => {
    const hasDismissed = localStorage.getItem('mobile-leaderboard-modal-dismissed')
    if (isMobileScreen && hasDismissed !== 'true') {
      setShowModal(true)
    }
  }, [isMobileScreen])

  const memoLiveEntries = useMemo(() => {
    const liveEntryElems: React.ReactNode[] = []

    liveEntries.forEach(liveEntry => {
      if (!liveEntry.deltaAmount) return

      const resultEntryAmount = utils.formatUnits(
        liveEntry.deltaAmount || 0,
        getAppCurrencyDecimals()
      )

      const resultEntryFormatStr = Math.abs(Number(resultEntryAmount)) < 10 ? '0,0.00' : '0,0'

      let resultEntry = numeral(resultEntryAmount).format(resultEntryFormatStr)
      let isWin = false
      if (Number(liveEntry.deltaAmount) > 0) {
        resultEntry = '+' + resultEntry
        isWin = true
      }

      const resultDeltaAmount = Number(
        utils.formatUnits(liveEntry.multiplier || 0, getAppCurrencyDecimals())
      )
      const numeralFormatStr = resultDeltaAmount < 10 ? '0,0.00' : '0,0'
      const entryAmount = numeral(resultDeltaAmount).format(numeralFormatStr)

      const imageToRender = getUserImageToRender(
        liveEntry.fundOwnerAddress,
        liveEntry.fundOwnerIsUsingTwitterToDisplay,
        liveEntry.fundOwnerAvatarSeedAddress,
        liveEntry.fundOwnerTwitterImage,
        {},
        { borderRadius: 100, maxHeight: 20 },
        {
          height: '16px',
          width: '16px',
        },
        {
          height: '16px',
          width: '16px',
        }
      )

      const usernameToRender = getUsernameToRender(
        liveEntry.fundOwnerAddress,
        liveEntry.fundOwnerIsUsingTwitterToDisplay,
        liveEntry.fundOwnerUsername,
        liveEntry.fundOwnerTwitterUsername,
        {
          transition: 'all ease-in-out 0.16',
          // color: '#aaa',
          fontSize: '12px',
          lineHeight: '9px',
          paddingTop: '1px',
        }
      )

      const gameImageToRender = () => {
        if (liveEntry.gameName && (gameNameToSVGMap as any)[liveEntry.gameName as string]) {
          return (
            <img
              src={(gameNameToSVGMap as any)[liveEntry.gameName as string] || SVGS.questionMarkIcon}
              width={16}
            />
          )
        }
        return <img src={SVGS.questionMarkIcon} width={16} />
      }

      liveEntryElems.push(
        <SLEDataRow
          key={liveEntry.reactKey}
          layoutId={liveEntry.resolvementTxHash}
          variants={leDataRowVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <SLEData>
            {imageToRender}
            <ClickToCopy className='mr-left' copyText={liveEntry.fundOwnerAddress}>
              {usernameToRender}
            </ClickToCopy>
          </SLEData>
          <SLEData>
            <img src={networkStyle.currencyIcon} alt={networkStyle.currencyName} width={16} />
            <span className='mr-left'>{entryAmount}</span>
          </SLEData>
          <SLEData className='le-center'>
            <span className={isWin ? 'le-win' : 'le-loss'}>{resultEntry}</span>
          </SLEData>
          {/* TODO: With Q and K we cant just identify games from events */}
          <SLEData className='le-center'>{gameImageToRender()}</SLEData>
          <SLEData className='le-center'>
            <SBetLink
              href={`${blockExplorerUrl}/tx/${liveEntry.resolvementTxHash}`}
              target='_blank'
            >
              <img src={PNGS.linkIcon} alt='tx-link' />
            </SBetLink>
          </SLEData>
        </SLEDataRow>
      )
    })

    return liveEntryElems
  }, [liveEntries, networkStyle.currencyName, blockExplorerUrl])

  return (
    <AnimatePresence mode='popLayout' initial={false}>
      <SLiveEntriesWrapper layoutScroll>
        <SLEHeaderRow
          key={'header'}
          variants={window.innerWidth >= BREAKPOINTS.md ? leRowVariants : undefined}
          layout
          layoutId={'header'}
        >
          <div>USER</div>
          <div>ENTRY</div>
          <div className='le-center'>RESULT</div>
          <div className='le-center'>GAME</div>
          <div className='le-center'>TX</div>
        </SLEHeaderRow>
        {memoLiveEntries}
        <MobileLeaderboardModal isVisible={showModal} setIsVisible={handleModalClose} />
      </SLiveEntriesWrapper>
    </AnimatePresence>
  )
}
