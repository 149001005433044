import ModalCard from '@/components/shared/Modal/Card'
import { SelectedCardPack } from '@/components/Forms/Cards'
import { packIdToPack } from '@/components/Games/Cards/CardsData'
import { packIndexToPackQKForACardDraw } from '@/lib/crypto/cards'
import { formatEther } from 'viem'
import { TEXT_COLORS } from '@/design'
import { Overlay } from '../styles'
import { CloseButtonIcon } from '@/components/shared/CloseButton'

export interface ICardsDetailModalProps {
  selectedPackId: number
  onClose: () => void
}

const CardsModalCard = styled(ModalCard)`
  height: 80svh;
  width: 90svw;
  text-align: center;
`
export const ContentWrapper = styled.div`
 margin: 0 auto;
 height 90%;
 width: 90%;
 max-height: 350px;
 overflow: scroll;
`
export const FooterWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  p {
    font-size: 10px;
    color: ${TEXT_COLORS.two};
  }
`

export const CardsDetailModal = ({ selectedPackId, onClose }: ICardsDetailModalProps) => {
  return (
    <Overlay>
      <CardsModalCard isVisible={true} onClose={onClose} title='Card Pack'>
        <CloseButtonIcon onClick={onClose} />
        <ContentWrapper>
          <SelectedCardPack selectedPackId={selectedPackId} />
        </ContentWrapper>
        <FooterWrapper>
          Cost: ${packIdToPack[selectedPackId]?.minCost}
          <p>
            Odds:{' '}
            {packIndexToPackQKForACardDraw[selectedPackId].q.map((prob, i) => {
              const formattedProb = (parseFloat(formatEther(prob)) * 100).toFixed(2) + '%'
              if (i + 1 !== packIndexToPackQKForACardDraw[selectedPackId].q.length) {
                return formattedProb + ', '
              }
              return formattedProb
            })}
          </p>
        </FooterWrapper>
      </CardsModalCard>
    </Overlay>
  )
}
