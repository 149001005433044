import {
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormTab,
  FormWrapper,
  SFormSection,
} from '../style'
import React, { useMemo, useState } from 'react'
import { styled } from 'styled-components'
import { BORDER_COLORS } from '@/design'
import numeral from 'numeral'
import { StyledPackButton, StyledCard } from './styles'
import { useGameSoundManager } from '@/components/Canvases/CardsCanvas/CardsAudio'
import { useForm, Controller } from 'react-hook-form'
import CountUp from 'react-countup'
import { useBalances } from '@/hooks/useBalances'
import { useBufferZone } from '@/hooks/useBufferZone'
import { GameButton } from '@/components/shared/Button/GameButton'
import { type CardPackSelection } from '@/lib/crypto/cards'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { cardIdToCards, packIdToPack } from '@/components/Games/Cards/CardsData'
import { FormLayout } from '../FormComponents/shared/FormLayout'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { deviceBP } from '@/design/breakpoints'
import { CardsDetailModal } from '@/components/Modals/CardsDetailModal'
import { FareNumberInput } from '@/components/shared/Input/FareNumberInput'

const SFormTab = styled(FormTab)`
  gap: 2px;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  border-bottom: 0px solid ${BORDER_COLORS.one};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${deviceBP.sm} {
    padding: 2px 12px;
  )}
`

const DescriptionText = styled.span`
  font-style: italic;
  text-align: center;
  display: block; // To make the span behave like a block for centering
  margin: 20px 20px; // Adding top and bottom margins
  min-height: 150px; // To make sure the text is centered vertically
`

const PackDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`

const CardDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 80px);
  gap: 15px;
  margin-left: 25px;
  margin-block: 20px;

  @media ${deviceBP.sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    gap: 10px;
  }
`

export interface CardsFormData {
  side: number // @NOTE: This is same as 'selectedPackId' just using 'side' property to be compatible with other games
  selectedPackId: number
  entryAmount: number
}

export const SelectedCardPack: React.FC<{
  selectedPackId: number
}> = memo(
  function SelectedCardPack({ selectedPackId }) {
    const cardsInSelectedPack = useMemo(
      () => cardIdToCards.filter(c => c.packId === selectedPackId),
      [selectedPackId]
    )

    return (
      <>
        <CardDiv>
          {cardsInSelectedPack.map(c => (
            <StyledCard
              key={c.id}
              $iconSrc={c.viewInfo.icon.src}
              $isActive={false}
              $cardDescription={c.viewInfo.description}
              $cardValue={c.viewInfo.value}
              $cardTier={c.viewInfo.tier}
              onClick={() => {}}
            >
              <span>{c.viewInfo.name}</span>
            </StyledCard>
          ))}
        </CardDiv>

        {/* <DescriptionText>{packIdToPack[selectedPackId].viewInfo?.description}</DescriptionText> */}
      </>
    )
  },
  (prevProps, nextProps) => prevProps.selectedPackId === nextProps.selectedPackId
)

export const CardsForm: React.FC = () => {
  const networkStyle = useNetworkStyle()
  const balances = useBalances()
  const balanceNumber = Number(balances.currency)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activePackId, setActivePackId] = useState<number | null>(null)
  const isMobileScreen = useIsBreakpoint('sm')

  // const { gameStateType } = useCardsGameState(
  //   useShallow(state => ({
  //     selectedSide: state.entry.side,
  //     setSelectedSide: (side: number) => state.setEntry({ side }),
  //     results: state.results,
  //     side: state.submittedEntry ? state.submittedEntry.side : state.entry.side,
  //     gameStateType: state.type,
  //     send: state.send,
  //   }))
  // )

  const { control, watch, setValue } = useForm<CardsFormData>({
    defaultValues: {
      side: 0,
      entryAmount: packIdToPack[0].minCost,
      selectedPackId: 0,
    },
  })
  const { playGameSound } = useGameSoundManager()

  const formData = watch()
  const { selectedPackId } = formData
  const { bufferedZone: bufferedMaxEntryAmount } = useBufferZone(formData.side)

  const entryAmountNum = numeral(formData.entryAmount).value() || 0

  const packsMinCost = useMemo(() => packIdToPack[selectedPackId].minCost, [selectedPackId])
  const cardsInSelectedPack = useMemo(
    () => cardIdToCards.filter(c => c.packId === selectedPackId),
    [selectedPackId]
  )

  const updateSelectedPackId = (packId: CardPackSelection) => {
    setValue('side', packId)
    setValue('selectedPackId', packId)
    const soundString = packIdToPack[packId].viewInfo?.audioClip
    playGameSound(soundString, 0.5, 0.8)
    const newPacksMinCost = packIdToPack[packId].minCost
    setValue('entryAmount', newPacksMinCost)
  }

  const handlePackClick = (packId: number) => {
    try {
      updateSelectedPackId(packId)
      setActivePackId(packId)
      setIsModalOpen(true)
    } catch (error) {
      console.error('Error handling pack click:', error)
    }
  }

  const handleCloseModal = () => {
    try {
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error closing modal:', error)
    }
  }

  return (
    <FormWrapper>
      <FormLayout>
        <SFormSection>
          <FormLabelRow>
            <FormLabel>
              <FormIndicator $isActive={entryAmountNum > 0} />
              <FormAmountLabel>Select Pack</FormAmountLabel>
            </FormLabel>
          </FormLabelRow>
          <SFormTab $tabs={3}>
            {packIdToPack.map((pack, index) => (
              <StyledPackButton
                key={index}
                $iconSrc={pack.viewInfo?.icon}
                $isActive={selectedPackId === index}
                onClick={() => handlePackClick(index)}
                packCost={pack.minCost}
              >
                <span>{pack.viewInfo?.name}</span>
              </StyledPackButton>
            ))}
          </SFormTab>

          {!isMobileScreen && (
            <SFormTab
              $tabs={3}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10%',
              }}
            >
              {packIdToPack.map((pack, index) => (
                <PackDiv key={index}>${pack.minCost}</PackDiv>
              ))}
            </SFormTab>
          )}
        </SFormSection>

        <SFormSection>
          <FormLabelRow>
            <FormLabel>
              <FormIndicator $isActive={entryAmountNum > 0} />
              <FormAmountLabel>Pack Cost</FormAmountLabel>
            </FormLabel>
            <FormLabel>
              {balanceNumber > 0 && (
                <FormAmountLabel>
                  <CountUp
                    end={balanceNumber}
                    decimals={2}
                    duration={2}
                    separator={','}
                    preserveValue
                  />
                </FormAmountLabel>
              )}
            </FormLabel>
          </FormLabelRow>
          <Controller
            name='entryAmount'
            control={control}
            rules={{
              required: true,
              max: bufferedMaxEntryAmount < packsMinCost ? packsMinCost : bufferedMaxEntryAmount,
              min: packsMinCost,
            }}
            defaultValue={packsMinCost}
            render={({ field }) => {
              const {
                // ref,
                ...props
              } = field
              const value = numeral(field.value).value() || 0
              const isDisabled = (numeral(balances.currency).value() || 0) === 0
              return (
                <FareNumberInput
                  {...props}
                  isTextEditDisabled={true}
                  onChange={event => {
                    field.onChange(numeral(event.target.value).value() as any)
                  }}
                  allowLeadingZeros={false}
                  allowNegative={false}
                  thousandSeparator=','
                  decimalScale={2}
                  hasInputSlider={false}
                  disabled={isDisabled}
                  inputSuffix={
                    <img
                      src={networkStyle.currencyIcon}
                      alt={networkStyle.currencyName}
                      width={20}
                    />
                  }
                  inputSliderProps={{
                    value,
                    onChange: sliderValue => {
                      field.onChange(sliderValue)
                    },
                    min: packsMinCost,
                    max:
                      bufferedMaxEntryAmount < packsMinCost ? packsMinCost : bufferedMaxEntryAmount,
                    step: packsMinCost,
                  }}
                />
              )
            }}
          />
        </SFormSection>
        {!isMobileScreen && <SelectedCardPack selectedPackId={selectedPackId} />}
      </FormLayout>
      <FormFooter>
        <GameButton
          formData={{ ...formData, side: formData.selectedPackId, numberOfEntries: 1 }}
          entryAmountNum={entryAmountNum}
        />
      </FormFooter>

      {isMobileScreen && isModalOpen && activePackId !== null && (
        <CardsDetailModal selectedPackId={activePackId} onClose={handleCloseModal} />
      )}
    </FormWrapper>
  )
}
