import * as THREE from 'three'
import { useRef, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'

interface ConfettiEffectProps {
  trigger: boolean
  onComplete?: () => void
}

export function ConfettiEffect({ trigger, onComplete }: ConfettiEffectProps) {
  const particleCount = 100
  const particlesRef = useRef<THREE.Points | null>(null)
  const positions = useRef<Float32Array>(new Float32Array(particleCount * 3))
  const velocities = useRef<Float32Array>(new Float32Array(particleCount * 3))
  const colors = useRef<Float32Array>(new Float32Array(particleCount * 3))
  const frameCounter = useRef(0)
  const animationDuration = 250 // Duration in frames

  useEffect(() => {
    if (trigger) {
      for (let i = 0; i < particleCount; i++) {
        const idx = i * 3

        // Random starting positions
        positions.current[idx] = (Math.random() - 0.5) * 0.5 // x
        positions.current[idx + 1] = (Math.random() - 0.5) * 0.5 + 1 // y
        positions.current[idx + 2] = (Math.random() - 0.5) * 0.5 // z

        // Random velocities for dispersal
        velocities.current[idx] = (Math.random() - 0.5) * 0.02 // vx
        velocities.current[idx + 1] = Math.random() * 0.05 + 0.01 // vy (upward)
        velocities.current[idx + 2] = (Math.random() - 0.5) * 0.02 // vz

        // Random colors
        colors.current[idx] = Math.random() // R
        colors.current[idx + 1] = Math.random() // G
        colors.current[idx + 2] = Math.random() // B
      }
      frameCounter.current = 0 // Reset frame counter for each trigger
    }
  }, [trigger])

  useFrame(() => {
    if (!trigger || !particlesRef.current) return

    const positionsAttr = particlesRef.current.geometry.attributes.position as THREE.BufferAttribute
    const colorsAttr = particlesRef.current.geometry.attributes.color as THREE.BufferAttribute

    for (let i = 0; i < particleCount; i++) {
      const idx = i * 3
      positions.current[idx] += velocities.current[idx] // Update x
      positions.current[idx + 1] += velocities.current[idx + 1] // Update y
      positions.current[idx + 2] += velocities.current[idx + 2] // Update z

      // Slowly increase x and z velocities for dispersion, apply gradual gravity on y
      velocities.current[idx] *= 1.02 // Gradual x dispersion
      velocities.current[idx + 2] *= 1.02 // Gradual z dispersion
      velocities.current[idx + 1] -= 0.001 // Gravity effect on y

      // Fade out colors over time
      // const fadeFactor = 1 - frameCounter.current / animationDuration;
      // colorsAttr.array[idx] = colors.current[idx] * fadeFactor;
      // colorsAttr.array[idx + 1] = colors.current[idx + 1] * fadeFactor;
      // colorsAttr.array[idx + 2] = colors.current[idx + 2] * fadeFactor;

      positionsAttr.array[idx] = positions.current[idx]
      positionsAttr.array[idx + 1] = positions.current[idx + 1]
      positionsAttr.array[idx + 2] = positions.current[idx + 2]
    }

    positionsAttr.needsUpdate = true
    colorsAttr.needsUpdate = true

    frameCounter.current += 1
    if (frameCounter.current >= animationDuration) {
      frameCounter.current = 0
      if (onComplete) onComplete()
    }
  })

  return (
    <points ref={particlesRef}>
      <bufferGeometry attach='geometry'>
        <bufferAttribute
          attach='attributes-position'
          array={positions.current}
          itemSize={3}
          count={particleCount}
        />
        <bufferAttribute
          attach='attributes-color'
          array={colors.current}
          itemSize={3}
          count={particleCount}
        />
      </bufferGeometry>
      <pointsMaterial size={0.25} vertexColors transparent />
    </points>
  )
}
