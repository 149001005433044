import { TypedListener } from '@/lib/crypto/typechain/common'
import { ApprovalEvent } from '@/lib/crypto/typechain/USDCMock'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'
import { useCurrency } from '@/hooks/useCurrency'
import { useActiveWallet } from '@/lib/privy/hooks'
import useCurrencyStore from '@/store/useCurrencyStore'
import { usePrivy } from '@privy-io/react-auth'

export const useAllowanceListner = () => {
  const { appAddresses, appContracts, appProvider } = useAppChainConfig()
  const { fetchAndSetAllowance } = useCurrency()
  const { walletAddress, walletChainId, isWalletAuthed } = useActiveWallet()
  const { ready, authenticated } = usePrivy()
  const setApproveAllowanceState = useCurrencyStore.use.setApproveAllowanceState()

  useEffect(() => {
    if (!appContracts || !walletAddress || !isWalletAuthed || !ready || !authenticated) return
    setApproveAllowanceState('pending')

    console.log('initial:fetchAndSetAllowance')
    fetchAndSetAllowance(walletAddress)
      .then(() => {
        setApproveAllowanceState('approved')
      })
      .catch(error => {
        setApproveAllowanceState('not-approved')
        console.error(error)
      })

    const approvalFromFilter = appContracts.ws.currency.filters.Approval(
      walletAddress,
      appAddresses.bankroll,
      null
    )
    const approvalFromListener: TypedListener<ApprovalEvent> = (_owner, _spender, _value) => {
      if (!walletAddress) return

      console.log('listener:fetchAndSetAllowance')
      fetchAndSetAllowance(walletAddress)
    }
    appContracts.ws.currency.on(approvalFromFilter, approvalFromListener)

    return () => {
      appContracts.ws.currency.removeListener(approvalFromFilter, approvalFromListener)
    }
  }, [
    walletAddress,
    appContracts,
    appProvider,
    appAddresses,
    walletChainId,
    ready,
    isWalletAuthed,
    authenticated,
  ])
}
