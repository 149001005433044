import { BORDER_COLORS, BREAKPOINTS, TEXT_COLORS } from '@/design'
import { useRouletteGameStore } from '@/store/useRouletteGameStore'
import { RouletteBetForm } from './RouletteBetForm'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'

const DrawerToggle = styled.button<{ $isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 4px 4px 0 0;
  padding: 2px 16px;
  color: ${TEXT_COLORS.two};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  backdrop-filter: blur(5px);
  font-size: 8px;

  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  @media (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    display: ${props => (props.$isOpen ? 'flex' : 'none')};
    font-size: 20px;
    color: white;
  }
`

const SRouletteCanvasContent = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => (props.$isOpen ? 'rgba(0, 0, 0, 0.8)' : 'transparent')};
  backdrop-filter: ${props => (props.$isOpen ? 'blur(10px)' : 'none')};
  border-top: ${props => (props.$isOpen ? `1px solid ${BORDER_COLORS.one}` : 'none')};
  z-index: 10;
  transform: translateY(${props => (props.$isOpen ? '0' : '100%')});
  transition: transform 0.3s ease-in-out;
  padding: 16px;
  z-index: ${props => (props.$isOpen ? '10000' : '0')};
  user-select: none;

  @media (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    top: 5%;
    height: 100%;
    margin-block: 16px;
    background: rgba(0, 0, 0, 0.8);
    padding-bottom: 20%;
  }
`

export default function RouletteDrawer() {
  const { isDrawerOpen, setDrawerOpen } = useRouletteGameStore()
  const isMobileScreen = useIsBreakpoint('xs')

  return (
    <SRouletteCanvasContent $isOpen={isDrawerOpen}>
      {!isMobileScreen && (
        <DrawerToggle onClick={() => setDrawerOpen(!isDrawerOpen)} $isOpen={isDrawerOpen}>
          <span style={{ fontSize: '12px', color: TEXT_COLORS.three }}>
            {isDrawerOpen ? '▼' : '▲'}
          </span>
          {isDrawerOpen ? 'Hide' : 'Show'}
          <span style={{ fontSize: '12px', color: TEXT_COLORS.three }}>
            {isDrawerOpen ? '▼' : '▲'}
          </span>
        </DrawerToggle>
      )}
      <RouletteBetForm />
    </SRouletteCanvasContent>
  )
}
