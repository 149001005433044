import { motion, type Variants } from 'framer-motion'

export const NotiPillVariant: Variants = {
  initial: {
    opacity: 0,
    y: -70,
    transition: {
      type: 'tween',
      ease: 'linear',
      duration: 0.24,
    },
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.5,
    },
  },
  exit: {
    opacity: 0,
    y: -70,
    transition: {
      type: 'tween',
      ease: 'linear',
      duration: 0.24,
    },
  },
}

export const defaultTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.24,
}

export const SNotiPill = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 32px;
  padding-inline: 18px;
  cursor: pointer;
  text-wrap: nowrap;
`
