import { useEffect, useCallback } from 'react'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'

// Import sound files
import winSound from '@/assets/audio/bombs-win.wav'
import closePackSound from '@/assets/audio/Device 7 Stop.wav'
import shuffleSounds_1 from '@/assets/audio/cards/cardsShuffle_1.wav'
import shuffleSounds_2 from '@/assets/audio/cards/cardsShuffle_2.wav'
import shuffleSounds_3 from '@/assets/audio/cards/cardsShuffle_3.wav'
import shuffleSounds_4 from '@/assets/audio/cards/cardsShuffle_4.wav'
import shuffleSounds_5 from '@/assets/audio/cards/cardsShuffle_5.wav'
import onHover from '@/assets/audio/mouse-over-slider.wav'

import explorerPack from '@/assets/audio/ExplorerPack.wav'
import challengerPack from '@/assets/audio/ChallengerPack.wav'
import cryptonaughtPack from '@/assets/audio/CryptonaughtPack.wav'

// Define an object to hold the sound files
const GAME_SOUNDS = {
  win: winSound,
  closePack: closePackSound,
  shuffle_1: shuffleSounds_1,
  shuffle_2: shuffleSounds_2,
  shuffle_3: shuffleSounds_3,
  shuffle_4: shuffleSounds_4,
  shuffle_5: shuffleSounds_5,
  explorerPack: explorerPack,
  challengerPack: challengerPack,
  cryptonaughtPack: cryptonaughtPack,
  onHover: onHover,
}

export function useGameSoundManager() {
  const soundContext = useSound()
  // Load sounds when component mounts, and clean up on unmount
  useEffect(() => {
    if (soundContext && typeof soundContext.loadSound === 'function') {
      Object.entries(GAME_SOUNDS).forEach(([key, path]) => {
        soundContext.loadSound(key, path)
      })
    } else {
      console.warn('DEBUG Cannot load sounds: Sound context not available in GameSoundManager')
    }

    // Unload sounds when the component unmounts
    return () => {
      if (soundContext && typeof soundContext.unloadSound === 'function') {
        Object.keys(GAME_SOUNDS).forEach(key => {
          soundContext.unloadSound(key)
        })
      }
    }
  }, [soundContext])

  // Define the playGameSound function
  const playGameSound = useCallback(
    (soundName: string, volume = 1, pitch = 1) => {
      if (soundContext && typeof soundContext.playSound === 'function') {
        soundContext.playSound(soundName, volume, pitch)
      } else {
        console.warn('DEBUG Cannot play sound: Sound context not available in GameSoundManager')
      }
    },
    [soundContext]
  )

  return { playGameSound }
}
