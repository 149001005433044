import { BACKGROUND_COLORS, BORDER_COLORS, COLORS, FARE_COLORS, TEXT_COLORS } from '@/design'
import { ClickToCopy } from '../../shared/ClickToCopy'
import { motion, type Variants } from 'framer-motion'
import { deviceBP } from '@/design/breakpoints'

export const dropdownVariant: Variants = {
  initial: {
    y: 20,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 20,
    opacity: 0,
  },
}

export const mobileDropdownVariant: Variants = {
  initial: {
    y: '110%',
    opacity: 1,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      damping: 25,
      type: 'spring',
    },
  },
  exit: {
    y: '110%',
    opacity: 0.8,
    transition: {
      damping: 25,
      type: 'spring',
    },
  },
}

export const SDropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
`

export const SClickToCopy = styled(ClickToCopy)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  small {
    top: -14px;
    left: 0px;
    align-self: center;
    justify-content: center;
  }
  &.leaderboard {
    width: auto;
  }
`

export const SAddressAvatarWrapper = styled.div<{ $userColor?: string; $isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 42px;
  width: auto;
  padding-left: 8px;
  padding-right: 12px;
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.two};
  border-radius: 6px;
  transition: all ease-in-out 0.08s;
  position: relative;

  &:hover {
    border-color: ${props => props.$userColor};
  }
  .avatar-wrapper {
    margin-right: 8px;
  }

  ${props =>
    props.$isOpen &&
    css`
      background: #101010;
      color: ${TEXT_COLORS.one};
      border-color: ${props.$userColor};
    `}

  > span {
    line-height: 1px;
    padding-top: 3px;
    user-select: none;
    justify-self: flex-start;
  }

  @media (max-width: 760px) {
    border: none;
    padding-right: ${({ $isOpen }) => ($isOpen ? '12px' : '0px')};

    ${props =>
      props.$isOpen &&
      css`
        background: transparent;
        color: ${TEXT_COLORS.one};
        border: 1px solid ${props.$userColor};
      `}

    .avatar-wrapper {
      margin-right: 0px;
    }
  }
`

export const SDropdownContent = styled(motion.div)<{ $userColor?: string }>`
  position: absolute;
  top: 48px;
  right: 0px;
  width: 131px;
  border: 1px solid ${BORDER_COLORS.one};
  z-index: 10;
  border-radius: 6px;
  background: ${BACKGROUND_COLORS.one};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 10px;

  @media ${deviceBP.xs} {
    left: -6px;
    top: 56px;
    border: 1px solid ${BORDER_COLORS.one};
    width: 99svw;
    height: 100vh;
    padding: 0px;
    border-radius: 6px 6px 0 0;
    margin: auto;
  }
`

export const SDropdownItem = styled.div<{ $userColor?: string }>`
  position: relative;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  padding-left: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  transition: all ease-in-out 0.12s;
  height: 20px;
  display: flex;
  align-items: center;

  &.mobile-faucet {
    margin: 0px auto 16px;
    width: 85%;
    border-radius: 6px;
    border: 1px solid ${FARE_COLORS.pink};
    box-shadow: 0px 0px 3px ${FARE_COLORS.pink};

    > span {
      display: flex;
      height: 42px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: ${TEXT_COLORS.one};
    }
  }

  > span {
    transition: all ease-in-out 0.12s;
    font-size: 11px;
    line-height: 12px;
    color: ${TEXT_COLORS.two};
  }

  > .hover-bar {
    transition: all ease-in-out 0.12s;
    position: absolute;
    right: 6px;
    top: 0px;
    height: 20px;
    width: 1px;

    box-shadow: inset 0px 0px 10px transparent;
    border: 1px solid transparent;
    background: rgba(255, 255, 255, 0);
  }
  &:hover {
    > span {
      color: ${TEXT_COLORS.one};
    }
    > .hover-bar {
      box-shadow: inset 0px 0px 10px ${props => props.$userColor}99;
      border: 1px solid ${props => props.$userColor};
    }
  }

  @media ${deviceBP.sm} {
    margin: auto;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid ${COLORS.error};
    box-shadow: 0px 0px 3px ${COLORS.error};
    background: transparent;
    width: 150px;
    padding: 0px 16px;
    height: 42px;

    > span {
      font-size: 14px;
      color: ${TEXT_COLORS.one};
    }
  }
`

export const MenuLinks = styled.a`
  transition: all ease-in-out 0.12s;
  font-size: 11px;
  line-height: 12px;
  color: ${TEXT_COLORS.two};
  text-decoration: none;
`

export const ProfileInfo = styled.div`
  display: flex;
  margin: 0px 16px 10px 16px;
  padding: 12px;
  text-wrap: pretty;
  line-height: 1.25rem;
  color: ${TEXT_COLORS.two};
`

export const ButtonWrapper = styled.div`
  display: inline-flex;
  width: 90%;
  margin: 0px auto;

  span {
    font-size: 14px;
  }
`
