import { createPortal } from 'react-dom'
import { SVGS } from '@/assets'
import { ShareWinningsModal } from '@/components/Modals/ShareWinningsModal'
import { useShareWinningsModal } from '@/hooks/useShareWinningsModal'
import { BORDER_COLORS, BREAKPOINTS, FARE_COLORS } from '@/design'

const pulseGlow = keyframes`
  0%, 100% {
    transform: scale(0.95);
  }
  50% {
    box-shadow: 0 0 10px ${BORDER_COLORS.three};
    transform: scale(1);
  }
`

const spin = keyframes`
  to {
    --bg-angle: 360deg;
  }
`

const quickplayBackgroundGradient = css`
  background:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.86)) padding-box,
    conic-gradient(
        from var(--bg-angle) at 50% 50%,
        ${FARE_COLORS.aqua} 0%,
        ${FARE_COLORS.peach} 25%,
        ${FARE_COLORS.pink} 50%,
        ${FARE_COLORS.peach} 75%,
        ${FARE_COLORS.aqua} 100%
      )
      border-box;
`

const ShareWinningsIcon = styled.button`
  --bg-angle: 0deg;

  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  background:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.86)) padding-box,
    conic-gradient(
        from var(--bg-angle) at 50% 50%,
        #410dff 0%,
        #ff5e4f 25%,
        #d900d5 50%,
        #ff5e4f 75%,
        #410dff 100%
      )
      border-box;
  ${quickplayBackgroundGradient}
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 0 15px;
  cursor: pointer;
  touch-action: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 5px;
  animation:
    ${pulseGlow} 2.5s infinite,
    ${spin} 7s infinite linear;
  transition: all 0.5s ease;

  &:hover {
    animation:
      ${pulseGlow} 1.5s infinite paused,
      ${spin} 1s infinite linear;
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    top: 10px;
    height: 30px;
  }
`

export const ShareWinningsIconButton = () => {
  const { isModalOpen, handleShareWinningsClick, handleCloseModal } = useShareWinningsModal()

  return (
    <>
      <ShareWinningsIcon onClick={handleShareWinningsClick}>
        <p>FLEX</p>
        <img src={SVGS.usdcWhiteIcon} alt='Copy to clipboard' width={24} />
      </ShareWinningsIcon>
      {isModalOpen &&
        createPortal(<ShareWinningsModal onClose={handleCloseModal} />, document.body)}
    </>
  )
}
