import numeral from 'numeral'

import { SVGS } from '@/assets'
import { Button, ButtonEnum } from '@/components/shared/Button'
import { BORDER_COLORS, FARE_COLORS, FONT_STYLES, SPACING, TEXT_COLORS } from '@/design'
import { deviceBP } from '@/design/breakpoints'
import { useActiveWallet } from '@/lib/privy/hooks'
import { addAppNoti } from '@/store/useNotiStore'
import { noUserSelect } from '@/style'
import useCurrencyStore from '../../../store/useCurrencyStore'

const DepositBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1c1c1e;
  ${noUserSelect}

  @media ${deviceBP.sm} {
    width: 100%;
  }

  .balance-received {
    color: ${FARE_COLORS.aqua};
  }

  .balance-display {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 12px;
  }
`

const DepositTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: white;
  line-height: 1.25;
  font-family: system-ui, sans-serif;
  margin-bottom: ${SPACING.md}px;

  @media ${deviceBP.sm} {
    font-size: 18px;
    margin-inline: 12px;
  }
`
const BubbleWrapper = styled.div`
  display: inline-flex;
  gap: ${SPACING.xs}px;
  background-color: rgba(118, 118, 128, 0.18);
  padding: ${SPACING.xs}px ${SPACING.sm}px;
  border-radius: 10rem;
  width: fit-content;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_STYLES.sm};

  &.arbitrumBubble,
  &.usdcBubble {
    padding: 4px 8px;
    gap: 4px;
    margin: 2px 0 0 8px;
  }
  &.arbitrumBubble {
    @media ${deviceBP.sm} {
      margin-left: 0;
    }
  }
`

const DepositContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 16px 16px 0;
  font-size: 16px;
  gap: ${SPACING.xs}px;
  color: ${TEXT_COLORS.two};
  line-height: 1.25;

  p {
    margin: 8px 0;
    font-family: system-ui, sans-serif;
  }

  @media ${deviceBP.sm} {
    font-size: ${FONT_STYLES.sm};
    margin-inline: 12px;
    word-wrap: break-word;
  }
`

const CopyInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 12px;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

const InputAddress = styled.input`
  background: #2c2c2e;
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  border-radius: 12px;
  padding: 8px 16px;
  font-size: 14px;
  flex: 1;
  height: 40px;
  font-family: system-ui, sans-serif;

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &:focus {
    outline: none;
    border: 1px solid ${FARE_COLORS.aqua};
  }

  @media (min-width: 768px) {
    width: auto;
  }
`

export const TransferModalFunds = () => {
  const { walletAddress, appChainConfig } = useActiveWallet()
  const { networkStyle } = appChainConfig
  const selectedCurrencyBalance = useCurrencyStore(state => state.balances.currency)
  // const prevSelectedCurrencyBalance = usePrevious(selectedCurrencyBalance)
  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(walletAddress)
      addAppNoti({
        type: 'success',
        msg: 'Copied to clipboard',
      })
    } catch (err) {
      addAppNoti({
        type: 'error',
        msg: 'Failed to copy',
      })
    }
  }, [walletAddress])
  const [prevBalance, setPrevBalance] = useState(selectedCurrencyBalance)
  const [balanceDifference, setBalanceDifference] = useState('0.00')

  useEffect(() => {
    if (selectedCurrencyBalance !== prevBalance) {
      setBalanceDifference(
        numeral(selectedCurrencyBalance)
          .subtract(prevBalance || '0')
          .format('0,0.00')
      )
      setPrevBalance(selectedCurrencyBalance)
    }
  }, [selectedCurrencyBalance, prevBalance])

  return (
    <DepositBoxWrapper>
      <DepositTitle>Deposit {networkStyle.currencyName} (Arbitrum)</DepositTitle>
      <BubbleWrapper>
        <img src={SVGS.coinbaseCoinLogo} width={18} alt='coinbase logo' />
        <img src={SVGS.binanceCircleIcon} width={18} alt='binance logo' />
        <img src={SVGS.krakenIcon} width={18} alt='kraken logo' />
      </BubbleWrapper>
      <DepositContent>
        <p>
          Buy USDC on Coinbase, Binance, or another exchange.
          <BubbleWrapper className='usdcBubble'>
            <img src={SVGS.usdcIcon} width={14} alt='usdc logo' /> USDC
          </BubbleWrapper>
        </p>
        <p>
          Send/Withdraw USDC to the address below and select ARBITRUM as the network.
          <BubbleWrapper className='arbitrumBubble'>
            <img src={networkStyle.networkLogo} width={14} alt='network logo' />
            ARBITRUM
          </BubbleWrapper>
        </p>
      </DepositContent>
      <div className='balance-received'>
        {balanceDifference !== '0.00' &&
          `Received: ${balanceDifference} ${networkStyle.currencyName}`}
      </div>

      <CopyInputWrapper>
        <InputAddress value={walletAddress} disabled />
        <Button
          type='button'
          buttonType={ButtonEnum.BASE}
          onClick={copyToClipboard}
          disabled={false}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
        >
          <img src={SVGS.copyIcon} alt='Copy to clipboard' width={40} />
        </Button>
      </CopyInputWrapper>
      <div className='balance-display'>
        {networkStyle.currencyName} balance: {numeral(selectedCurrencyBalance).format('0,0.00')}
      </div>
    </DepositBoxWrapper>
  )
}
