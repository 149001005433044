import { SUserDashboard } from './styles'
import { UserTxHistory } from './UserTxHistory'
import { UserGameStats } from './UserGameStats'
import { SettingsTitle } from '@/pages/SettingsPage/style'
// import { UserCards } from './UserCards'

export const UserDashboard = ({ walletAddress }: { walletAddress: string | undefined }) => (
  <SUserDashboard>
    {walletAddress && (
      <>
        <SettingsTitle>Game Stats</SettingsTitle>
        <UserGameStats walletAddress={walletAddress} />
      </>
    )}
    {walletAddress && (
      <>
        <SettingsTitle>Transaction history</SettingsTitle>
        <UserTxHistory walletAddress={walletAddress} />
      </>
    )}
    {/* {walletAddress && (
      <>
        <SettingsTitle>Collected Cards</SettingsTitle>
        <UserCards walletAddress={walletAddress} />
      </>
    )} */}
  </SUserDashboard>
)
