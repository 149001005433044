import { SFormSection } from '@/components/Forms/style'
import {
  BORDER_COLORS,
  BREAKPOINTS,
  CHIP_COLORS,
  FARE_COLORS,
  OUTCOME_COLORS,
  TEXT_COLORS,
} from '@/design'
import { motion } from 'framer-motion'
import { styled } from 'styled-components'

export const BetFormWrapper = styled.div<{ $isDisabled: boolean; $isOpen: boolean }>`
  display: ${props => (props.$isOpen ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 656px;
  margin: 0 auto;
  opacity: ${props => (props.$isDisabled ? 0.8 : 1)};
  pointer-events: ${props => (props.$isDisabled ? 'none' : 'auto')};
  transition: opacity 0.2s ease;

  @media (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    max-width: 100%;
    overflow: scroll;
  }
`

export const TopSection = styled.div`
  display: flex;
  height: 70%;

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    align-items: center;
    justify-content: center;
    width: 99%;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) and (orientation: landscape) {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }
`

export const LeftButton = styled.button`
  width: calc(100% / 14);
  aspect-ratio: 1/3;
  background: #4caf4f92;
  border: 1px solid #4caf50;
  border-radius: 4px;
  color: white;
`

export const NumberGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 0px;
  flex: 1;
  position: relative;
`

export const RightColumn = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  width: calc(100% / 14);
`

export const NumberButton = styled.button<{ $isRed?: boolean }>`
  width: 100%;
  aspect-ratio: 1;
  background: ${props => (props.$isRed ? '#ff1a1aa4' : '#1a1a1aa7')};
  border: 1px solid ${props => (props.$isRed ? '#ff1a1a' : '#383838')};
  border-radius: 4px;
  color: white;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`

export const MiddleSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  margin-left: calc(100% / 14);
  width: calc((100% / 14) * 12);
  aspect-ratio: 12/1;
`

export const BottomSection = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1px;
  margin-left: calc(100% / 14);
  width: calc((100% / 14) * 12);
  aspect-ratio: 12/1;
`

export const SectionButton = styled(NumberButton)`
  width: 100%;
  height: 100%;
  aspect-ratio: auto;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`

export const ChipOverlay = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
`

export const ChipWrapper = styled.div<{ $x: number; $y: number }>`
  position: absolute;
  width: 28px;
  aspect-ratio: 1;
  left: ${props => props.$x}%;
  top: ${props => props.$y}%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`

export const ChipCircle = styled.div<{ $isHover?: boolean; $value: number; $minBetAmount: number }>`
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: ${props => {
    const multiplier = props.$value / props.$minBetAmount
    if (props.$isHover) return CHIP_COLORS.hover
    if (multiplier < 5) return CHIP_COLORS.one
    if (multiplier < 25) return CHIP_COLORS.five
    if (multiplier < 100) return CHIP_COLORS.twentyFive
    return CHIP_COLORS.hundred
  }};
  border: ${props => {
    if (props.$value === props.$minBetAmount) {
      return '2px dashed blue'
    }
    return props.$isHover ? '2px dashed #ffffff44' : '2px dashed white'
  }}

  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: ${props => {
    if (props.$value === props.$minBetAmount) {
      return props.$isHover ? '#ffffff88' : 'blue'
    }
    return props.$isHover ? '#ffffff88' : '#ffffffdd'
  }};

  user-select: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

  /* Add inner ring */
  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    border-radius: 50%;
      border: ${props => {
        if (props.$value === props.$minBetAmount) {
          return props.$isHover ? '2px dashed #ffffff44' : '2px dashed blue'
        }
        return props.$isHover ? '2px dashed #ffffff44' : '2px dashed white'
      }}
  }
`

export const CheckboxContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0px;
  display: flex;
  align-items: center;
`

export const StyledCheckbox = styled.input`
  margin-right: 4px;
  transform: scale(0.8);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid ${FARE_COLORS.gray};
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: ${FARE_COLORS.gray};

    &::after {
      content: '✓';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 12.8px;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  }
`

export const CheckboxLabel = styled.label`
  font-family: 'Gohu';
  font-size: 10px;
  color: ${TEXT_COLORS.two};
`

export const ChipButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 8px;
`

export const ChipButton = styled.button<{
  $isActive: boolean
  $value: number
  $minBetAmount: number
}>`
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${props => {
    const multiplier = props.$value / props.$minBetAmount
    if (multiplier < 5) return `${CHIP_COLORS.one}${props.$isActive ? 'ff' : '99'}`
    else if (multiplier < 25) return `${CHIP_COLORS.five}${props.$isActive ? 'ff' : '99'}`
    else if (multiplier < 100) return `${CHIP_COLORS.twentyFive}${props.$isActive ? 'ff' : '99'}`
    return `${CHIP_COLORS.hundred}${props.$isActive ? 'ff' : '99'}`
  }};
  border: 2px solid ${props => (props.$isActive ? '#ffffff' : CHIP_COLORS.border.normal)};
  box-shadow: ${props =>
    props.$isActive ?
      '0 0 10px rgba(255, 255, 255, 0.5), inset 0 0 8px rgba(0, 0, 0, 0.5)'
    : 'inset 0 0 8px rgba(0, 0, 0, 0.5)'};
  color: ${CHIP_COLORS.text.normal};
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 600;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

  &:first-child {
    &::before {
      content: '';
      position: absolute;
      width: 70%;
      height: 70%;
      border-radius: 50%;
      border: 1.5px dashed blue;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 70%;
    height: 70%;
    border-radius: 50%;
    border: 1.5px dashed white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    border: 1.5px solid ${CHIP_COLORS.border.faded};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover:not(:disabled) {
    transform: scale(1.05);
    border-color: #ffffff;
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const CurrentEntriesSection = styled(SFormSection)`
  padding-bottom: 24px;
  border: none;
  max-height: 268px;
  min-height: 268px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 8px;
`

export const EntryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 0px;
  overflow-y: auto;
  padding: 0 8px 4px 0;
  margin-right: -8px;
  height: 82%;
  mask-image: linear-gradient(to bottom, black calc(100% - 4px), transparent 100%);

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${BORDER_COLORS.one};
    border-radius: 2px;
  }
`

export const EntryItemContainer = styled(motion.div)`
  position: relative;
  overflow: visible;
  min-height: 32px;
`

export const EntryItemInner = styled(motion.div)`
  position: relative;
  width: 91%;
  padding-right: 4px;
`

export const EntryItem = styled(motion.div)<{ $isWinning?: boolean; $intensity?: number }>`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  font-size: 13px;
  background: ${props => {
    if (!props.$isWinning) return 'rgba(0, 0, 0, 0.2)'
    // Add more visible background based on intensity
    switch (props.$intensity) {
      case 1:
        return 'rgba(26, 185, 26, 0.1)'
      case 2:
        return 'rgba(26, 215, 26, 0.1)'
      case 3:
        return 'rgba(26, 245, 26, 0.1)'
      case 4:
        return 'rgba(255, 215, 0, 0.1)'
      case 5:
        return 'rgba(217, 0, 213, 0.1)'
      default:
        return 'rgba(26, 185, 26, 0.1)'
    }
  }};
  backdrop-filter: blur(5px);
  transition: background 0.2s ease;
  width: 100%;
  position: relative;

  &:hover {
    background: ${props => {
      if (!props.$isWinning) return 'rgba(0, 0, 0, 0.3)'
      // More intense on hover
      switch (props.$intensity) {
        case 1:
          return 'rgba(26, 185, 26, 0.15)'
        case 2:
          return 'rgba(26, 215, 26, 0.15)'
        case 3:
          return 'rgba(26, 245, 26, 0.15)'
        case 4:
          return 'rgba(255, 215, 0, 0.15)'
        case 5:
          return 'rgba(217, 0, 213, 0.15)'
        default:
          return 'rgba(26, 185, 26, 0.15)'
      }
    }};
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid
      ${props => {
        if (!props.$isWinning) return BORDER_COLORS.one
        switch (props.$intensity) {
          case 1:
            return OUTCOME_COLORS.win.one
          case 2:
            return OUTCOME_COLORS.win.two
          case 3:
            return OUTCOME_COLORS.win.three
          case 4:
            return OUTCOME_COLORS.win.four
          case 5:
            return OUTCOME_COLORS.win.five
          default:
            return OUTCOME_COLORS.win.one
        }
      }};
    border-radius: 4px;
    pointer-events: none;
    ${props =>
      props.$isWinning &&
      css<{ $intensity?: number }>`
        box-shadow: 0 0 10px ${props => getIntensityColor(props.$intensity)};
        animation: glowPulse 1.5s infinite;
      `}
  }

  @keyframes glowPulse {
    0% {
      box-shadow: 0 0 10px ${props => getIntensityColor(props.$intensity)};
    }
    50% {
      box-shadow: 0 0 20px ${props => getIntensityColor(props.$intensity, '80')};
    }
    100% {
      box-shadow: 0 0 10px ${props => getIntensityColor(props.$intensity)};
    }
  }
`

const getIntensityColor = (intensity?: number, opacity = '4D') => {
  switch (intensity) {
    case 1:
      return OUTCOME_COLORS.win.one + opacity
    case 2:
      return OUTCOME_COLORS.win.two + opacity
    case 3:
      return OUTCOME_COLORS.win.three + opacity
    case 4:
      return OUTCOME_COLORS.win.four + opacity
    case 5:
      return OUTCOME_COLORS.win.five + opacity
    default:
      return OUTCOME_COLORS.win.one + opacity
  }
}

export const EntryChip = styled.div<{ $value: number; $minBetAmount: number }>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${props => {
    const multiplier = props.$value / props.$minBetAmount
    if (multiplier < 5) return CHIP_COLORS.one
    if (multiplier < 25) return CHIP_COLORS.five
    if (multiplier < 100) return CHIP_COLORS.twentyFive
    return CHIP_COLORS.hundred
  }};
  border: ${props =>
    props.$value === props.$minBetAmount ? '2px dashed blue' : '2px dashed white'};
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 600;
  color: ${props => (props.$value === props.$minBetAmount ? 'blue' : CHIP_COLORS.text.normal)};
  flex-shrink: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid ${CHIP_COLORS.border.normal};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export const EntryDescription = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${TEXT_COLORS.two};
  margin-left: 4px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const RemoveButton = styled.button<{ $disabled?: boolean }>`
  background: transparent;
  border: none;
  color: ${TEXT_COLORS.two};
  padding: 2px;
  cursor: ${props => (props.$disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.$disabled ? '0.4' : '0.4')};
  transition: all 0.2s;
  margin-left: auto;
  font-size: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  flex-shrink: 0;

  &:hover:not(:disabled) {
    opacity: ${props => (props.$disabled ? '0.4' : '1')};
    background: ${props => (props.$disabled ? 'transparent' : 'rgba(255, 255, 255, 0.1)')};
  }
`

export const EmptyStateContainer = styled(motion.div)`
  position: relative;
  overflow: hidden;
`

export const EmptyStateContent = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px dashed ${BORDER_COLORS.one};
  border-radius: 4px;
  color: ${TEXT_COLORS.two};
  backdrop-filter: blur(5px);
`

export const EmptyStateText = styled.div`
  font-size: 13px;
  opacity: 0.8;
  letter-spacing: 0.5px;
`

export const ChipBorder = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
`
