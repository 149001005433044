import axios from 'axios'
import posthog from 'posthog-js'
import { useFundWallet } from '@privy-io/react-auth'
import { MoonPayBuyWidget } from '@moonpay/moonpay-react'

import { useActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { useSnapshot } from 'valtio'
import { fundWalletModalState } from '@/components/Modals/FundWalletModal/FundWalletModalState'
import { useSupportModal } from '@/hooks/useSupportModal'

interface IBuyUSDC {
  onCloseOverlay?: () => void
}

export const BuyUsdcModal = () => {
  const { isMoonpayModalShowing } = useSnapshot(fundWalletModalState)

  if (!isMoonpayModalShowing) return null

  return <BuyUSDC onCloseOverlay={() => (fundWalletModalState.isMoonpayModalShowing = false)} />
}

export const BuyUSDC = ({ onCloseOverlay }: IBuyUSDC) => {
  const { walletAddress } = useActiveWallet()
  const { chainDefinition, httpUrl } = useAppChainConfigStore(state => ({
    appContracts: state.appContracts,
    httpUrl: state.appChainConfig.httpUrl,
    chainDefinition: state.appChainConfig.chainDefinition,
  }))
  const { showSupportModal } = useSupportModal()
  const { fundWallet } = useFundWallet({
    onUserExited: (...args) => {
      console.log('User exited fund wallet', args)
      showSupportModal()
    },
  })

  const fundActiveWalletCurrency = () => {
    fundWallet(walletAddress, {
      asset: 'USDC',
      amount: '30.0',
      chain: chainDefinition,
    }).then(() => {
      posthog?.capture('user_funded_wallet_currency', {
        asset: 'USDC',
        amount: '30.0',
        chain: chainDefinition,
      })
    })
  }
  const fundActiveWalletNative = () => {
    fundWallet(walletAddress, {
      asset: 'native-currency',
      amount: '0.0042',
      chain: chainDefinition,
    }).then(() => {
      posthog?.capture('user_funded_wallet', {
        asset: 'native-currency',
        amount: '0.0042',
        chain: chainDefinition,
      })
    })
  }

  const onUrlSignatureRequested = async (url: string) => {
    const { data } = await axios({
      url: `${httpUrl}/moonpay/signature`,
      method: 'POST',
      data: {
        url: url,
      },
    })
    console.log(data.signature)

    return data.signature
  }

  return (
    <MoonPayBuyWidget
      // variant='embedded'
      variant='overlay'
      walletAddress={walletAddress}
      baseCurrencyCode='usd'
      baseCurrencyAmount='100'
      defaultCurrencyCode='eth'
      onCloseOverlay={onCloseOverlay}
      onLogin={async () => console.log('Customer logged in!')}
      onUrlSignatureRequested={onUrlSignatureRequested}
      theme='dark'
      visible
    />
  )
}
