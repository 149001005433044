import { FareAA__factory, USDCMock__factory, UsdcVault__factory } from './typechain'
import { diceHelperFunctions } from './dice'
import { coinFlipHelperFunctions } from './coinFlip'
import { rpsHelperFunctions } from './rps'
import { bombsHelperFunctions, type IBombsSide } from './bombs'
import { crashHelperFunctions } from './crash'
import { type IRouletteSide, rouletteHelperFunctions } from './roulette'
import { type AppGameName } from '@/chains/types'
import { type GameHelperFunctions } from './types'
import { type IPlinkoSide, plinkoHelperFunctions } from './plinko'
import { cardsHelperFunctions } from './cards'

export const SUContractInterface = UsdcVault__factory.createInterface()
export const USDCContractInterface = USDCMock__factory.createInterface()
export const BankrollContractInterface = FareAA__factory.createInterface()

// @NOTE: This might get out of hand with new games, will have to find a better way at some point
export const gameTypeHelperFunctionsMap: {
  [key in AppGameName]: key extends AppGameName.Bombs ? GameHelperFunctions<IBombsSide>
  : key extends AppGameName.Plinko ? GameHelperFunctions<IPlinkoSide>
  : key extends AppGameName.Roulette ? GameHelperFunctions<IRouletteSide>
  : GameHelperFunctions
} = {
  dice: diceHelperFunctions,
  rps: rpsHelperFunctions,
  coinFlip: coinFlipHelperFunctions,
  bombs: bombsHelperFunctions,
  plinko: plinkoHelperFunctions,
  crash: crashHelperFunctions,
  cards: cardsHelperFunctions,
  roulette: rouletteHelperFunctions,
} as const

export const gameTypeIsHelperFunctionsWithSideMap: { [key in AppGameName]: boolean } = {
  dice: true,
  rps: false,
  coinFlip: false,
  bombs: true,
  plinko: true,
  crash: true,
  cards: true,
  roulette: true,
} as const

export type GameSide = number & IBombsSide & IPlinkoSide & IRouletteSide

// USED
export const isValidSide = (gameName: AppGameName, side: GameSide) => {
  return gameTypeHelperFunctionsMap[gameName].isValidSide(side)
}

// USED
export const getMaxCount = (gameName: AppGameName, side: GameSide) => {
  return gameTypeHelperFunctionsMap[gameName].getMaxCount(side)
}

// USED
export const getMultiplierWithPPV = (gameName: AppGameName, side: GameSide) => {
  return gameTypeHelperFunctionsMap[gameName].getMultiplierWithPPV(side)
}

// NOT USED
export const getMultiplierWithoutPPV = (gameName: AppGameName, side: GameSide) => {
  return gameTypeHelperFunctionsMap[gameName].getMultiplierWithoutPPV(side)
}

// NOT USED
export const getKellyFraction = (gameName: AppGameName, side: GameSide) => {
  return gameTypeHelperFunctionsMap[gameName].getKellyFraction(side)
}

// USED
export const getPotentialProfitCoefficient = (gameName: AppGameName, side: GameSide) => {
  return gameTypeHelperFunctionsMap[gameName].getPotentialProfitCoefficient(side)
}
