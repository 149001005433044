import { parseEther } from 'viem'
import { type AppGameName } from '@/chains/types'
import {
  bombCountToRevealCountToMultiplier,
  bombCountToRevealCountToProbability,
  type IBombsSide,
} from '../crypto/bombs'
import { type IPlinkoSide, PlinkoProbabilitiesAndMultipliers } from '../crypto/plinko'
import { packIndexToPackQKForACardDraw } from '../crypto/cards'
import { unit } from './helpers'
import {
  constructRouletteQKFromRouletteNumberToBetFraction,
  type IRouletteSide,
} from '../crypto/roulette'

export const GameNameToQK: { [key in AppGameName]: { getQK: (_side: any) => any } } = {
  coinFlip: {
    getQK: (_side: number) => ({ q: [parseEther('0.5')], k: [parseEther('1.98')] }),
  },
  rps: {
    getQK: (_side: number) => ({
      q: [unit / 3n, unit / 3n],
      k: [parseEther('1.98'), parseEther('0.99')],
    }),
  },
  dice: {
    getQK: (side: number) => ({
      q: [((10000n - BigInt(side)) * unit) / 10000n],
      k: [(((10000n * unit) / (10000n - BigInt(side))) * 99n * unit) / 100n / unit],
    }),
  },
  bombs: {
    getQK: (side: IBombsSide) => ({
      q: [bombCountToRevealCountToProbability[side.bombCount][side.revealCount]],
      k: [bombCountToRevealCountToMultiplier[side.bombCount][side.revealCount]],
    }),
  },
  plinko: {
    getQK: (side: IPlinkoSide) => ({
      q: PlinkoProbabilitiesAndMultipliers.getProbabilitesForRowCount(side.rowCount),
      k: PlinkoProbabilitiesAndMultipliers.getMultipliersForRiskLevelAndRowCount(
        side.riskLevel,
        side.rowCount
      ),
    }),
  },
  crash: {
    getQK: (side: number) => ({
      q: [(((99n * unit) / 100n) * unit) / ((BigInt(side) * unit) / 100n)], // since q * k = ev (q * k = ev * 1e18, because of the units) and we want to have ev = 0.99 * 1e18, we just do 0.99 * 1e18 / k to find q
      k: [(BigInt(side) * unit) / 100n], // for x2.0, side will be 200 => 200 * unit / 100
    }),
  },
  cards: {
    getQK: (side: number) => ({
      q: packIndexToPackQKForACardDraw[side].q,
      k: packIndexToPackQKForACardDraw[side].k,
    }),
  },
  roulette: {
    getQK: (side: IRouletteSide) =>
      constructRouletteQKFromRouletteNumberToBetFraction(side.rouletteNumberToBetFraction),
  },
}
