import React from 'react'
import { Canvas } from '@react-three/fiber'
import { CrashScene } from './CrashScene'
import { SBorderAnimator } from '@/components/Canvases/shared/BorderFlash'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SVolumeSliderWrapper, VolumeSlider } from '@/components/shared/SoundSystem/VolumeSlider'
import { GameTimeProvider } from './GameTimeContext'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { AppGameName } from '@/chains/types'
import { ShareWinningsIconButton } from '@/components/shared/ShareWinningsIconButton'
import { SCanvas } from '@/components/Canvases/styles'

export const CrashThree: React.FC = () => {
  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)

  return (
    <SCanvas>
      <DeltaNumbersDisplay gameName={AppGameName.Crash} />
      <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
        <GameTimeProvider>
          {/* <TimeDebugOverlay /> */}
          <Canvas
            camera={{ position: [0, 0, 20], fov: 60 }}
            // camera={{ position: [0, 0, 20], fov: 120 }}
            shadows
            dpr={[1, 2]}
            gl={{ antialias: true, stencil: false, preserveDrawingBuffer: false, alpha: true }}
          >
            <fog attach='fog' args={['#000000', 5, 80]} />
            <CrashScene />
          </Canvas>
          <SVolumeSliderWrapper>
            <VolumeSlider />
          </SVolumeSliderWrapper>
          {didPlayerWin && <ShareWinningsIconButton />}
        </GameTimeProvider>
      </SBorderAnimator>
    </SCanvas>
  )
}
export default CrashThree
