import { externalLinks, getRouteLinks } from '@/constants/links'
import {
  BottomDirectory,
  DirectoryWrapper,
  ExternalLinkWrapper,
  FloatingDirectory,
  LinkWrapper,
  TopDirectory,
} from '../style'
import { fadeInRightVariant } from '@/design/animations'
import { useGetGameStoreType } from '@/store/useGameStateStore'
import LinkTitlePopover from '../../FarePopover'
import { useActiveWallet } from '@/lib/privy/hooks/useActiveWallet'

export const LeftPanel = () => {
  const { pathname } = useLocation()
  const gameStoreType = useGetGameStoreType(pathname)
  const activePage = useMemo(() => {
    return pathname
  }, [pathname])
  const { activeWallet } = useActiveWallet()

  const shouldDisable = useMemo(() => gameStoreType !== 'IDLE', [gameStoreType])

  const [isVisible, setIsVisible] = useState(true)
  const visibleTimerRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    visibleTimerRef.current = setTimeout(() => {
      setIsVisible(false)
    }, 3_000)

    return () => clearTimeout(visibleTimerRef.current)
  }, [])

  const showMenuTitles = useCallback(() => {
    setIsVisible(true)
    if (visibleTimerRef.current) {
      clearTimeout(visibleTimerRef.current)
    }
  }, [])

  const hideMenuTitles = useCallback(() => {
    setIsVisible(false)
  }, [])

  const routeLinks = useMemo(
    () => getRouteLinks(activeWallet?.walletClientType),
    [activeWallet?.walletClientType]
  )

  return (
    <DirectoryWrapper onMouseEnter={showMenuTitles} onMouseLeave={hideMenuTitles}>
      <FloatingDirectory variants={fadeInRightVariant} initial='initial' animate='animate'>
        <TopDirectory $isDisabled={shouldDisable}>
          {routeLinks.map(route => (
            <LinkWrapper
              className={'link-absolute-wrapper'}
              key={route.title}
              to={route.to}
              $isActive={route.to === activePage}
              $bgColor={route.bgColor}
              $borderColor={route.borderColor}
              target={route.to.startsWith('https') ? '_blank' : undefined}
            >
              <LinkTitlePopover isShowing={isVisible} textColor='#fff'>
                {route.title}
              </LinkTitlePopover>
              <img id={`route-${route.title}`} src={route.img} alt={`${route.img}-icon`} />
            </LinkWrapper>
          ))}
        </TopDirectory>
        <BottomDirectory>
          {externalLinks.map((link, i) => (
            <ExternalLinkWrapper
              key={link.title}
              href={link.to}
              target='_blank'
              rel='noreferrer'
              $delay={(routeLinks.length + i + 1) / 10}
            >
              <img src={link.img} alt={`${link.alt}-icon`} />
            </ExternalLinkWrapper>
          ))}
        </BottomDirectory>
      </FloatingDirectory>
    </DirectoryWrapper>
  )
}
