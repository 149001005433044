import { useActiveWallet } from '@/lib/privy/hooks'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import useSUContractStore from '@/store/useSUContractStore'
import { usePrivy } from '@privy-io/react-auth'

export const useHasAppLoaded = () => {
  const { walletAddress } = useActiveWallet()
  const { ready } = usePrivy()
  const approvedGameState = useSUContractStore(state => state.approvedGameState)
  const approveAllowanceState = useCurrencyStore.use.approveAllowanceState()
  const sessionVerifyState = useAppChainConfigStore(state => state.sessionVerifyState)
  // console.log(approvedGameState, approveAllowanceState, sessionVerifyState)

  return useMemo(
    () =>
      Boolean(!walletAddress && ready) ||
      Boolean(
        sessionVerifyState !== 'pending' &&
        approvedGameState !== 'pending' &&
        approveAllowanceState !== 'pending'
      ),
    [sessionVerifyState, approvedGameState, approveAllowanceState, walletAddress, ready]
  )
}
