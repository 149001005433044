import { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { Text } from '@react-three/drei'
import { Mesh } from 'three'

interface OpenPackButtonProps {
  position: [number, number, number]
  onClick: () => void
  visible: boolean
  buttonActionLabel: string
}

export function OpenPackButton({
  position,
  onClick,
  visible,
  buttonActionLabel,
}: OpenPackButtonProps) {
  const mesh = useRef<Mesh>(null!)

  useFrame(() => {})

  if (!visible) return null // Don't render if not visible

  return (
    <mesh ref={mesh} position={position} onClick={onClick}>
      <boxGeometry args={[3, 1, 0.05]} />
      <meshStandardMaterial opacity={0.001} transparent={true} depthWrite={false} color='white' />
      <Text
        position={[0, 0, 0.1]}
        fontSize={0.5}
        color='white'
        anchorX='center'
        anchorY='middle'
        textAlign='center'
      >
        {buttonActionLabel}
      </Text>
    </mesh>
  )
}
