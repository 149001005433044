import React, { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SBorderAnimator } from '@/components/Canvases/shared/BorderFlash'
import { SVolumeSliderWrapper, VolumeSlider } from '@/components/shared/SoundSystem/VolumeSlider'
import { Bloom, EffectComposer } from '@react-three/postprocessing'
import { CardScene } from './CardScene'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { AppGameName } from '@/chains/types'
import { SCanvas } from '../styles'
import { PrivyBanner } from '@/components/Forms/Cards/PrivyBanner'

export const CardsCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)

  return (
    <SCanvas>
      <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
        <DeltaNumbersDisplay gameName={AppGameName.Cards} />
        <Canvas
          className='cards-canvas'
          ref={canvasRef}
          shadows
          dpr={[1, 2]}
          gl={{ antialias: true, stencil: false, preserveDrawingBuffer: false, alpha: true }}
          camera={{
            position: [0, -1, 10],
            fov: 45,
            rotation: [0, 0, 0],
          }}
        >
          <fog attach='fog' args={['#000000', 5, 25]} />
          <EffectComposer>
            <Bloom luminanceThreshold={0.15} luminanceSmoothing={3} intensity={0.5} />
          </EffectComposer>
          <CardScene />
        </Canvas>
        <SVolumeSliderWrapper>
          <VolumeSlider />
        </SVolumeSliderWrapper>
      </SBorderAnimator>
      <PrivyBanner />
    </SCanvas>
  )
}
