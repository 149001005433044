import { motion } from 'framer-motion'
import {
  floatingContainer,
  SPACING,
  COMPONENTS,
  BACKGROUND_COLORS,
  FONT_STYLES,
  TEXT_COLORS,
  FARE_COLORS,
  BORDER_COLORS,
} from '@/design'
import './../../NotiPill/noti-pill.css'

import { Button } from '../../Button'

export const PanelContainer = styled(motion.div)`
  > * {
    border-radius: 6px;
    overflow: hidden;
  }

  > *:not(:last-child) {
    margin-bottom: ${SPACING.md}px;
  }

  @media (max-width: 800px) {
    margin: 16px;
  }
`

export const OverviewPanelWrapper = styled.div`
  ${floatingContainer};
`

export const BalanceWrapper = styled.div`
  border-radius: 6px;

  button {
    width: 100%;
  }

  > * {
    padding: 0 ${SPACING.sm}px;
  }
`

export const BlockHeader = styled.div<{ $isShowPanel?: boolean }>`
  display: flex;
  padding: 0 ${SPACING.sm}px;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  height: ${COMPONENTS.panelTabHeader}px;
  background: ${BACKGROUND_COLORS.one};
  ${({ $isShowPanel }) =>
    $isShowPanel ?
      css`
        margin-bottom: ${SPACING.sm}px;
        border-bottom: 1px solid ${BORDER_COLORS.one};
      `
    : css`
        margin-bottom: 0px;
      `};

  > div {
    display: flex;
    align-items: center;
  }

  > * {
    margin: auto 0;
  }

  img {
    width: 20px;
    height: 20px;
  }

  > img:last-child {
    margin: auto 0;
    width: 16px;
    height: 16px;
    padding: 0;
    filter: invert();
  }

  &.block-full-width {
    width: 100%;
  }

  p,
  .balance-amount-display {
    margin-left: ${SPACING.sm}px;
    text-transform: uppercase;
    ${FONT_STYLES.md}
  }
`

export const OverviewRow = styled.div`
  display: flex;
  margin-bottom: ${SPACING.sm}px;
  height: 20px;
  span {
    font-size: 14px !important;
    line-height: 1px;
    padding-top: 2px;
  }

  &.left-border {
    margin-left: 12px;
    border-left: 3px solid rgba(255, 255, 255, 0.12);
  }

  &.left-border-large {
    margin-left: 12px;
    border-left: 3px solid rgba(255, 255, 255, 0.12);
    height: 20px;
    padding-top: 4px;
    padding-bottom: 10px;
  }

  img {
    margin: auto 0;
    height: 16px;
    width: 16px;
    margin-right: ${SPACING.sm}px;
  }

  p,
  span {
    ${FONT_STYLES.lg};
    color: #aaaaaa;
    margin: auto 0;
  }
`

export const StatsOverviewRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACING.sm}px;
  padding-top: 4px;
  padding-bottom: 10px;
  span {
    font-size: 14px !important;
    line-height: 1px;
    padding-top: 2px;
  }

  &.left-border {
    margin-left: 12px;
    border-left: 3px solid rgba(255, 255, 255, 0.12);
  }

  img {
    margin: auto 0;
    height: 16px;
    width: 16px;
    margin-right: ${SPACING.sm}px;
  }

  p,
  span {
    ${FONT_STYLES.lg};
    color: #aaaaaa;
    margin: auto 0;
  }

  > div {
    display: flex;
    &:last-child {
      margin-top: 16px;
    }
  }
`

export const CollapsedWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const CollapsedBalanceWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: ${SPACING.sm}px;
  }
`
export const OverviewLabel = styled.div`
  ${FONT_STYLES.sm};
  text-transform: uppercase;
  color: ${TEXT_COLORS.one};
  display: flex;
  align-items: center;

  > img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  span {
    line-height: 1px;
    padding-top: 3px;
  }

  &:not(:first-of-type) {
    margin-top: ${SPACING.md}px;
    margin-bottom: ${SPACING.md}px;
  }
`

export const GameModeButton = styled(Button)<{ $isActive: boolean }>`
  &:focus-visible {
    outline: none;
  }
  box-shadow: none;
  transition: none;
  &:hover {
    background: transparent;
    border: 1px solid ${FARE_COLORS.blue} !important;
    box-shadow: none;
  }
  ${({ $isActive }) =>
    $isActive ?
      css`
        border: 1px solid ${FARE_COLORS.blue} !important;
        color: ${TEXT_COLORS.one};
        background: #0a0a0a;
        &:hover {
          background: #0a0a0a;
          box-shadow: none;
        }
      `
    : css`
        border: 1px solid rgba(255, 255, 255, 0.16) !important;
        color: ${TEXT_COLORS.two} !important;
        &:hover {
          box-shadow: none;
        }
      `};
`

export const OverviewButtonWrapper = styled.div`
  padding: 4px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`

export const OverviewButton = styled(Button)`
  height: 42px;
`
