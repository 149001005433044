import React, { StrictMode } from 'react'
import { RouletteCanvas } from './RouletteCanvas'
import { RouletteForm } from '@/components/Games/Roulette/RouletteForm'
import GamePageComponent from '@/components/shared/GamePage'
import { AppGameName } from '@/chains/types'
import { useRouletteGameStore } from '@/store/useRouletteGameStore'
import { BORDER_COLORS } from '@/design'
import { useIsBreakpoint, useIsBreakpointLandscape } from '@/hooks/common/useIsBreakpoint'
import { CloseButtonIcon } from '@/components/shared/CloseButton'

const MessageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const UseLandscapeMessage = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background: rgba(0, 0, 0);
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  height: fit-content;
  padding: 16px;
  text-align: center;
  z-index: 1000;

  p {
    font-size: 24px;
    line-height: 1.5;
  }
`

export const RoulettePage: React.FC = () => {
  const setDrawerOpen = useRouletteGameStore(state => state.setDrawerOpen)
  const isNotDesktop = useIsBreakpoint('xs')
  const isLandscape = useIsBreakpointLandscape('sm')
  const [messageVisible, setMessageVisible] = useState(true)
  return (
    <StrictMode>
      {!isLandscape && isNotDesktop && messageVisible && (
        <MessageOverlay>
          <UseLandscapeMessage>
            <CloseButtonIcon onClick={() => setMessageVisible(false)} />
            <p>For a better gaming experience, play this game in landscape mode!</p>
          </UseLandscapeMessage>
        </MessageOverlay>
      )}
      <GamePageComponent
        mode={AppGameName.Roulette}
        CanvasComponent={RouletteCanvas}
        FormComponent={() => <RouletteForm setIsDrawerOpen={setDrawerOpen} />}
      />
    </StrictMode>
  )
}
