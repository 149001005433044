import { AnimatePresence, motion } from 'framer-motion'
import CountUp from 'react-countup'
import { BigNumber } from 'ethers'

import { SVGS, PNGS } from '@/assets'
import useCurrencyStore from '@/store/useCurrencyStore'
import {
  BlockHeader,
  CollapsedWrapper,
  BalanceWrapper,
  OverviewLabel,
  OverviewRow,
  CollapsedBalanceWrapper,
  OverviewPanelWrapper,
  StatsOverviewRow,
  OverviewButtonWrapper,
  PanelContainer,
  GameModeButton,
  OverviewButton,
} from './style'
import { GameHistory } from '@/components/GameHistory'
import { ButtonEnum } from '../../Button'
import { useLocalStorage } from 'usehooks-ts'
import useUserDataStore from '@/store/useUserDataStore'
import { multiplyBigNumberWithFixedPointNumber } from '@/lib/crypto'
import { useActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { useWithdrawPrivyModalState } from '../../../Modals/WithdrawPrivyModal/withdrawPrivyModalState'
import { useFundModal } from '../../../../hooks/useFundModal'
import { QuickPlayButton } from '../../Button/QuickPlayButton'

export const OverviewPanel = () => {
  const [isShowPanel, setShowPanel] = useLocalStorage<boolean>('showBalancePanel', true)
  const selectedCurrencyAmount = useCurrencyStore(state => state.balances.currency)
  const { activeWallet, externalWallet, appChainConfig } = useActiveWallet()
  const {
    accWinningAmount,
    accLosingAmount,
    winThresholdForPoint,
    loseThresholdForPoint,
    bonusLevelPoints,
    levelMultiplier,
    cachedAssistPoints,
  } = useUserDataStore()
  const { openModal: openFundWalletModal } = useFundModal()
  const { showWithdrawPrivyModal } = useWithdrawPrivyModalState()
  const { isUsingSmartWallet, setIsUsingSmartWallet } = useAppChainConfigStore(state => ({
    isUsingSmartWallet: state.isUsingSmartWallet,
    setIsUsingSmartWallet: state.setIsUsingSmartWallet,
  }))
  const { networkStyle } = appChainConfig

  const overviewActionBtnElem = useMemo(() => {
    if (isUsingSmartWallet && Number(selectedCurrencyAmount) > 0) {
      return (
        <>
          <OverviewButton
            onClick={openFundWalletModal}
            buttonType={ButtonEnum.CONNECT_WALLET}
            disabled={false}
          >
            <span>DEPOSIT</span>
          </OverviewButton>
          <OverviewButton
            onClick={showWithdrawPrivyModal}
            buttonType={ButtonEnum.CONNECT_WALLET}
            disabled={false}
          >
            <span>WITHDRAW</span>
          </OverviewButton>
        </>
      )
    }

    return (
      <OverviewButton
        onClick={openFundWalletModal}
        buttonType={ButtonEnum.CONNECT_WALLET}
        disabled={false}
      >
        <span>ADD FUNDS</span>
      </OverviewButton>
    )
  }, [openFundWalletModal, showWithdrawPrivyModal, isUsingSmartWallet, selectedCurrencyAmount])

  const overviewToggleBtnElem = useMemo(() => {
    return (
      <>
        {!isUsingSmartWallet ?
          <QuickPlayButton
            onClick={() => {
              setIsUsingSmartWallet(!isUsingSmartWallet)
            }}
            disabled={false}
            $isProcing={false}
            $isActive={false}
          >
            <span>QUICKPLAY</span>
          </QuickPlayButton>
          : <GameModeButton
            onClick={() => {
              setIsUsingSmartWallet(!isUsingSmartWallet)
            }}
            disabled={false}
            isLoading={false}
            buttonType={ButtonEnum.PRIMARY_1}
            style={{ height: '42px', marginBlock: 0 }}
          >
            <span>STANDARD</span>
          </GameModeButton>
        }
      </>
    )
  }, [isUsingSmartWallet, setIsUsingSmartWallet])

  const deathMatchPoints = useMemo(() => {
    return (
      multiplyBigNumberWithFixedPointNumber(
        BigNumber.from(accWinningAmount || '0'),
        String(levelMultiplier || 1)
      )
        .div(winThresholdForPoint || 1)
        .toNumber() +
      multiplyBigNumberWithFixedPointNumber(
        BigNumber.from(accLosingAmount || '0'),
        String(levelMultiplier || 1)
      )
        .div(loseThresholdForPoint || 1)
        .toNumber() +
      // Add Assists
      (cachedAssistPoints || 0) +
      // Add bonus level points
      (bonusLevelPoints || 0) || 0
    )
  }, [
    accWinningAmount,
    levelMultiplier,
    winThresholdForPoint,
    accLosingAmount,
    loseThresholdForPoint,
    cachedAssistPoints,
    bonusLevelPoints,
  ])

  return (
    <AnimatePresence>
      <PanelContainer>
        <OverviewPanelWrapper>
          <BlockHeader $isShowPanel={isShowPanel} onClick={() => setShowPanel(!isShowPanel)}>
            <motion.div
              key={String(isShowPanel)}
              transition={{
                duration: 0.25,
                ease: 'easeIn',
              }}
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
            >
              {isShowPanel ?
                <>
                  <img src={SVGS.rewardIcon} alt='wallet-icon' />
                  <p>BALANCE</p>
                </>
                : <CollapsedWrapper>
                  <CollapsedBalanceWrapper>
                    <img
                      src={networkStyle.currencyIcon}
                      alt={networkStyle.currencyName}
                      width={16}
                    />
                    <CountUp
                      end={Number(selectedCurrencyAmount)}
                      decimals={2}
                      duration={2}
                      separator={','}
                      suffix={` ${networkStyle.currencyName}`}
                      preserveValue
                    />
                  </CollapsedBalanceWrapper>
                </CollapsedWrapper>
              }
            </motion.div>
            <img
              src={PNGS.chevronIcon}
              style={{
                transform: `rotate(${isShowPanel ? 180 : 0}deg)`,
                transition: '.2s ease-in-out',
              }}
            />
          </BlockHeader>
          {isShowPanel ?
            <motion.div
              key={String(isShowPanel)}
              transition={{
                duration: 0.25,
                ease: 'easeIn',
              }}
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
            >
              <BalanceWrapper>
                <>
                  <StatsOverviewRow className='left-border'>
                    <div>
                      <img
                        src={networkStyle.currencyIcon}
                        alt={networkStyle.currencyName}
                        width={16}
                      />
                      <CountUp
                        end={Number(selectedCurrencyAmount)}
                        decimals={2}
                        duration={2}
                        separator={','}
                        suffix={` ${networkStyle.currencyName}`}
                        preserveValue
                      />
                    </div>
                    <div>
                      <img src={SVGS.deathmatchIcon} alt='usdc' />
                      <CountUp
                        end={deathMatchPoints}
                        decimals={0}
                        duration={2}
                        separator={','}
                        suffix={' POINTS'}
                        preserveValue
                      />
                    </div>
                  </StatsOverviewRow>

                  <OverviewLabel>
                    <img src={SVGS.slotsIcon} alt='history' />
                    <span>HISTORY</span>
                  </OverviewLabel>
                  <OverviewRow className='left-border-large'>
                    <GameHistory />
                  </OverviewRow>
                </>
                {Boolean(activeWallet) && (
                  <OverviewButtonWrapper>{overviewActionBtnElem}</OverviewButtonWrapper>
                )}
                {Boolean(externalWallet) && (
                  <OverviewButtonWrapper>{overviewToggleBtnElem}</OverviewButtonWrapper>
                )}
              </BalanceWrapper>
            </motion.div>
            : null}
        </OverviewPanelWrapper>
      </PanelContainer>
    </AnimatePresence>
  )
}
