import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  FARE_COLORS,
  FONT_STYLES,
  SPACING,
  TEXT_COLORS,
} from '@/design'

const commonStyles = css`
  height: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: stretch;
  justify-content: center;
`

export const Suffix = styled.div`
  ${commonStyles}
  right: 0px;
  margin-right: ${SPACING.sm}px;
`
export const Prefix = styled.div`
  ${commonStyles}
  left: 0px;
  margin-left: ${SPACING.xs}px;
`
export const BaseInput = styled.input<{ $hasPrefix?: boolean; $hasSuffix?: boolean }>`
  background: ${BACKGROUND_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  border-radius: 6px;
  padding: ${SPACING.sm}px;
  width: calc(100% - ${SPACING.sm * 2}px);
  display: block;
  font-size: 14px !important;
  transition:
    border ease-in-out 0.18s,
    background ease-in-out 0.18s;

  ${({ $hasPrefix }) =>
    $hasPrefix &&
    css`
      padding-left: ${SPACING.xl}px;
    `}

  ${({ $hasSuffix }) =>
    $hasSuffix &&
    css`
      padding-right: ${SPACING.sm}px;
    `}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &:focus {
    outline: none;
    border: 1px solid ${FARE_COLORS.aqua};
    background: #010e0c;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    user-select: none;
  }
`

export const InputWrapper = styled.div<{ $hasPrefix?: boolean; $hasSuffix?: boolean }>`
  display: flex;
  position: relative;
  touch-action: none;

  // Modify sibling selector if DOM hierarchy isn't correct
  #input-num-format-new:focus-within ~ #fare-input-slider-wrapper {
    .thumb-0 {
      background: #428375;
    }
    .track-0 {
      border-top: 1px solid ${FARE_COLORS.aqua};
    }
    .track-1 {
      border-top: 1px solid #1b1d26;
      border-bottom: 1px solid ${FARE_COLORS.aqua};
    }
  }

  &:has(#fare-input-slider-wrapper > .fare-input-slider:active) {
    #input-num-format-new {
      outline: none;
      border: 1px solid ${FARE_COLORS.aqua};
      background: #010e0c;
    }
  }

  input {
    ${({ $hasPrefix }) =>
      $hasPrefix &&
      css`
        padding-left: ${SPACING.lg + SPACING.sm}px !important;
      `}

    ${({ $hasSuffix }) =>
      $hasSuffix &&
      css`
        padding-right: ${SPACING.xl + SPACING.xs}px !important;
      `}
  }

  ${Suffix},
  ${Prefix} {
    > * {
      border-radius: 6px;
      text-transform: uppercase;
      color: ${TEXT_COLORS.one};
      ${FONT_STYLES.xs};
      margin: auto;
    }

    button {
      right: 0;
      background: ${BACKGROUND_COLORS.two};
      border: 1px solid ${BORDER_COLORS.one};
      transition: 0.2s ease-in-out;
      all: unset;
      background: ${BACKGROUND_COLORS.two};
      border: 1px solid ${BORDER_COLORS.one};
      color: ${TEXT_COLORS.two};
      ${FONT_STYLES.xs};
      border-radius: 4px;
      min-width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: auto;
      padding: ${SPACING.xxs}px;
      min-height: 18px;

      &:not(:last-of-type) {
        margin-right: 6px;
      }

      &:hover {
        color: ${TEXT_COLORS.one};
        border: 1px solid ${FARE_COLORS.blue};
      }
    }
  }
`

export const SReactSliderWrapper = styled.div<{ $disabled?: boolean }>`
  height: 18px;
  width: 100%;
  position: absolute;
  bottom: -6px;
  ${props =>
    props.$disabled &&
    css`
      pointer-events: none;
    `}
  .fare-input-slider {
    cursor: pointer;
    width: calc(100% - 1.5px);
    height: 18px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .track-0 {
      transition:
        background ease-out 0.16s,
        border-top ease-out 0.16s;
      height: 4px;
      background: ${FARE_COLORS.aqua}50;
      border-top: 1px solid transparent;
      border-bottom-left-radius: 6px;
      border-bottom: 1px solid transparent;
      overflow: hidden;
    }

    .track-1 {
      transition:
        background ease-out 0.16s,
        border-top ease-out 0.16s;
      height: 4px;
      background: #1b1d26;
      border-bottom-right-radius: 6px;
      border-top: 1px solid ${BORDER_COLORS.one};
      border-bottom: 1px solid transparent;
    }

    &:active {
      cursor: grabbing !important;
      .track-0 {
        border-top: 1px solid ${FARE_COLORS.aqua};
        border-bottom: 1px solid ${FARE_COLORS.aqua};
      }
      .track-1 {
        border-bottom: 1px solid ${FARE_COLORS.aqua};
      }
      .thumb-0 {
        background: #428375;
      }
    }
  }
`

export const SSliderThumb = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 2px;
  border: 1px solid ${FARE_COLORS.aqua};
  background: ${FARE_COLORS.aqua}50;
  transition: background ease-out 0.16s;
  backdrop-filter: blur(1px);
  &:active {
    background: #428375;
  }
  &:focus {
    outline: none;
  }
`
