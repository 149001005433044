import type { LiveEntrySocket, ILiveEntry } from '../types'
import { FSpaceBase, type IFSpaceBaseOpts } from './FSpace'
import { type LiveEntryFState, initialLiveEntryFState } from '../state'
import { useDelayedLiveEntry } from '@/store/useGameStateStore'
import { MAX_LIVE_ENTRIES } from '@/lib/fare/state/gameLiveEntrySlice'
import { nanoid } from 'nanoid'
import { gameProxy } from '@/store/proxy/gameProxy'

type LiveEntrySpaceOpts = Omit<IFSpaceBaseOpts<LiveEntryFState>, 'name' | 'status' | 'state'>

export class LiveEntrySpace extends FSpaceBase<LiveEntrySocket, LiveEntryFState> {
  // fundOwnerAddressToStoredCardData = {} as any
  constructor({ fsocketOpts, authToken }: LiveEntrySpaceOpts) {
    super({
      name: 'live-entry',
      state: initialLiveEntryFState,
      fsocketOpts,
      authToken,
    })

    this.mountListeners()
  }

  onReconnectSocket(): void {
    this.state.liveEntries = []
  }

  mountListeners() {
    if (this.hasMountedListeners) return
    this.hasMountedListeners = true
    this.io.on('initial_state', initialLiveEntries => {
      this.state.liveEntries = initialLiveEntries.map(liveEntry => {
        return {
          ...liveEntry,
          reactKey: nanoid(),
        }
      })
    })

    // @TODO: Ensure that the same chat message isn't added more than once
    this.io.on('new_live_entry', _liveEntry => {
      const liveEntry: ILiveEntry = {
        ..._liveEntry,
        reactKey: nanoid(),
      }
      console.log('DEBUG::: listened to new live entry: ', _liveEntry)

      const isMyEntry = this.#checkIsMyEntry(liveEntry)
      console.log('DEBUG::: isMyEntry: ', isMyEntry)
      if (isMyEntry) return

      if (this.state.liveEntries.length >= MAX_LIVE_ENTRIES) {
        this.state.liveEntries.splice(this.state.liveEntries.length - 1, 1)
      }

      this.state.liveEntries.unshift(liveEntry)
    })
  }

  #checkIsMyEntry(liveEntry: ILiveEntry) {
    //          ╭─────────────────────────────────────────────────────────╮
    //          │  Changed this to now be the users address because the   │
    //          │   smart wallet tx doesn't return a tx hash before it    │
    //          │                        finishes                         │
    //          ╰─────────────────────────────────────────────────────────╯
    const delayedLiveEntry = useDelayedLiveEntry.getState()
    const currGameName = gameProxy.pathGameName
    const currWalletAddress = delayedLiveEntry.liveEntry.submittedTxHash

    if (
      currWalletAddress === liveEntry.fundOwnerAddress.toLowerCase() &&
      liveEntry.gameName === currGameName
    ) {
      console.log('IS MY LIVE ENTRY', liveEntry.gameName, currGameName)
      delayedLiveEntry.setInProgressLiveEntry(liveEntry, liveEntry.resolvementTxHash)
      return true
    }
  }
}
