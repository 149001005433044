import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import { Environment } from '@react-three/drei'
import { PNGS } from '@/assets'
import { useCardsGameStore } from '@/store/useCardsGameStore'
import { OpenPackButton } from './OpenPackButton'
import { type Group } from 'three'
import { ConfettiEffect } from './ConfettiEffect'
import { useGameSoundManager } from './CardsAudio'
import { CardObject } from './CardObject'
import { useCardsGameState } from '@/store/useGameStateStore'
import { useShallow } from 'zustand/react/shallow'
import { cardIdToCards } from '@/components/Games/Cards/CardsData'
import { entryEvent } from '@/events/entryEvent'
import { cardDrawCountToOpenAPack } from '@/lib/crypto/cards'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import useSUContractStore from '@/store/useSUContractStore'

interface Card {
  id: number
  rotation: number
  isClicked: boolean
}

const threeVec = new THREE.Vector3()

export const CardScene: React.FC = () => {
  const { cardResults, packAvailable, setPackAvailable, backendResult, clearBackendResult } =
    useCardsGameStore()
  const isMobileScreen = useIsBreakpoint('sm')
  const { playGameSound } = useGameSoundManager()
  const [isButtonVisible, setButtonVisible] = useState(false)
  const [buttonAction, setButtonAction] = useState('Open Pack')
  const cubeRef = useRef<Group>(null) // Reference to the cube mesh
  const duration = 200
  let isGroupClickable = false
  const currentStartPos = useRef(new THREE.Vector3(-3, 1, 2))
  const endPosition = useRef(new THREE.Vector3(-3, 1, 5))
  const startTime = useRef(0)
  const dummyCards: number[] = new Array(cardDrawCountToOpenAPack).fill(0)
  const [cardArray, setCardArray] = useState<number[]>(dummyCards)
  const selectedCard = useRef<number>(-1)
  const [showConfetti, setShowConfetti] = useState(false)
  const { send, gameStateType } = useCardsGameState(
    useShallow(state => ({
      selectedSide: state.entry.side,
      setSelectedSide: (side: number) => state.setEntry({ side }),
      results: state.results,
      side: state.submittedEntry ? state.submittedEntry.side : state.entry.side,
      gameStateType: state.type,
      send: state.send,
    }))
  )
  const { setInProgressEntry, setIsSubmitting } = useSUContractStore(
    useShallow(state => ({
      setInProgressEntry: state.setInProgressEntry,
      setIsSubmitting: state.setIsSubmitting,
    }))
  )
  useEffect(() => {
    // console.log('state.type', state.type)
    switch (gameStateType) {
      case 'IDLE':
        break
      case 'RESET':
        send({ type: 'IDLE', payload: {} })
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStateType])

  const [cards, setCards] = useState<Card[]>(
    Array.from({ length: dummyCards.length }, (_, i) => ({
      id: i,
      rotation: 0,
      isClicked: false,
    }))
  )
  const ShuffleSounds = {
    shuffle_1: 'shuffle_1',
    shuffle_2: 'shuffle_2',
    shuffle_3: 'shuffle_3',
    shuffle_4: 'shuffle_4',
    shuffle_5: 'shuffle_5',
  }
  const GetRandomShuffleSound = () => {
    const keys = Object.keys(ShuffleSounds)
    return keys[Math.floor(Math.random() * keys.length)]
  }

  // Handle flipping a specific card
  const flipCard = useCallback(
    (id: number) => {
      // console.log('selectedCard, id:', selectedCard.current, id)
      if (id == 0 && selectedCard.current == -1) {
        entryEvent.pub('entryFinished', {
          deltaAmount: backendResult.deltaAmounts[id], // deltaAmount
        })
        playGameSound('win', 0.5, 0.8)
        triggerConfetti()
        setCards(prevCards =>
          prevCards.map(card =>
            card.id === id ? { ...card, rotation: card.rotation + Math.PI } : card
          )
        )
      } else {
        if (id === 0) {
          if (selectedCard.current == id) {
            // console.log('second click')
          } else {
            console.log('wtf')
            return
          }
        } else {
          if (id - 1 != selectedCard.current) {
            console.log('wtf')
            return
          }
        }

        setCards(prevCards =>
          prevCards.map(card =>
            card.id === id + 1 ? { ...card, rotation: card.rotation + Math.PI } : card
          )
        )
        setCardClicked(id)
        if (id === dummyCards.length - 1) {
          //pl
          playGameSound(GetRandomShuffleSound(), 0.5, 0.8)
          setButtonVisible(true)
          setButtonAction('Close Pack')
          moveToNewPosition(-3, 1.5, 0)
        } else {
          playGameSound('win', 0.5, 0.8)
          entryEvent.pub('entryFinished', {
            deltaAmount: backendResult.deltaAmounts[id + 1], // deltaAmount
          })
        }
      }

      selectedCard.current = id
    },
    [backendResult]
  )

  const setCardClicked = useCallback((id: number) => {
    setCards(prevCards =>
      prevCards.map(card => (card.id === id ? { ...card, isClicked: true } : card))
    )
  }, [])

  // reset the cards to all have a rotation of 0
  const resetCards = useCallback(() => {
    setCards(prevCards => prevCards.map(card => ({ ...card, rotation: 0, isClicked: false })))
  }, [])

  const handleButtonClick = () => {
    if (packAvailable) {
      selectedCard.current = -1
      setButtonVisible(false)
      setPackAvailable(false)
      moveToNewPosition(-3, 8, 2)
      isGroupClickable = true
      playGameSound(GetRandomShuffleSound(), 0.5, 0.8)
    } else {
      setButtonVisible(false)
      setButtonAction('Open Pack')
      playGameSound('closePack', 0.5, 0.8)
      moveToNewPosition(-3, 0, 5)
      resetCards()
      isGroupClickable = false

      // @NOTE: Reset the game state
      clearBackendResult()
      setPackAvailable(false)

      entryEvent.pub('updateBalance')
      entryEvent.pub('gameFinished')

      send?.({
        type: 'RESET',
        payload: {},
      })

      setInProgressEntry(null)
      setIsSubmitting(false)
    }
  }

  const moveToNewPosition = (x: number, y: number, z: number) => {
    endPosition.current = new THREE.Vector3(x, y, z)
    startTime.current = performance.now()
  }

  const GetFinalPosition = (index: number, isClicked: boolean): [number, number, number] => {
    if (isClicked) {
      return [
        0 + index * 1.25, // x position for 3 columns
        // -0.5 + Math.floor(index / 3) * -3.5, // y position for 2 rows\
        -4,
        0 + index * 0.1, // z position,
      ]
    } else {
      return [
        3 + -0.05 * (index - selectedCard.current), // x position for 3 columns
        -9 + 0.05 * (index - selectedCard.current), // + drawModifier, // y position for 2 rows
        5 + -0.1 * index, // z position,
      ]
    }
  }

  const triggerConfetti = () => {
    setShowConfetti(true)
  }

  function handleGroupClick(event: any) {
    if (!isGroupClickable) {
      event.stopPropagation() // Prevents the event from bubbling up
      return
    }
    // If group is clickable, allow the click to propagate
  }

  useEffect(() => {
    console.log('backendResults', backendResult)
    if (backendResult) {
      setPackAvailable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendResult])

  useEffect(() => {
    // console.log('cardResults', cardResults)
    if (cardResults.length > 0) {
      setCardArray(cardResults)
      console.log('DEBUG::: set card array: cardResults')
      resetCards()
    }
  }, [cardResults])

  useEffect(() => {
    if (packAvailable) {
      setButtonVisible(true)
      setButtonAction('Open Pack')
    }
  }, [packAvailable])

  // Reset animation whenever the endPosition changes
  useEffect(() => {
    if (cubeRef.current) {
      currentStartPos.current = cubeRef.current.position.clone() // Set new start as current pos
      startTime.current = performance.now() // Reset start time
    }
  }, [endPosition.current])

  useFrame(() => {
    if (cubeRef.current) {
      const now = performance.now() // Current time
      const elapsedTime = now - startTime.current // Elapsed time
      const progress = Math.min(elapsedTime / duration, 1) // Progress capped at 1

      // Interpolate between current start and new end position
      // @TODO: Do not know how to resolve this error but checked the docs (https://github.com/pmndrs/react-three-fiber/blob/master/packages/eslint-plugin/docs/rules/no-new-in-loop.md) and did an update. If it causes unexpected behaviour, update it. Cause I cant be sure if it causes an unexpected behaviour
      const currentPosition = threeVec.lerpVectors(
        currentStartPos.current,
        endPosition.current,
        progress
      )
      cubeRef.current.position.copy(currentPosition)

      if (progress === 1) {
        currentStartPos.current.copy(endPosition.current) // Sync start and end
      }
    }
  })

  return (
    <>
      <group position={[0, -2, 7]}>
        <ConfettiEffect trigger={showConfetti} onComplete={() => setShowConfetti(false)} />
      </group>

      <group ref={cubeRef}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[2, 2, 2]} />
        {/*!isMobileScreen && (
          <Environment
            files={[PNGS.galaxy, PNGS.galaxy, PNGS.galaxy, PNGS.galaxy, PNGS.galaxy, PNGS.galaxy]}
          />
        )*/}
        <group onClick={handleGroupClick}>
          {cards.map((card, index) => {
            const c = cardIdToCards[cardArray[index]]
            return (
              <CardObject
                key={card.id}
                id={card.id}
                isClicked={card.isClicked}
                rotation={card.rotation}
                onClick={() => flipCard(card.id)}
                card={{ CardName: c.viewInfo.name, CardIconString: c.viewInfo.iconString }}
                position={GetFinalPosition(index, card.isClicked)}
              />
            )
          })}
        </group>
      </group>
      <OpenPackButton
        position={[0, -4.25, 1]}
        onClick={handleButtonClick}
        visible={isButtonVisible}
        buttonActionLabel={buttonAction}
      />
    </>
  )
}

export default CardScene
