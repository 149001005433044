/* eslint-disable no-extra-boolean-cast */
import { decodeError } from 'ethers-decode-error'
import useRandomnessType from '@/hooks/useRandomnessType'
import { type ISUContractForm } from '@/store/useSUContractStore'
import { BankrollContractInterface, SUContractInterface } from '@/lib/crypto'
import { type GameStateReducer } from '@/lib/fare/state'
import { sendGameStoreUpdateByAddress, useDelayedLiveEntry } from '@/store/useGameStateStore'
import useSUContractStore, { initialSUContractResults } from '@/store/useSUContractStore'
import {
  isKnownErrorMessage,
  mapErrorDataToMeaningfullString,
  mapSCStringToMeaningfulString,
} from '@/lib/crypto/error'
import { addAppNoti } from '@/store/useNotiStore'
import { parseUnits } from 'ethers/lib/utils'
import useMaxValuesStore, { applyBufferToValue } from '@/store/useMaxValuesStore'
import useAAStore from '@/lib/aa/hooks/useAAStore'
import { GameNameToQK } from '@/lib/vault'

import { usePostLog } from '@/lib/posthog/logging'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'
import { useCurrency } from '@/hooks/useCurrency'
import { useActiveWallet } from '@/lib/privy/hooks'
import { usePrivyService } from '@/lib/privy/usePrivyService'
import { usePathGameName } from '@/hooks/usePathGameName'
import { useQuickplaySmartWallet } from '@/hooks/useQuickplaySmartWallet'
import { AxiosError } from 'axios'
import { buildQK, minimizeQK, packMaxValues, sortBigIntQK } from '@/lib/vault/helpers'

export const useGameContract = () => {
  const gameName = usePathGameName()
  const { appAddresses, appContracts } = useAppChainConfig()
  const { walletAddress, activeWallet, appChainConfig } = useActiveWallet()
  const { networkStyle } = appChainConfig
  const { updateUserGameConfig } = usePrivyService()
  const {
    isApprovingContracts,
    hasApprovedContracts,
    setIsSubmitting,
    setSUContractResults,
    setSubmittedSide,
    setSubmittedAmount,
    setEntryCount,
    isSubmitting,
    inProgressEntry,
    approvedGameState,
  } = useSUContractStore(state => ({
    isApprovingContracts: state.isApprovingContracts,
    hasApprovedContracts: state.hasApprovedContracts,
    setIsWithdrawing: state.setIsWithdrawing,
    setIsSubmitting: state.setIsSubmitting,
    setSubmittedSide: state.setSubmittedSide,
    setSubmittedAmount: state.setSubmittedAmount,
    setEntryCount: state.setEntryCount,
    setSUContractResults: state.setSUContractResults,
    isSubmitting: state.isSubmitting,
    inProgressEntry: state.inProgressEntry,
    approvedGameState: state.approvedGameState,
  }))

  const { setIsApprovingContracts, setHasApprovedContracts } = useSUContractStore(state => ({
    setIsApprovingContracts: state.setIsApprovingContracts,
    setHasApprovedContracts: state.setHasApprovedContracts,
  }))

  const { isUsingAA, session: aaSession, aaClient } = useAAStore()
  const { sendSmartWalletTrialRegister } = useQuickplaySmartWallet()
  const {
    ethUsdcPriceSCValue,
    ethUsdcPriceBufferPercentage,
    averageCallbackGasSCValue,
    averageCallbackGasBufferPercentage,
    aaCostMultiplierSCValue,
    aaCostMultiplierBufferPercentage,
  } = useMaxValuesStore()
  const { fetchAndSetAllowance } = useCurrency()
  const send = useMemo(() => sendGameStoreUpdateByAddress(gameName), [gameName]) as
    | GameStateReducer
    | undefined
  const { encodedRandomnessType } = useRandomnessType()
  const { postlog } = usePostLog()

  const gameContract = useMemo(() => appContracts?.vault, [appContracts])
  const gameContractWs = useMemo(() => appContracts?.ws.vault, [appContracts])
  const submitEntry = useCallback(
    async (data: ISUContractForm) => {
      if (!gameContract || !walletAddress) return
      if (
        !Boolean(ethUsdcPriceSCValue) &&
        !Boolean(ethUsdcPriceBufferPercentage) &&
        !Boolean(averageCallbackGasSCValue) &&
        !Boolean(averageCallbackGasBufferPercentage) &&
        !Boolean(aaCostMultiplierSCValue) &&
        !Boolean(aaCostMultiplierBufferPercentage)
      ) {
        // @TODO: Maybe have default values rather than returning?
        return
      }
      try {
        setIsSubmitting(true)
        const gameQKs = GameNameToQK[gameName].getQK(data.side)
        const minimzedQKs = minimizeQK(gameQKs.q, gameQKs.k)
        const { sortedQ: q, sortedK: k } = sortBigIntQK(
          buildQK(minimzedQKs.q, minimzedQKs.k, BigInt(data.numberOfEntries))
        )
        // @NOTE: Set user's gameConfig so that backend identifies trial's gameConfig correctly
        await updateUserGameConfig({
          gameName: gameName || '',
          count: Number(data.numberOfEntries),
          side: data.side,
        })
        // @TODO: Get max values and buffer percentage and calculate max values for user and pack those values
        // @TODO: What if user set the bugger with decimal? This would error out because I am using bigints. So, think if we should support that if so find a way to support it
        const maxEthUsdcPrice = applyBufferToValue(
          ethUsdcPriceSCValue || '0',
          ethUsdcPriceBufferPercentage || '0'
        )
        const maxAverageCallbackGas = applyBufferToValue(
          averageCallbackGasSCValue || '0',
          averageCallbackGasBufferPercentage || '0'
        )
        const maxAACostMultiplier = applyBufferToValue(
          aaCostMultiplierSCValue || '0',
          aaCostMultiplierBufferPercentage || '0'
        )

        const packedMaxValues = packMaxValues(
          maxEthUsdcPrice,
          maxAverageCallbackGas,
          maxAACostMultiplier
        )

        // if (isUsingAA && aaSession) {
        // @NOTE: Gas estimation for keccak fails. Therefore, estimate gas as if using vrf and use that gasLmit explicitly when sending the tx
        // @NOTE: Gas usage of Keccak and VRF are fairly similar therefore, this should be fine
        // @TODO: Looks like this is not needed, can delete if it does work without estimation
        const estimatedGasLimit = await gameContract.estimateGas.trialRegister(
          walletAddress, // who
          parseUnits(String(data.entryAmount), networkStyle.decimals), // multiplier
          q,
          k,
          packedMaxValues // packedMaxValues
        )
        setSubmittedSide(data.side)
        setSUContractResults(initialSUContractResults)

        if (activeWallet?.walletClientType === 'privy') {
          send?.({
            type: 'START',
            payload: {
              side: data.side,
              entryAmount: data.entryAmount,
              entryCount: data.numberOfEntries,
              stopLoss: data.stopLoss,
              stopGain: data.stopGain,
            },
          })
          // @NOTE: These are data needed for Plinko deltaAmountDisplayer
          setSubmittedAmount(String(data.entryAmount))
          setEntryCount(data.numberOfEntries)
          gameContract
            .trialRegister(
              walletAddress, // who
              parseUnits(String(data.entryAmount), networkStyle.decimals), // multiplier
              q,
              k,
              packedMaxValues, // packedMaxValues
              {
                gasLimit: estimatedGasLimit.mul(120).div(100),
              }
            )
            .then(async tx => {
              // Add to live entries delay queue
              useDelayedLiveEntry.getState().setSubmittedTxHash(tx.hash)
              const resp = await tx?.wait()

              postlog(`Submit tx successful for ${gameName}`, {
                loglevel: 'success',
                eventName: `submitted_successful_${gameName}_entry`,
                txHash: resp.transactionHash,
                walletAddress,
                gamePayload: {
                  side: data.side,
                  entryAmount: data.entryAmount,
                  entryCount: data.numberOfEntries,
                  stopLoss: data.stopLoss,
                  stopGain: data.stopGain,
                },
              })

              window.__adrsbl.run(`${gameName}_played`, true, [
                { name: 'pageUrl', value: location.pathname },
                { name: 'txHash', value: resp.transactionHash },
                { name: 'walletAddress', value: walletAddress },
                {
                  name: 'gamePayload',
                  value: {
                    side: data.side,
                    entryAmount: data.entryAmount,
                    entryCount: data.numberOfEntries,
                    stopLoss: data.stopLoss,
                    stopGain: data.stopGain,
                  },
                },
              ])
            })
            .catch(err => {
              send?.({
                type: 'ERROR',
                payload: { errMsg: 'Error with tx' },
              })

              setIsSubmitting(false)
              addAppNoti({
                msg: String(err),
                type: 'error',
              })
              throw err
            })

          addAppNoti({
            msg: 'Entry submitted',
            type: 'success',
          })
        } else {
          const submitEntryTx = await gameContract.trialRegister(
            walletAddress, // who
            parseUnits(String(data.entryAmount), networkStyle.decimals), // multiplier
            q,
            k,
            packedMaxValues, // packedMaxValues
            {
              gasLimit: estimatedGasLimit.mul(120).div(100),
            }
          )
          // Add to live entries delay queue
          useDelayedLiveEntry.getState().setSubmittedTxHash(submitEntryTx.hash)
          addAppNoti({
            msg: 'Entry submitted',
            type: 'success',
          })

          send?.({
            type: 'START',
            payload: {
              side: data.side,
              entryAmount: data.entryAmount,
              entryCount: data.numberOfEntries,
              stopLoss: data.stopLoss,
              stopGain: data.stopGain,
            },
          })
          // @NOTE: These are data needed for Plinko deltaAmountDisplayer
          setSubmittedAmount(String(data.entryAmount))
          setEntryCount(data.numberOfEntries)

          const resp = await submitEntryTx?.wait()

          postlog(`Submit tx successful for ${gameName}`, {
            loglevel: 'success',
            eventName: `submitted_successful_${gameName}_entry`,
            txHash: resp.transactionHash,
            walletAddress,
            gamePayload: {
              side: data.side,
              entryAmount: data.entryAmount,
              entryCount: data.numberOfEntries,
              stopLoss: data.stopLoss,
              stopGain: data.stopGain,
            },
          })

          window.__adrsbl.run(`${gameName}_played`, true, [
            { name: 'pageUrl', value: location.pathname },
            { name: 'txHash', value: resp.transactionHash },
            { name: 'walletAddress', value: walletAddress },
            {
              name: 'gamePayload',
              value: {
                side: data.side,
                entryAmount: data.entryAmount,
                entryCount: data.numberOfEntries,
                stopLoss: data.stopLoss,
                stopGain: data.stopGain,
              },
            },
          ])
        }

        // send?.({
        //   type: 'START',
        //   payload: {
        //     side: data.side,
        //     entryAmount: data.entryAmount,
        //     entryCount: data.numberOfEntries,
        //     stopLoss: data.stopLoss,
        //     stopGain: data.stopGain,
        //   },
        // })
      } catch (err) {
        setIsSubmitting(false)
        if (err) {
          send?.({
            type: 'ERROR',
            payload: { errMsg: 'Error with tx' },
          })

          let errMsg = 'Unknown Error'
          if (isUsingAA && aaSession) {
            if ((err as Error)?.message && isKnownErrorMessage((err as Error)?.message)) {
              errMsg = (err as Error).message
            } else {
              errMsg = mapErrorDataToMeaningfullString((err as any)?.cause?.data)
              if (errMsg === 'UnknownError') {
                errMsg = 'Error with Quickplay - try refreshing tab'
              }
            }
          } else {
            if ((err as Error)?.message && isKnownErrorMessage((err as Error)?.message)) {
              errMsg = (err as Error).message
            } else {
              const decodedError = decodeError(err, SUContractInterface)
              errMsg = mapSCStringToMeaningfulString(decodedError.error)
            }
          }

          if (err instanceof AxiosError) {
            if (err.response?.data.error) errMsg = err.response?.data.error
          }

          addAppNoti({
            msg: errMsg,
            type: 'error',
          })
          postlog('Error while submitting quickplay', {
            loglevel: 'error',
            eventName: 'aa_quickplay_error_while_submitting',
            errorTrace: err as Error,
          })
          throw new Error(`Error while submitting entry, with quickplay: ${isUsingAA && aaClient}`)
        }
      } finally {
        fetchAndSetAllowance(walletAddress).catch(console.error)
      }
    },
    [
      gameContract,
      gameContractWs,
      walletAddress,
      encodedRandomnessType,
      isUsingAA,
      aaSession,
      aaClient,
      // aaTrialRegister,
      send,
      setSUContractResults,
      ethUsdcPriceSCValue,
      ethUsdcPriceBufferPercentage,
      averageCallbackGasSCValue,
      averageCallbackGasBufferPercentage,
      aaCostMultiplierSCValue,
      aaCostMultiplierBufferPercentage,
      appAddresses,
      activeWallet,
      gameName,
    ]
  )

  const approveContracts = useCallback(async () => {
    try {
      if (!appContracts) return
      setIsApprovingContracts(true)

      const allowConsumerContractsTx = await appContracts.bankroll.setAllowedContracts(
        [appAddresses.vault],
        [true]
      )
      await allowConsumerContractsTx.wait()
      setHasApprovedContracts(true)
    } catch (err) {
      // @NOTE: Package found from the following discussion: https://github.com/ethers-io/ethers.js/discussions/3027
      // @NOTE: Still problematic to decode AA related stuff (as expected, because we receive an error from our POST request rather than an error from the geth node), like it will not give the custom error but give something like: "Buffer is not defined"
      // @NOTE: Maybe it might be a good idea to divide eoa and aa error handling?
      const decodedError = decodeError(err, BankrollContractInterface)
      console.log('decoded error: ', decodedError)
      addAppNoti({
        msg: decodedError.error,
        type: 'error',
      })
      throw new Error(`Error approving contracts`)
    } finally {
      setIsApprovingContracts(false)
    }
  }, [appContracts])

  return useMemo(
    () => ({
      gameContract,
      gameContractWs,
      walletAddress,
      sendSmartWalletTrialRegister,
      submitEntry,
      approveContracts,
      isApprovingContracts,
      hasApprovedContracts,
      isSubmitting,
      inProgressEntry,
      approvedGameState,
      gameName,
    }),
    [
      gameContract,
      gameContractWs,
      walletAddress,
      submitEntry,
      sendSmartWalletTrialRegister,
      approveContracts,
      isApprovingContracts,
      hasApprovedContracts,
      isSubmitting,
      inProgressEntry,
      approvedGameState,
      gameName,
    ]
  )
}
