import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { RoundedBox, Image, Text, Line } from '@react-three/drei'
import * as THREE from 'three'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { Vector3 } from 'three'
import { useGameSoundManager } from './CardsAudio'

// import sounds
import cornerImage from '@/assets/cards/FareLogo.png'
import cornerImageBack from '@/assets/cards/FareLogoAlt.png'
import centerImage from '@/assets/svg/fare.svg'

interface CardObjectProps {
  id: number
  rotation: number
  onClick: () => void
  position: [number, number, number]
  card: {
    CardName: string
    CardIconString: string
  }
  isClicked: boolean
}

export const CardObject: React.FC<CardObjectProps> = ({
  rotation,
  onClick,
  position,
  card,
  isClicked,
}) => {
  const cardRef = useRef<THREE.Mesh>(null)
  const { playGameSound } = useGameSoundManager()

  const tempVector = useMemo(() => new THREE.Vector3(), [])

  // Animate the card's rotation smoothly on each frame
  useFrame(() => {
    if (cardRef.current) {
      cardRef.current.rotation.y = THREE.MathUtils.lerp(cardRef.current.rotation.y, rotation, 0.1)
      const currentPosition = cardRef.current.position
      const targetPosition = tempVector.set(position[0], position[1], position[2])

      // Smoothly interpolate between current position and target position
      currentPosition.lerp(targetPosition, 0.1) // 0.1 is the lerp factor, adjust for speed
    }
  })

  const [hovered, setHovered] = useState(false)

  useFrame((state, delta) => {
    if (cardRef.current) {
      if (!isClicked) {
        return
      }

      if (hovered) {
        // Smooth scaling up
        cardRef.current.scale.lerp(tempVector.set(1.1, 1.1, 1.1), 0.5)
        cardRef.current.position.lerp(
          tempVector.set(position[0], position[1] + 3, position[2] + 0.5),
          0.1
        )
      } else {
        // Smooth scaling back to original size
        cardRef.current.scale.lerp(tempVector.set(1, 1, 1), 0.5)
        cardRef.current.position.lerp(tempVector.set(position[0], position[1], position[2]), 0.1)
      }
    }
  })

  useEffect(() => {
    if (!isClicked) {
      return
    }
    if (hovered) {
      playGameSound('onHover', 0.5, 0.8)
    }
  }, [hovered])

  return (
    <>
      <RoundedBox
        ref={cardRef}
        args={[2, 3, 0.1]}
        radius={0.1}
        // position={isClicked ? [0,0,0] : [10,10,10]}
        onClick={event => {
          event.stopPropagation()
          onClick()
        }}
        scale={isClicked ? [1, 1, 1] : [0.5, 0.5, 0.5]}
        onPointerOver={e => {
          e.stopPropagation()
          setHovered(true)
        }}
        onPointerOut={e => {
          e.stopPropagation()
          setHovered(false)
        }}
      >
        <meshPhysicalMaterial
          color='#808080'
          metalness={1.0}
          roughness={0.0}
          reflectivity={0.5}
          side={THREE.DoubleSide}
        />

        {/* Front Images */}
        <Image position={[-0.7, 1.2, 0.06]} url={cornerImageBack} transparent scale={[0.3, 0.3]} />
        <Image
          position={[0.7, -1.2, 0.06]}
          url={cornerImageBack}
          transparent
          scale={[0.3, 0.3]}
          rotation={[0, 0, 3.15]}
        />
        <Image position={[0, 0, 0.06]} url={centerImage} transparent />

        {/* Back */}
        <Text
          position={[0, -1, -0.06]}
          fontSize={0.25}
          color='white'
          anchorX='center'
          anchorY='middle'
          maxWidth={1.8} // Max width for wrapping
          textAlign='center'
          rotation={[0, Math.PI, 0]}
        >
          {card.CardName}
        </Text>
        <Image
          position={[0, 0, -0.1]}
          url={card.CardIconString}
          transparent
          rotation={[0, Math.PI, 0]}
        />
        <Image
          position={[0.7, 1.2, -0.06]}
          url={cornerImage}
          transparent
          rotation={[0, Math.PI, 0]}
          scale={[0.3, 0.3]}
        />
        <Image
          position={[-0.7, -1.2, -0.06]}
          url={cornerImage}
          transparent
          rotation={[0, Math.PI, 3.15]}
          scale={[0.3, 0.3]}
        />

        {/* Bloomed Edge Lines */}
        <Line
          points={[
            [-0.5, 1.3, 0.06],
            [0.8, 1.3, 0.06],
          ]}
          color='magenta'
          lineWidth={1}
        />
        <Line
          points={[
            [0.8, 1.3, 0.06],
            [0.8, -1, 0.06],
          ]}
          color='magenta'
          lineWidth={1}
        />
        <Line
          points={[
            [0.5, -1.3, 0.06],
            [-0.8, -1.3, 0.06],
          ]}
          color='magenta'
          lineWidth={1}
        />
        <Line
          points={[
            [-0.8, -1.3, 0.06],
            [-0.8, 1, 0.06],
          ]}
          color='magenta'
          lineWidth={1}
        />

        <Line
          points={[
            [-0.5, 1.2, 0.06],
            [0.7, 1.2, 0.06],
          ]}
          color='magenta'
          lineWidth={1}
        />
        <Line
          points={[
            [0.7, 1.2, 0.06],
            [0.7, -1, 0.06],
          ]}
          color='magenta'
          lineWidth={1}
        />
        <Line
          points={[
            [0.5, -1.2, 0.06],
            [-0.7, -1.2, 0.06],
          ]}
          color='magenta'
          lineWidth={1}
        />
        <Line
          points={[
            [-0.7, -1.2, 0.06],
            [-0.7, 1, 0.06],
          ]}
          color='magenta'
          lineWidth={1}
        />

        <Line
          points={[
            [-0.8, 1.3, -0.06],
            [0.5, 1.3, -0.06],
          ]}
          color='cyan'
          lineWidth={0.5}
        />
        <Line
          points={[
            [0.8, 1, -0.06],
            [0.8, -1.3, -0.06],
          ]}
          color='cyan'
          lineWidth={0.5}
        />
        <Line
          points={[
            [0.8, -1.3, -0.06],
            [-0.5, -1.3, -0.06],
          ]}
          color='cyan'
          lineWidth={0.5}
        />
        <Line
          points={[
            [-0.8, -1, -0.06],
            [-0.8, 1.3, -0.06],
          ]}
          color='cyan'
          lineWidth={0.5}
        />
      </RoundedBox>

      <EffectComposer>
        <Bloom
          intensity={0.25} // Adjust bloom intensity
          luminanceThreshold={0.1} // Control what gets the bloom effect
          luminanceSmoothing={0.5} // Smooth transition between bloom and no bloom
        />
      </EffectComposer>
    </>
  )
}
