import useMaxValues from '@/hooks/useMaxValues'
import { useActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import useCurrencyStore from '@/store/useCurrencyStore'
import useSUContractStore from '@/store/useSUContractStore'
import { usePrivy } from '@privy-io/react-auth'

export const PrivyQuickplaySmartWalletListener = () => {
  const { smartWalletAddress, walletChainId, smartWalletClient } = useActiveWallet()
  const { ready, authenticated } = usePrivy()

  const { appContracts } = useAppChainConfigStore(state => ({
    appContracts: state.appContracts,
  }))

  const { smartWalletAllowance } = useCurrencyStore(state => ({
    smartWalletAllowance: state.allowances.currency,
  }))

  const { setApprovedGameState, hasApprovedSmartWalletGameContracts } = useSUContractStore(
    state => ({
      setApprovedGameState: state.setApprovedGameState,
      hasApprovedSmartWalletGameContracts: state.hasApprovedSmartWalletGameContracts,
    })
  )
  const { fetchAndSetSCMaxValues } = useMaxValues()

  const switchSmartWalletChainId = useCallback(
    async (id: number) => {
      try {
        await smartWalletClient?.switchChain({ id })
        console.log('Switched smart wallet chain')
      } catch (err) {
        console.error(err)
      }
    },
    [smartWalletClient]
  )

  const checkApprovedGameContracts = useCallback(async (): Promise<boolean> => {
    if (
      !appContracts?.bankroll ||
      !appContracts?.vault ||
      !smartWalletAddress ||
      !ready ||
      !authenticated
    )
      return false

    useSUContractStore.setState({
      hasApprovedSmartWalletGameContracts: 'pending',
    })

    try {
      const isApproved = await appContracts.ws.bankroll.isValidContractForFundOwner(
        appContracts.vault.address,
        smartWalletAddress
      )

      fetchAndSetSCMaxValues(smartWalletAddress)

      setApprovedGameState(isApproved ? 'approved' : 'not-approved')
      useSUContractStore.setState({
        hasApprovedSmartWalletGameContracts: isApproved ? 'approved' : 'not-approved',
      })

      return isApproved
    } catch (err) {
      console.error(err)
      useSUContractStore.setState({
        hasApprovedSmartWalletGameContracts: 'not-approved',
      })
    }

    return false
  }, [appContracts, fetchAndSetSCMaxValues, smartWalletAddress, ready, authenticated])

  useEffect(() => {
    if (!ready || !authenticated || !smartWalletAddress) return

    useAppChainConfigStore.setState({
      hasSetupSmartWallet: Boolean(
        smartWalletAddress &&
        hasApprovedSmartWalletGameContracts === 'approved' &&
        Number(smartWalletAllowance) > 0
      ),
    })
  }, [
    hasApprovedSmartWalletGameContracts,
    smartWalletAllowance,
    smartWalletAddress,
    ready,
    authenticated,
  ])

  useEffect(() => {
    switchSmartWalletChainId(walletChainId)
    checkApprovedGameContracts()
  }, [checkApprovedGameContracts, walletChainId])

  return null
}
