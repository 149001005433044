import { PNGS } from '@/assets'
import { packIndexToPackQKForACardDraw } from '@/lib/crypto/cards'
import { formatEther } from 'viem'
import ramenString from '@/assets/cards/ramen.png'
import allenString from '@/assets/cards/Allen.png'
import tokenString from '@/assets/cards/Token.png'
import luckCharmString from '@/assets/cards/LuckCharm.png'
import fighterClassString from '@/assets/cards/FighterClass.png'
import pleasureClassString from '@/assets/cards/PleasureClass.png'
import frigateClassString from '@/assets/cards/FrigateClass.png'
import nebulaNexusString from '@/assets/cards/NebulaNexus.png'
import cyberSpireString from '@/assets/cards/CyberSpire.png'
import holoHeavenString from '@/assets/cards/HoloHeaven.png'
import synthwaveSanctuaryString from '@/assets/cards/SynthwaveSanctuary.png'
import eclipseElysiumString from '@/assets/cards/EclipseElysium.png'
import luminaArcadiaString from '@/assets/cards/LuminaArcadia.png'
import solariumOasisString from '@/assets/cards/SolariumOasis.png'
import bog24String from '@/assets/cards/Bog24.png'

// Cards side will just be a number and it will indicate which pack to open. So, side = 0 indicates that user is opening a explorer pack, side = 1 means they are opening another pack
export const isValidCardsSide = (side: any) => {
  return side >= 0 && side <= 2
}

// @NOTE: useful for backend
// export const getPackIndexToKToCardIndex = (packIndex: number, k: bigint) => {
//   return packIndexToPackQKForACardDraw[packIndex].k.findIndex(val => val === k)
// }

export interface Card {
  id: number
  packId: number
  indexInsidePack: number
  k: bigint
  viewInfo: CardViewInfo
}

interface Sprite {
  src: string
}
export interface CardViewInfo {
  name: string
  iconString: string
  icon: Sprite
  iconGray: Sprite
  value: number // @NOTE: value = k * packMinCost
  description: string
  tier: number
}

export interface Pack {
  id: number
  minCost: number
  viewInfo: PackViewInfo
}

export interface PackViewInfo {
  name: string
  description: string
  icon: string
  audioClip: string
}

export const getCardIndexGivenPackIdAndIndexInsidePack = (
  packId: number,
  indexInsidePack: number
): number => {
  if (packId === 0) return indexInsidePack
  return cardsIdToPackIdAndIndexInsidePack.filter(c => packId > c.packId).length + indexInsidePack
}

// @NOTE: useful for backend
// export const getPacksCardCount = (packId: number) => {
//   if (isValidCardsSide(packId)) {
//     return cardsIdToPackIdAndIndexInsidePack.filter(c => c.packId === packId).length
//   }
//   return -1
// }

export const packIdToPack: Pack[] = [
  {
    id: 0,
    minCost: 1,
    viewInfo: {
      name: 'Explorer Pack',
      description:
        'The AstroPunk Anarchy Explorer Pack is a great way to start your collection! This pack contains a hand selected deck of cards, perfect for those who want to explore the vast universe of AstroPunk Anarchy.',
      icon: PNGS.explorerPack,
      audioClip: 'explorerPack',
    },
  },
  {
    id: 1,
    minCost: 5,
    viewInfo: {
      name: 'Challenger Pack',
      description:
        'Explore the vast reaches of space with the Rocket Fleet Pack in "AstroPunk Anarchy"! This exclusive pack features a collection of elite rockets, each designed for a specific purpose, perfect for those who crave adventure and power in the cosmos.',
      icon: PNGS.challengerPack,
      audioClip: 'challengerPack',
    },
  },
  {
    id: 2,
    minCost: 15,
    viewInfo: {
      name: 'Cryptonaught Pack',
      description:
        'Step into the high-stakes world of "AstroPunk Anarchy" with the Cryptonaught Pack! This premium pack features a selection of the most luxurious and legendary casinos in the galaxy, each offering a unique experience for those daring enough to take a chance.',
      icon: PNGS.cryptonaughtPack,
      audioClip: 'cryptonaughtPack',
    },
  },
]

// @NOTE: As indexInsidePack increases, k value increases. So, pack is ordered so that k value increases with index of the card inside index
export const cardsIdToPackIdAndIndexInsidePack = [
  {
    packId: 0,
    indexInsidePack: 0,
    viewInfo: {
      name: 'Ramen',
      icon: { src: PNGS.ramen },
      iconGray: { src: PNGS.ramenG },
      iconString: ramenString,
      description: 'A simple yet comforting meal from the heart of Neo-Tokyo.',
      tier: 0,
    },
  },
  {
    packId: 0,
    indexInsidePack: 1,
    viewInfo: {
      name: 'Token',
      icon: { src: PNGS.token },
      iconGray: { src: PNGS.tokenG },
      iconString: tokenString,
      description: 'A common currency in the AstroPunk underworld.',
      tier: 1,
    },
  },
  {
    packId: 0,
    indexInsidePack: 2,
    viewInfo: {
      name: 'Luck Charm',
      icon: { src: PNGS.luckCharm },
      iconGray: { src: PNGS.luckCharmG },
      iconString: luckCharmString,
      description: 'A talisman believed to bring good fortune to its bearer.',
      tier: 2,
    },
  },
  {
    packId: 1,
    indexInsidePack: 0,
    viewInfo: {
      name: 'Ramen',
      icon: { src: PNGS.ramen },
      iconGray: { src: PNGS.ramenG },
      iconString: ramenString,
      description: 'A simple yet comforting meal from the heart of Neo-Tokyo.',
      tier: 0,
    },
  },
  {
    packId: 1,
    indexInsidePack: 1,
    viewInfo: {
      name: 'Token',
      icon: { src: PNGS.token },
      iconGray: { src: PNGS.tokenG },
      iconString: tokenString,
      description: 'A common currency in the AstroPunk underworld.',
      tier: 0,
    },
  },
  {
    packId: 1,
    indexInsidePack: 2,
    viewInfo: {
      name: 'Luck Charm',
      icon: { src: PNGS.luckCharm },
      iconGray: { src: PNGS.luckCharmG },
      iconString: luckCharmString,
      description: 'A talisman believed to bring good fortune to its bearer.',
      tier: 1,
    },
  },
  {
    packId: 1,
    indexInsidePack: 3,
    viewInfo: {
      name: 'Pleasure Class Rocket',
      icon: { src: PNGS.pleasureClass },
      iconGray: { src: PNGS.pleasureClassG },
      iconString: pleasureClassString,
      description: 'A luxurious craft for those who prefer to travel the stars in style.',
      tier: 1,
    },
  },
  {
    packId: 1,
    indexInsidePack: 4,
    viewInfo: {
      name: 'Fighter Class Rocket',
      icon: { src: PNGS.fighterClass },
      iconGray: { src: PNGS.fighterClassG },
      iconString: fighterClassString,
      description: 'A sleek, fast rocket designed for dogfights in deep space.',
      tier: 2,
    },
  },
  {
    packId: 1,
    indexInsidePack: 5,
    viewInfo: {
      name: 'Frigate Class Rocket',
      icon: { src: PNGS.frigateClass },
      iconGray: { src: PNGS.frigateClassG },
      iconString: frigateClassString,
      description: 'A heavily armored vessel equipped for long-term missions and combat.',
      tier: 2,
    },
  },
  {
    packId: 2,
    indexInsidePack: 0,
    viewInfo: {
      name: 'Nebula Nexus',
      icon: { src: PNGS.nebulaNexus },
      iconGray: { src: PNGS.nebulaNexusG },
      iconString: nebulaNexusString,
      description: 'A vibrant, neon-lit casino floating in the heart of a distant nebula.',
      tier: 0,
    },
  },
  {
    packId: 2,
    indexInsidePack: 1,
    viewInfo: {
      name: 'Cyber Spire',
      icon: { src: PNGS.cyberSpire },
      iconGray: { src: PNGS.cyberSpireG },
      iconString: cyberSpireString,
      description: 'A towering casino known for its high-tech games and elite clientele.',
      tier: 0,
    },
  },
  {
    packId: 2,
    indexInsidePack: 2,
    viewInfo: {
      name: 'Holo Heaven',
      icon: { src: PNGS.holoHeaven },
      iconGray: { src: PNGS.holoHeavenG },
      iconString: holoHeavenString,
      description: 'An exclusive casino where reality bends with stunning holographic displays.',
      tier: 0,
    },
  },
  {
    packId: 2,
    indexInsidePack: 3,
    viewInfo: {
      name: 'Bog24',
      icon: { src: PNGS.bog24 },
      iconGray: { src: PNGS.bog24 },
      iconString: bog24String,
      description:
        'A mysterious swamp planet with a hidden casino known for its high-stakes games.',
      tier: 1,
    },
  },
  {
    packId: 2,
    indexInsidePack: 4,
    viewInfo: {
      name: 'Synthwave Sanctuary',
      icon: { src: PNGS.synthwaveSanctuary },
      iconGray: { src: PNGS.synthwaveSanctuaryG },
      iconString: synthwaveSanctuaryString,
      description: 'A retro-futuristic casino with an atmosphere steeped in synthwave culture.',
      tier: 1,
    },
  },
  {
    packId: 2,
    indexInsidePack: 5,
    viewInfo: {
      name: 'Eclipse Elysium',
      icon: { src: PNGS.eclipseElysium },
      iconGray: { src: PNGS.eclipseElysiumG },
      iconString: eclipseElysiumString,
      description:
        'A legendary casino known for hosting the most intense games under the shadow of a perpetual eclipse.',
      tier: 1,
    },
  },
  {
    packId: 2,
    indexInsidePack: 6,
    viewInfo: {
      name: 'Lumina Arcadia',
      icon: { src: PNGS.luminaArcadia },
      iconGray: { src: PNGS.luminaArcadiaG },
      iconString: luminaArcadiaString,
      description: 'The pinnacle of luxury and fortune, a casino only the most elite can enter.',
      tier: 2,
    },
  },
  {
    packId: 2,
    indexInsidePack: 7,
    viewInfo: {
      name: 'Solarium Oasis',
      icon: { src: PNGS.solariumOasis },
      iconGray: { src: PNGS.solariumOasis },
      iconString: solariumOasisString,
      description:
        'A hidden paradise on the edge of the galaxy, where the wealthy come to relax and unwind.',
      tier: 2,
    },
  },
  {
    packId: 2,
    indexInsidePack: 8,
    viewInfo: {
      name: 'Allen',
      icon: { src: PNGS.allen },
      iconGray: { src: PNGS.allenG },
      iconString: allenString,
      description: 'A mysterious figure, known for their hidden talents and high stakes.',
      tier: 2,
    },
  },
]

export const cardIdToCards: Card[] = cardsIdToPackIdAndIndexInsidePack.map(c => ({
  ...c,
  id: getCardIndexGivenPackIdAndIndexInsidePack(c.packId, c.indexInsidePack),
  // @NOTE: As indexInsidePack increases, k value increases. So, pack is ordered so that k value increases with index of the card inside index
  k: packIndexToPackQKForACardDraw[c.packId].k[c.indexInsidePack],
  viewInfo: {
    ...c.viewInfo,
    // @TODO: Not sure if I should multiply this 'value' with entryAmount or not. Like should this value be same when user is betting 5 usd vs 10 usd ?
    value: Number(
      // formatEther(
      //   packIndexToPackQKForACardDraw[c.packId].k[c.indexInsidePack] *
      //     BigInt(packIdToPack[c.packId].minCost)
      // )
      formatEther(packIndexToPackQKForACardDraw[c.packId].k[c.indexInsidePack])
    ),
  },
}))
