import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import {
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormWrapper,
  SFormSection,
} from '@/components/Forms/style'
import numeral from 'numeral'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useShallow } from 'zustand/react/shallow'
import { FormLayout } from '@/components/Forms/FormComponents/shared/FormLayout'
import {
  RouletteChip,
  type RouletteEntryHelperForQK,
  useRouletteGameStore,
  type RouletteEntry,
} from '@/store/useRouletteGameStore'
import { AnimatePresence } from 'framer-motion'
import {
  CheckboxContainer,
  CheckboxLabel,
  StyledCheckbox,
  ChipButtonsContainer,
  ChipButton,
  ChipBorder,
  CurrentEntriesSection,
  EmptyStateContainer,
  EmptyStateContent,
  EmptyStateText,
  EntryChip,
  EntryDescription,
  EntryItem,
  EntryItemContainer,
  EntryItemInner,
  EntryList,
  RemoveButton,
} from './styles'
import { SliderInput } from '@/components/Forms/FormComponents/shared/SliderInput'
import { useRouletteSound } from './RouletteSoundInterface'
import { FARE_COLORS } from '@/design/colors'
import { calculateWinIntensity } from './RouletteScene'
import { GameButton } from '@/components/shared/Button/GameButton'
import {
  convertRouletteUIRepresentationToRouletteNumberToBetFraction,
  type IRouletteSide,
} from '@/lib/crypto/roulette'
import { SVGS } from '@/assets'
import RouletteDrawer from './RouletteDrawer'
import { useIsBreakpoint, useIsBreakpointLandscape } from '@/hooks/common/useIsBreakpoint'
import { Button, ButtonEnum } from '@/components/shared/Button'

interface RouletteFormData {
  // not sure exactly what needs to be included for backend purposes
  side: IRouletteSide
  entryAmount: number
  numberOfEntries: number
  stopLoss: number
  stopGain: number

  minBetAmount: number
  selectedChip: RouletteChip
}

const formatChipAmount = (amount: number) => {
  return numeral(amount).format(amount % 1 === 0 ? '0,0' : '0,0.00')
}

// Add setIsDrawerOpen to props
interface RouletteFormProps {
  setIsDrawerOpen: (isOpen: boolean) => void
}

const RouletteFormComponent: React.FC<RouletteFormProps> = ({ setIsDrawerOpen }) => {
  const { control, watch, setValue } = useForm<RouletteFormData>({
    defaultValues: {
      side: {},
      entryAmount: 0,
      numberOfEntries: 1,
      stopLoss: 0,
      stopGain: 0,
      minBetAmount: 1,
      selectedChip: RouletteChip.ONE_X,
    },
  })
  const formData = watch()
  const isMobileScreen = useIsBreakpoint('sm')
  const { playGameSound } = useRouletteSound()
  const { isDrawerOpen, setDrawerOpen } = useRouletteGameStore()

  // Watch only necessary fields to minimize re-renders
  const minBetAmount = watch('minBetAmount')
  // const entryAmount = watch('entryAmount')

  const { selectedBalance } = useCurrencyStore(
    useShallow(state => ({
      selectedBalance: state.balances.currency,
    }))
  )

  const {
    gameState,
    setMinBetAmount,
    selectedChip,
    setSelectedChip,
    currentEntries,
    removeEntry,
    totalBetAmount,
    result,
    isKeepingSelection,
    toggleKeepSelection,
    minBetAmount: minBetAmountStore,
    uiRouletteSide,
  } = useRouletteGameStore()

  useEffect(() => {
    setValue('entryAmount', totalBetAmount)
  }, [setValue, totalBetAmount])

  // const isSubmitDisabled = useMemo(() => {
  //   return gameState !== 'IDLE' || currentEntries.length === 0 || totalBetAmount <= 0
  // }, [gameState, currentEntries.length, totalBetAmount])

  const formatEntryDescription = (entry: RouletteEntry): string => {
    switch (entry.type) {
      case 'straight':
        return `Number ${entry.value}`
      case 'split':
        return `Split ${Array.isArray(entry.value) ? entry.value.join('/') : entry.value}`
      case 'street': {
        const nums = Array.isArray(entry.value) ? entry.value : [entry.value]
        return `Street ${nums[0]}-${nums[nums.length - 1]}`
      }
      case 'corner': {
        const nums = Array.isArray(entry.value) ? entry.value : [entry.value]
        return `Corner ${nums.join('/')}`
      }
      case 'line': {
        const nums = Array.isArray(entry.value) ? entry.value : [entry.value]
        return `Line ${nums[0]}-${nums[nums.length - 1]}`
      }
      case 'dozen': {
        const dozenStartNum = (
          Array.isArray(entry.value) ?
            entry.value
          : [entry.value])[0] as number
        return `Dozen ${dozenStartNum}-${dozenStartNum + 11}`
      }
      case 'column':
        const columnNum = (Array.isArray(entry.value) ? entry.value : [entry.value])[0] as number
        return `Column ${columnNum}`
      case 'redBlack':
        return typeof entry.value === 'string' ? entry.value.toUpperCase() : 'RED/BLACK'
      case 'oddEven':
        return typeof entry.value === 'string' ? entry.value.toUpperCase() : 'ODD/EVEN'
      case 'highLow':
        if (typeof entry.value === 'string') {
          return entry.value.toUpperCase()
        }
        return (
          typeof entry.value === 'number' ?
            entry.value > 18 ?
              'HIGH'
            : 'LOW'
          : 'HIGH/LOW'
        )
      case 'trio': {
        const nums = Array.isArray(entry.value) ? entry.value : [entry.value]
        return `Trio ${nums.join('/')}`
      }
      default:
        return `Unknown bet type: ${entry.type}`
    }
  }

  // sets roulette drawer closed if mobile screen
  useEffect(() => {
    if (isMobileScreen) {
      setDrawerOpen(false)
    } else {
      setDrawerOpen(true)
    }
  }, [isMobileScreen, setDrawerOpen])

  // const handleStartRound = () => {
  //   startRound()
  //   playGameSound('beep', 0.4, 1)
  // }

  const isEntryWinning = (entry: RouletteEntryHelperForQK, result?: number): boolean => {
    if (gameState !== 'RESULT_SHOWN' || !result) return false

    return entry.numbers.includes(result)
  }

  // Update chip selection to also show drawer
  const handleChipSelect = (value: RouletteChip) => {
    setSelectedChip(value)
    setIsDrawerOpen(true)
  }

  // Add effect to handle min bet amount changes
  useEffect(() => {
    // Only update if the value is different from what's in the store
    if (minBetAmount !== minBetAmountStore) {
      // Update the store's min bet amount
      setMinBetAmount(minBetAmount)
      // Reset selected chip when min bet amount changes
      setSelectedChip(null)
    }
  }, [minBetAmount, minBetAmountStore, setMinBetAmount, setSelectedChip])
  const isLandscape = useIsBreakpointLandscape('sm')
  return (
    <FormWrapper>
      <FormLayout>
        {isMobileScreen && isLandscape && <RouletteDrawer />}
        <SliderInput
          label='Minimum Bet Amount'
          label2={`${numeral(selectedBalance).format('0,0.00')}`}
          name='minBetAmount'
          control={control}
          min={1}
          max={10}
          step={1}
          defaultValue={1}
        />

        <SFormSection>
          <FormLabelRow>
            <FormLabel
              $isRelative
              style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
            >
              <div>
                <FormIndicator $isActive={true} style={{ left: -12, top: 4 }} />
                <FormAmountLabel>Selected Chip</FormAmountLabel>
              </div>
            </FormLabel>
          </FormLabelRow>
          <Controller
            name='selectedChip'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ChipButtonsContainer>
                <ChipButton
                  type='button'
                  $isActive={selectedChip === minBetAmount}
                  $value={minBetAmount}
                  $minBetAmount={minBetAmount}
                  onClick={() => {
                    field.onChange(minBetAmount)
                    handleChipSelect(minBetAmount)
                    playGameSound('beep', 0.2, 0.9)
                  }}
                  style={{ color: FARE_COLORS.blue }}
                >
                  <ChipBorder src={SVGS.level4Icon} />
                  {numeral(minBetAmount).format('0,0')}
                </ChipButton>
                <ChipButton
                  type='button'
                  $isActive={selectedChip === minBetAmount * 5}
                  $value={minBetAmount * 5}
                  $minBetAmount={minBetAmount}
                  onClick={() => {
                    field.onChange(minBetAmount * 5)
                    handleChipSelect(minBetAmount * 5)
                    playGameSound('beep', 0.3, 1)
                  }}
                >
                  <ChipBorder src={SVGS.chipBorder} />
                  {numeral(minBetAmount * 5).format('0,0')}
                </ChipButton>
                <ChipButton
                  type='button'
                  $isActive={selectedChip === minBetAmount * 25}
                  $value={minBetAmount * 25}
                  $minBetAmount={minBetAmount}
                  onClick={() => {
                    field.onChange(minBetAmount * 25)
                    handleChipSelect(minBetAmount * 25)
                    playGameSound('beep', 0.35, 1.1)
                  }}
                >
                  <ChipBorder src={SVGS.chipBorder} />
                  {numeral(minBetAmount * 25).format('0,0')}
                </ChipButton>
                <ChipButton
                  type='button'
                  $isActive={selectedChip === minBetAmount * 100}
                  $value={minBetAmount * 100}
                  $minBetAmount={minBetAmount}
                  onClick={() => {
                    field.onChange(minBetAmount * 100)
                    handleChipSelect(minBetAmount * 100)
                    playGameSound('beep', 0.4, 1.2)
                  }}
                >
                  <ChipBorder src={SVGS.chipBorder} />
                  {numeral(minBetAmount * 100).format('0,0')}
                </ChipButton>
              </ChipButtonsContainer>
            )}
          />
        </SFormSection>

        <CurrentEntriesSection>
          <FormLabelRow>
            <FormLabel
              $isRelative
              style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
            >
              <div>
                <FormIndicator
                  $isActive={currentEntries.length > 0}
                  style={{ left: -12, top: 4 }}
                />
                <FormAmountLabel>Current Entries</FormAmountLabel>
              </div>
              <FormAmountLabel>TOTAL: {formatChipAmount(totalBetAmount)}</FormAmountLabel>
            </FormLabel>
          </FormLabelRow>
          <EntryList>
            <AnimatePresence mode='popLayout'>
              {currentEntries.length === 0 ?
                <EmptyStateContainer
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <EmptyStateContent>
                    <EmptyStateText>No entries placed</EmptyStateText>
                  </EmptyStateContent>
                </EmptyStateContainer>
              : currentEntries.map((entry, index) => (
                  <EntryItemContainer
                    key={`${entry.type}-${Array.isArray(entry.value) ? entry.value.join('-') : entry.value}`}
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    transition={{
                      type: 'spring',
                      stiffness: 500,
                      damping: 30,
                      mass: 1,
                    }}
                    layout
                  >
                    <EntryItemInner
                      initial={{ scaleX: 0 }}
                      animate={{ scaleX: 1 }}
                      exit={{ scaleX: 0 }}
                      transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 25,
                        mass: 0.8,
                      }}
                      style={{ originX: 0 }}
                    >
                      <EntryItem
                        $isWinning={isEntryWinning(entry, result)}
                        $intensity={calculateWinIntensity(entry)}
                      >
                        <EntryChip $value={entry.amount} $minBetAmount={minBetAmount}>
                          {numeral(entry.amount).format('0,0')}
                        </EntryChip>
                        <EntryDescription>{formatEntryDescription(entry)}</EntryDescription>
                        <RemoveButton
                          onClick={() => gameState === 'IDLE' && removeEntry(index)}
                          title='Remove bet'
                          $disabled={gameState !== 'IDLE'}
                        >
                          ✕
                        </RemoveButton>
                      </EntryItem>
                    </EntryItemInner>
                  </EntryItemContainer>
                ))
              }
            </AnimatePresence>
          </EntryList>
          <CheckboxContainer>
            <CheckboxLabel htmlFor='keepSelectionCheckbox'>KEEP SELECTION</CheckboxLabel>
            <StyledCheckbox
              type='checkbox'
              id='keepSelectionCheckbox'
              checked={isKeepingSelection}
              onChange={toggleKeepSelection}
            />
          </CheckboxContainer>
        </CurrentEntriesSection>
      </FormLayout>
      <FormFooter
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          justifyContent: 'space-between',
        }}
      >
        {isMobileScreen && (
          <>
            <Button
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              buttonType={ButtonEnum.BASE}
              disabled={false}
            >
              {isDrawerOpen ? 'Close Roulette Drawer' : 'Open Roulette Drawer'}
            </Button>
          </>
        )}
        <GameButton
          formData={{
            ...formData,
            side: {
              uiRepresentation: uiRouletteSide,
              rouletteNumberToBetFraction:
                convertRouletteUIRepresentationToRouletteNumberToBetFraction(uiRouletteSide),
            },
          }}
          entryAmountNum={totalBetAmount}
        />
      </FormFooter>
    </FormWrapper>
  )
}
export const RouletteForm = memo(RouletteFormComponent)
