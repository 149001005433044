import { SVGS } from '@/assets'
import { AppGameName } from '../types'

// any definition should only reference this enum for defined games
export const gameNameToSVGMap: { [key in AppGameName]: string } = {
  [AppGameName.CoinFlip]: SVGS.walletIcon,
  [AppGameName.Dice]: SVGS.diceIcon,
  [AppGameName.RPS]: SVGS.scissorIcon,
  [AppGameName.Bombs]: SVGS.bombIcon,
  [AppGameName.Plinko]: SVGS.plinkoIcon,
  [AppGameName.Crash]: SVGS.crashIcon,
  [AppGameName.Cards]: SVGS.cardsIcon,
  [AppGameName.Roulette]: SVGS.rouletteIcon,
} as const

export const routeGameTypeMap: Record<string, AppGameName> = {
  '/dice': AppGameName.Dice,
  '/rps': AppGameName.RPS,
  '/coin-flip': AppGameName.CoinFlip,
  '/bombs': AppGameName.Bombs,
  '/plinko': AppGameName.Plinko,
  '/crash': AppGameName.Crash,
  '/cards': AppGameName.Cards,
  '/roulette': AppGameName.Roulette,
} as const
