import { SVGS } from '@/assets'

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
`

interface ICloseButton {
  onClick: () => void
}

export const CloseButtonIcon: React.FC<ICloseButton> = ({ onClick }) => {
  return (
    <CloseButton onClick={onClick}>
      <img src={SVGS.closeIcon} width={24} alt='close' />
    </CloseButton>
  )
}
