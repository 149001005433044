import { getMobileRouteLinks } from '@/constants/links'
import { ImageWrapper, MenuLink, MobileMenuContentWrapper } from './style'
import { MenuParticles } from '@/components/shared/ParticlesBackground/MenuParticles'
import { AnimatePresence } from 'framer-motion'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { useActiveWallet } from '@/lib/privy/hooks'

interface IMobileMenuProps {
  isShowMenu: boolean
  onButtonClick: (isShowMenu: boolean) => void
}

const animationVariants = {
  initial: {
    opacity: 0,
    scaleY: 0,
  },
  animate: {
    opacity: 1,
    scaleY: 1,
    transition: {
      duration: 0.1,
      ease: [0.12, 0, 0.39, 0],
    },
  },
  exit: {
    opacity: 0,
    scaleY: 0.8,
    transition: {
      duration: 0.3,
      ease: [0.42, 0, 0.58, 1],
    },
  },
}

export const MobileMenu = ({ isShowMenu, onButtonClick }: IMobileMenuProps) => {
  const currentPath = window.location.pathname.split('/')[1]
  const isMobileScreen = useIsBreakpoint('xs')
  const isActive = useCallback((path: string) => `/${currentPath}` === path, [currentPath])
  const { activeWallet } = useActiveWallet()
  const mobileRouteLinks = useMemo(() => getMobileRouteLinks(activeWallet?.walletClientType), [activeWallet?.walletClientType])

  if (!isMobileScreen) return null


  return (
    <AnimatePresence mode='wait'>
      {isShowMenu && (
        <MobileMenuContentWrapper
          key='dropdown-content'
          variants={animationVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <div>
            {mobileRouteLinks.map(route => (
              <MenuLink
                isActive={isActive(route.to)}
                key={route.title}
                to={route.to}
                onClick={() => onButtonClick(!isShowMenu)}
                target={route.to.startsWith('https') ? '_blank' : undefined}
              >
                {isActive(route.to) ?
                  <MenuParticles particleColors={route.particleColors} />
                : null}
                <ImageWrapper isActive={isActive(route.to)}>
                  <img src={route.img} alt={`${route.img}-icon`} />
                </ImageWrapper>
                <span>{route.title}</span>
              </MenuLink>
            ))}
          </div>
        </MobileMenuContentWrapper>
      )}
    </AnimatePresence>
  )
}
