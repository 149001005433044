import { PlinkoScene } from './PlinkoScene'
import { Suspense } from 'react'
import { AnimatePresence } from 'framer-motion'
import { SCanvas, SMounting } from '../styles'
import useMountDuration from '@/hooks/useMountDuration'
import { LoadingChip } from '@/components/shared/LoadingChip'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { SVolumeSliderWrapper, VolumeSlider } from '@/components/shared/SoundSystem/VolumeSlider'
import { SBorderAnimator } from '../shared/BorderFlash'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { AppGameName } from '@/chains/types'
import { ShareWinningsIconButton } from '@/components/shared/ShareWinningsIconButton'

const PlinkoSceneSuspensed = ({ unityContext }: { unityContext: any }) => {
  const { mountDuration } = useMountDuration()
  const { isLoaded } = unityContext

  return (
    <SCanvas style={{ border: 'none' }}>
      <AnimatePresence>
        {!isLoaded && (
          <SMounting
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: mountDuration }}
          >
            <LoadingChip width={56} height={56} />
          </SMounting>
        )}
      </AnimatePresence>

      <Suspense fallback={null}>
        <PlinkoScene unityContext={unityContext} />
      </Suspense>
    </SCanvas>
  )
}

const SUnityWrapper = styled.div`
  position: relative;
  z-index: 1;
  @media (min-width: 1400px) {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
  }
`

const SBorderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`

interface PlinkoCanvasProps {
  unityContext: any
  onVolumeChange?: (volume: number) => void
}

const PlinkoCanvas = ({ unityContext, onVolumeChange }: PlinkoCanvasProps) => {
  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)

  return (
    <SCanvas style={{ background: 'black' }}>
      <SBorderWrapper>
        <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity} />
      </SBorderWrapper>
      <DeltaNumbersDisplay gameName={AppGameName.Plinko} />
      <SUnityWrapper>
        <AnimatePresence>
          <PlinkoSceneSuspensed unityContext={unityContext} />
          <SVolumeSliderWrapper>
            <VolumeSlider onVolumeChange={onVolumeChange} />
          </SVolumeSliderWrapper>
          {didPlayerWin && <ShareWinningsIconButton />}
        </AnimatePresence>
      </SUnityWrapper>
    </SCanvas>
  )
}

export default PlinkoCanvas
