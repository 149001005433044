import {
  ButtonWrapper,
  dropdownVariant,
  mobileDropdownVariant,
  ProfileInfo,
  SAddressAvatarWrapper,
  SClickToCopy,
  SDropdown,
  SDropdownContent,
  SDropdownItem,
} from './style'
import { generateUserColor } from '@/utils'
import { AnimatePresence } from 'framer-motion'
import useUserDataStore from '@/store/useUserDataStore'
import { useUserImageToRender, useUsernameToRender } from '@/utils/userDisplay'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { OverviewPanel } from '../../shared/Panels/RightPanel/OverviewPanel'
import { useActiveWallet } from '@/lib/privy/hooks'
import { faucetState } from '@/lib/privy/hooks/faucetState'
import { useAppFeatures } from '@/hooks/useAppFeatures'
import { FreePlay } from '../../shared/Header/FreePlayModal'
import { useAuthWallet } from '@/lib/privy/hooks/useAuthWallet'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useLocalStorage } from 'usehooks-ts'
import { SettingsPageEnum } from '@/pages/SettingsPage'
import { WalletOverview } from '@/components/Privy/WalletOverview'

interface DropdownProps {
  isOpen: boolean
  onButtonClick: (isOpen: boolean) => void
  onContentClick: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const Dropdown: React.FC<DropdownProps> = ({ isOpen, onButtonClick, onContentClick }) => {
  const navigate = useNavigate()
  const isMobileScreen = useIsBreakpoint('xs')
  const mouseLeaveTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { walletAddress, activeWallet, appChainConfig } = useActiveWallet()
  const { logout } = useAuthWallet()
  const balances = useCurrencyStore.use.balances()
  const { networkStyle } = appChainConfig

  const appFeatures = useAppFeatures()
  const { username, avatarSeedAddress, isUsingTwitterToDisplay, twitterUsername, twitterImage } =
    useUserDataStore(state => ({
      username: state.username,
      avatarSeedAddress: state.avatarSeedAddress,
      isUsingTwitterToDisplay: state.isUsingTwitterToDisplay,
      twitterUsername: state.twitterUsername,
      twitterImage: state.twitterImage,
    }))
  const userColor = useMemo(
    () => (avatarSeedAddress ? `#${generateUserColor(avatarSeedAddress || '')}` : ''),
    [avatarSeedAddress]
  )

  const deactivate = useCallback(async () => {
    if (!activeWallet) return

    await logout()
  }, [activeWallet, logout])

  const deactiveAndLogout = useCallback(async () => {
    try {
      await deactivate()
      // navigate('/')
      // TODO: Remove and ensure state is fully reset when logging a user out
      window.location.reload()
    } catch (err) {
      console.error(err)
    }
  }, [deactivate])

  const handleMouseEnter = () => {
    if (!isMobileScreen) {
      clearTimeout(mouseLeaveTimeoutRef.current)
    }
  }
  const handleMouseLeave = () => {
    if (!isMobileScreen) {
      mouseLeaveTimeoutRef.current = setTimeout(() => onButtonClick(false), 800)
    }
  }

  const userImageToRender = useUserImageToRender(
    walletAddress || '0x',
    isUsingTwitterToDisplay,
    avatarSeedAddress,
    twitterImage,
    {
      marginRight: '8px',
    }
  )

  const usernameToRender = useUsernameToRender(
    walletAddress || '0x',
    isUsingTwitterToDisplay,
    username,
    twitterUsername,
    {},
    {},
    {},
    false,
    false
  )

  const [_selectedSettingsTab, setSelectedSettingsTab] = useLocalStorage<SettingsPageEnum>(
    'selectedSettingsTabIndex',
    SettingsPageEnum.ACCOUNT
  )

  return (
    <SDropdown ref={dropdownRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SAddressAvatarWrapper
        $userColor={userColor}
        $isOpen={isOpen}
        onClick={() => onButtonClick(!isOpen)}
      >
        {userImageToRender}
        {isMobileScreen && !isOpen && (
          <img
            src={networkStyle.currencyIcon}
            style={{
              width: '12px',
              height: '12px',
              position: 'absolute',
              bottom: '8px',
              right: '-1px',
            }}
          />
        )}

        {!isMobileScreen || isOpen ? usernameToRender : null}
      </SAddressAvatarWrapper>
      <AnimatePresence mode='wait'>
        {isOpen && (
          <SDropdownContent
            key='dropdown-content'
            initial={
              isMobileScreen ?
                (mobileDropdownVariant.initial as any)
              : (dropdownVariant.initial as any)
            }
            animate={
              isMobileScreen ?
                (mobileDropdownVariant.animate as any)
              : (dropdownVariant.animate as any)
            }
            exit={
              isMobileScreen ? (mobileDropdownVariant.exit as any) : (dropdownVariant.exit as any)
            }
            $userColor={userColor}
            onClick={onContentClick}
          >
            {isMobileScreen && (
              <>
                <OverviewPanel />
                {!Number(balances.currency) && appFeatures.enableFaucet && (
                  <SDropdownItem
                    className='mobile-faucet'
                    $userColor={userColor}
                    onClick={() => {
                      faucetState.isFaucetModalOpen = true
                    }}
                  >
                    <span>FAUCET {networkStyle.currencyName}</span>
                  </SDropdownItem>
                )}
                <WalletOverview />
                <ProfileInfo>
                  To view your points dashboard, see your invite codes, connect your socials or edit
                  your settings, please visit on Desktop.
                </ProfileInfo>
              </>
            )}

            {!isMobileScreen && (
              <>
                <SClickToCopy copyText={walletAddress}>
                  <SDropdownItem $userColor={userColor}>
                    <span>COPY ADDRESS</span>
                    <div className='hover-bar'></div>
                  </SDropdownItem>
                </SClickToCopy>

                <SDropdownItem
                  $userColor={userColor}
                  onClick={() => {
                    setSelectedSettingsTab(SettingsPageEnum.ACCOUNT)
                    navigate('/settings')
                  }}
                >
                  <span>FUND WALLET</span>
                  <div className='hover-bar'></div>
                </SDropdownItem>

                {appFeatures.enableFaucet && (
                  <SDropdownItem
                    $userColor={userColor}
                    onClick={() => {
                      faucetState.isFaucetModalOpen = true
                    }}
                  >
                    <span>FAUCET {networkStyle.currencyName}</span>
                    <div className='hover-bar'></div>
                  </SDropdownItem>
                )}
                <SDropdownItem
                  $userColor={userColor}
                  onClick={() => {
                    navigate('/settings')
                  }}
                >
                  <span>SETTINGS</span>

                  <div className='hover-bar'></div>
                </SDropdownItem>
              </>
            )}
            {isMobileScreen ?
              <ButtonWrapper>
                <FreePlay />
                <SDropdownItem $userColor={userColor} onClick={deactiveAndLogout}>
                  <span>DISCONNECT</span>
                </SDropdownItem>
              </ButtonWrapper>
            : <SDropdownItem $userColor={userColor} onClick={deactiveAndLogout}>
                <span>DISCONNECT</span>
                <div className='hover-bar'></div>
              </SDropdownItem>
            }
          </SDropdownContent>
        )}
      </AnimatePresence>
    </SDropdown>
  )
}
