import useCurrencyStore from '@/store/useCurrencyStore'
import { gameStoreMapByGameName } from '@/store/useGameStateStore'
import { entryEvent } from '@/events/entryEvent'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'
import { useCurrency } from '@/hooks/useCurrency'
import { useActiveWallet } from '@/lib/privy/hooks'
import { usePathGameName } from '@/hooks/usePathGameName'
import { usePrivy } from '@privy-io/react-auth'

export const useBalanceListener = () => {
  const gameName = usePathGameName()
  const isListeningToCurrency = useRef(true)
  const queuedCurrencyBalance = useRef('')
  const uiBalanceUpdateTimeoutRef = useRef<NodeJS.Timeout>()
  const { ready, authenticated } = usePrivy()

  const { appContracts, appProvider } = useAppChainConfig()
  const { walletChainId, walletAddress, isWalletAuthed } = useActiveWallet()
  const setBalance = useCurrencyStore.use.setBalance()
  const { fetchBalance, fetchAndSetCurrencyBalance, fetchUpdateNativeBalance } = useCurrency()
  const useGameStore = useMemo(
    () => gameStoreMapByGameName[gameName] ?? gameStoreMapByGameName.coinFlip,
    [gameName]
  )
  const gameState = useGameStore(state => state.type)

  useEffect(() => {
    if (
      !appContracts ||
      !appProvider ||
      !walletAddress ||
      !isWalletAuthed ||
      !ready ||
      !authenticated
    )
      return

    // NOTE: Fetch initial balance when mounting listener
    if (isListeningToCurrency.current) {
      console.log('initial:fetchAndSetCurrencyBalance')
      fetchAndSetCurrencyBalance(walletAddress)
    }
    console.log('initial:fetchUpdateNativeBalance')
    fetchUpdateNativeBalance(walletAddress)

    const transferFromFilter = appContracts.ws.currency.filters.Transfer(walletAddress, null, null)
    const transferToFilter = appContracts.ws.currency.filters.Transfer(null, walletAddress, null)
    const transferListener = (..._args: any[]) => {
      if (isListeningToCurrency.current) {
        console.log('listener:fetchUpdateCurrencyBalance')
        fetchAndSetCurrencyBalance(walletAddress)
      }
    }

    // TODO: Need to ensure this isn't running more than once per 50 blocks
    // const blockListener = (...args: any[]) => {
    // console.log(args)
    // fetchUpdateNativeBalance(walletAddress)
    // }

    // Mount listeners
    // wsProvider.on('block', blockListener)
    appContracts.ws.currency.on(transferFromFilter, transferListener)
    appContracts.ws.currency.on(transferToFilter, transferListener)

    // Remove listeners
    return () => {
      // wsProvider.removeListener('block', blockListener)
      appContracts.ws.currency.removeListener(transferFromFilter, transferListener)
      appContracts.ws.currency.removeListener(transferToFilter, transferListener)
    }
  }, [walletChainId, isWalletAuthed, walletAddress, appContracts])

  useEffect(() => {
    if (!walletAddress) return

    switch (gameState) {
      case 'IDLE':
        isListeningToCurrency.current = true
        queuedCurrencyBalance.current = ''
        break
      case 'START':
        isListeningToCurrency.current = false
        break
      case 'RESOLVE':
        // NOTE: Queue currency balance while game's RESOLVE animation is playing
        fetchBalance(walletAddress || '', 'currency')
          .then(balance => {
            queuedCurrencyBalance.current = String(balance)
          })
          .catch(err => {
            queuedCurrencyBalance.current = ''
            console.error(err)
          })
        break
      case 'ERROR':
        isListeningToCurrency.current = true
        queuedCurrencyBalance.current = ''
        fetchAndSetCurrencyBalance(walletAddress)
        break
    }
  }, [gameState, walletAddress, walletChainId])

  entryEvent.useSub('updateBalance', data => {
    const delay = data.detail?.balanceUpdateDelay || 0

    uiBalanceUpdateTimeoutRef.current = setTimeout(() => {
      if (queuedCurrencyBalance.current) {
        setBalance('currency', queuedCurrencyBalance.current)
      } else {
        if (walletAddress) fetchAndSetCurrencyBalance(walletAddress)
      }

      isListeningToCurrency.current = true
      queuedCurrencyBalance.current = ''
    }, delay)
  })

  // NOTE; Clear out updateBalance and queuedCurrencyBalance timeout when switching games
  useEffect(() => {
    if (queuedCurrencyBalance.current) {
      queuedCurrencyBalance.current = ''
    }

    if (uiBalanceUpdateTimeoutRef.current) {
      clearTimeout(uiBalanceUpdateTimeoutRef.current)
      uiBalanceUpdateTimeoutRef.current = undefined
    }
  }, [gameName])
}
