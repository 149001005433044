import { AnimatePresence } from 'framer-motion'
import { defaultTransition, NotiPillVariant, SNotiPill } from './styles'

export const FareTokenNotiPill = () => {
  const handleClick = () => {
    window.open(
      'https://app.uniswap.org/explore/tokens/arbitrum/0xfa4e888d9fbbcf4afa7bf057ecfe59ed04619e62',
      '_blank',
      'noopener,noreferrer'
    )
  }

  return (
    <AnimatePresence>
      <SNotiPill
        className='noti-pill'
        variants={NotiPillVariant}
        initial={'initial'}
        animate={'animate'}
        exit={'exit'}
        transition={defaultTransition}
        onClick={handleClick}
      >
        BUY $FARE ON UNISWAP!
      </SNotiPill>
    </AnimatePresence>
  )
}
